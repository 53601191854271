import React, { Component } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Modal,
  Grid,
  TextField,
} from "@mui/material";
import { Tooltip } from "react-tooltip";
import { EDIT, DELETE } from "../../constants/url";
import AddIcon from "@mui/icons-material/Add";
import { FiTrash2, FiEdit2 } from "react-icons/fi";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import {
  COINS_LIST,
  DELETE_COINS,
  ADD_COINS_PATH,
  UPDATE_COINS,
} from "../../constants/url";
import {
  ALERT_BUTTON_OK,
  ALERT_ICON_ERR,
  ALERT_ICON_SUCC,
  COIN_ADD_SUCC,
  COIN_UPDATE_SUCC,
  CONFIRM_TITLE,
  CONFIRM_TXT_DEL_COIN,
  DELETED,
  DELETE_YES_BUTTON,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  ERR_POSTING,
  SUCC_ALERT_TITLE,
  SUCC_COIN_DEL,
  WARNING,
} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

class Coins extends Component {
  constructor() {
    const localRole = JSON.parse(localStorage.getItem("role"));
    super();
    this.state = {
      data: [],
      page:0,
      rowsPerPage:10,
      showModal: false,
      coin: "",
      price: "",
      editMode: false,
      editId: null,
      permissions:
        localRole && localRole.permissions ? localRole.permissions : "All",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowPageChange = this.handleRowPageChange.bind(this);
  }

  componentDidMount() {
    this.fetchCoinsData();
  }
  handlePageChange(e, newPage) {
    this.setState({ page: newPage });
  }
  handleRowPageChange(event) {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  fetchCoinsData = () => {
    const requestUrl = `${COINS_LIST}/?token=${cookies.get("agritech_token")}`;
    privateApiCall(requestUrl, "GET")
      .then((res) => {
        const k = 1;
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].sno = k + i;
        }
        this.setState({ data: res.data });
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`
        });
      });
  };

  handleDelete = (row) => {
    Swal.fire({
      title: `${CONFIRM_TITLE}`,
      text: `${CONFIRM_TXT_DEL_COIN}`,
      icon: `${WARNING}`,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: " #3085d6",
      confirmButtonText: `${DELETE_YES_BUTTON}`
    }).then((result) => {
      if (result.isConfirmed) {
        const requestUrl = `${DELETE_COINS}/${row._id}?token=${cookies.get(
          "agritech_token"
        )}`;
        privateApiCall(requestUrl, "POST", {})
          .then(() => {
            this.fetchCoinsData();
            Swal.fire(`${DELETED}`, `${SUCC_COIN_DEL}`, `${SUCC_ALERT_TITLE}`);
          })
          .catch((err) => {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ALERT_ICON_ERR}`,
              text: `${ERR_POSTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          });
      }
    });
  };

  handleEdit = (row) => {
    this.setState({
      editMode: true,
      editId: row._id,
      coin: row.coins,
      price: row.price,
      showModal: true,
    });
  };

  handleOpenModal = () => {
    this.setState({
      showModal: true,
      coin: "",
      price: "",
      editMode: false,
      editId: null,
    });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleAddOrUpdateCoin = () => {
    const { coin, price, editMode, editId } = this.state;
    const body = { coins: coin, price };
    let url;

    let successMessage;
    if (editMode) {
      url = `${UPDATE_COINS}/${editId}/?token=${cookies.get("agritech_token")}`;
      successMessage = `${COIN_UPDATE_SUCC}`;
    } else {
      url = `${ADD_COINS_PATH}/?token=${cookies.get("agritech_token")}`;
      successMessage = `${COIN_ADD_SUCC}`;
    }
    privateApiCall(url, "POST", body)
      .then(() => {
        this.fetchCoinsData();
        this.handleCloseModal();
        Swal.fire({
          title: `${SUCC_ALERT_TITLE}`,
          text: `${successMessage}`,
          icon: `${ALERT_ICON_SUCC}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText:` ${ALERT_BUTTON_OK}`,
        });
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: {ALERT_BUTTON_OK},
        });
      });
  };

  render() {
    const { data, showModal, coin, price, editMode } = this.state;
    return (
      <div id="wrapper">
        <Topbar backgroundColor="#769A0F" />
        <Sidebar />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title float-left">{STRING_CONSTANTS.COINS}</h4>
                    <ol className="breadcrumb float-right">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                      </li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.COINS}</li>
                    </ol>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card-box">
                    <div style={{ width: "98%" }}>
                      {this.state.permissions.some(
                        (item) =>
                          item.keyword === "coins" && item.isCreate == true
                      ) && (
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={this.handleOpenModal}
                          sx={{
                            backgroundColor: "#769A0F",
                            color: "#fff",
                            marginBottom: "20px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#769A0F", // Slightly darker shade for hover effect
                            },
                          }}
                        >
                         {STRING_CONSTANTS.ADD_COINS}
                        </Button>
                      )}
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell width="80px" align="center">
                               {STRING_CONSTANTS.SN_NO}
                              </TableCell>
                              <TableCell width="100px" align="center">
                                {STRING_CONSTANTS.COINS}
                              </TableCell>
                              <TableCell width="100px" align="center">
                                {STRING_CONSTANTS.PRICE}
                              </TableCell>
                              <TableCell width="160px" align="center">
                                {STRING_CONSTANTS.ACTIONS}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.slice(
                                  this.state.page * this.state.rowsPerPage,
                                  this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
                                ).map((row) => (
                              <TableRow key={row._id}>
                                <TableCell align="center">{row.sno}</TableCell>
                                <TableCell align="center">
                                  {row.coins}
                                </TableCell>
                                <TableCell align="center">
                                  {row.price}
                                </TableCell>
                                <TableCell align="center">
                                  {this.state.permissions.some(
                                    (item) =>
                                      item.keyword === "coins" &&
                                      item.isEdit == true
                                  ) && (
                                    <IconButton
                                      onClick={() => this.handleEdit(row)}
                                    >
                                      <FiEdit2
                                        size="18px"
                                        color="#0978F2"
                                        data-tooltip-id="1"
                                        data-tooltip-content={`${EDIT}`}
                                      />
                                    </IconButton>
                                  )}

                                  {this.state.permissions.some(
                                    (item) =>
                                      item.keyword === "coins" &&
                                      item.isDelete == true
                                  ) && (
                                    <IconButton
                                      onClick={() => this.handleDelete(row)}
                                    >
                                      <FiTrash2
                                        size="18px"
                                        style={{ color: "red" }}
                                        data-tooltip-id="2"
                                        data-tooltip-content={`${DELETE}`}
                                      />
                                      <Tooltip
                                        id="1"
                                        style={{ fontSize: "12px" }}
                                      />
                                      <Tooltip
                                        id="2"
                                        size="10px"
                                        style={{ fontSize: "12px" }}
                                      />
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page||0}
                        onPageChange={this.handlePageChange}
                        onRowsPerPageChange={this.handleRowPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={showModal}
          onClose={this.handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              padding: "20px",
              background: "white",
              width: "60%",
              margin: "100px auto",
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <h2>{editMode ? "Edit Coin" : "Add Coin"}</h2>
            <TextField
              label="Coins"
              value={coin}
              onChange={(e) => this.setState({ coin: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Price"
              value={price}
              onChange={(e) => this.setState({ price: e.target.value })}
              fullWidth
              margin="normal"
            />
            <Grid
              container
              justifyContent="flex-end"
              style={{ marginTop: "40px" }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={this.handleCloseModal}
                style={{ marginTop: "20px", marginRight: "10px" }}
              >
                {STRING_CONSTANTS.CLOSE}
              </Button>
              <Button
                variant="contained"
                onClick={this.handleAddOrUpdateCoin}
                style={{
                  marginTop: "20px",
                  backgroundColor: "#769A0F",
                  color: "#fff",
                }}
              >
                {editMode ? "Update" : "Submit"}
              </Button>
            </Grid>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Coins;
