import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Cookies from "universal-cookie";
import  Swal  from "sweetalert2/dist/sweetalert2";
import { ALERT_BUTTON_OK, ERR_ALERT_TITLE, ERR_GETTING } from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
const cookies = new Cookies();

class product extends Component {
  constructor() {
    super();
    window.postMessage("renderTable", "*");
    this.state = {
      data: "",
    };
  }
  componentDidMount() {
  privateApiCall("/listproducts/?token=" + cookies.get("asia_page_token"),'GET')
      .then(
        (res) => {
          const k = 1;

          for (let i = 0; i < res.data.data.length; i++) {
            res.data.data[i].sno = k + +i;
          }

          this.setState({ data: res.data.data });
        },
        (err) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`
          });
        }
      );
  }

  iconformat(cell, row) {
    return "<img src='" + cell + "' style='height:50px;'/>";
  }

  descriptionformat(cell, row) {
    return <div className="col-md-12">{cell}</div>;
  }

  edit(cell, row) {
    return (
      <span>
        <Link to={{ pathname: "/edit_product/" + row._id }}>
          <button
            type="submit"
            className="btn btn-icon waves-effect waves-light btn-info"
          >
            {" "}
            <i className="fa fa-pencil"></i>{" "}
          </button>
        </Link>
      </span>
    );
  }

  add(cell, row) {
    return (
      <span>
        <div>
          {row.status === 1 ? (
            <p>
              <button
                type="submit"
                className="btn btn-icon waves-effect waves-light btn-danger"
                data-toggle="modal"
                data-target={"#myModal1" + row._id}
              >
                {" "}
                <i className="fa fa-thumbs-o-down"></i>{" "}
              </button>
            </p>
          ) : (
            <p>
              <button
                type="submit"
                className="btn btn-icon waves-effect waves-light btn-success"
                data-toggle="modal"
                data-target={"#myModal" + row._id}
              >
                {" "}
                <i className="fa fa-thumbs-o-up"></i>{" "}
              </button>
            </p>
          )}
        </div>

        <div
          className="modal fade"
          id={"myModal" + row._id}
          tabindex="-1"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ marginTop: "80px" }}
          >
            <form
              method="post"
              action={
                "/productapprove/" +
                row._id +
                "/1/?token=" +
                cookies.get("asia_page_token")
              }
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {STRING_CONSTANTS.CONFIRM_ACTIVE}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    {STRING_CONSTANTS.CANCLE}
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {STRING_CONSTANTS.ACTIVE}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className="modal fade1"
          id={"myModal1" + row._id}
          tabindex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <form
              method="post"
              action={
                "/productblock/" +
                row._id +
                "/0/?token=" +
                cookies.get("asia_page_token")
              }
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel1">
                    {STRING_CONSTANTS.CONFIRM_BLOCK}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    {STRING_CONSTANTS.CANCLE}
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {STRING_CONSTANTS.BLOCK}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </span>
    );
  }

  render() {
    return (
      <div>
        <Topbar backgroundColor="#769A0F" />
        <Sidebar />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title float-left">{STRING_CONSTANTS.PRODUCT}</h4>

                    <ol className="breadcrumb float-right">
                      <li className="breadcrumb-item">
                        {" "}
                        <NavLink to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</NavLink>
                      </li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.PRODUCT}</li>
                    </ol>

                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card-box">
                    <div
                      style={{
                        width: "98%",
                      }}
                    >
                      <NavLink
                        to="/add_product"
                        className="btn btn-primary"
                        style={{ float: "left", backgroundColor: "#11abff" }}
                      >
                        {STRING_CONSTANTS.ADD_PRODUCTS}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default product;
