import React, { useEffect, useState } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import { Link } from "react-router-dom";
import { toast, Bounce } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2.js";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Modal,
  Grid,
  TextField,
  Fade,
  Card,
  Menu,
  MenuItem,
  CardMedia,
  Typography,
  IconButton,
} from "@mui/material";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import {
  ALERT_BUTTON_NO,
  ALERT_BUTTON_YES,
  DO_YOU_WANT_TO_DELETE_BROCHURE,
} from "../../constants/alertMessage";

const Gallery = () => {

  
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({
    title: "",
    brochure: "",
  });
  const [fileUrl, setFileUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
     
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setData({
      title: "",
      brochure: "",
    });
    setSelectedImage(null);
    setShowModal(false);
    setFileType("");
  };

  const loadGallery = async (e) => {
    const requestUrl = "/api/organisation/getGalleryImage";
    const response = await privateApiCall(requestUrl, "GET");

    setGalleryImages(response.data.images);
  };
  useEffect(() => {
    loadGallery();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestUrl = "/api/organisation/imageUpload";

      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("brochure", data.brochure);
      const response = await privateApiCall(requestUrl, "POST", formData
      );
      handleCloseModal();
      loadGallery();
      toast.info(response.data.message, {
        position: "top-right",
        transition: Bounce,
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleFileUpload = (file) => {
    const fileType = file.type;

    setFileType(fileType);
    setData((prevState) => ({ ...prevState, brochure: file }));
    setFileUrl(URL.createObjectURL(file));
  };
  const handleDeleteImage = async (imageUrl) => {
    try {
      const result = await Swal.fire({
        title: `${DO_YOU_WANT_TO_DELETE_BROCHURE}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: " #3085d6",
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        cancelButtonText: `${ALERT_BUTTON_NO}`,
      });
      if (result.isConfirmed) {
        const requestUrl = `/api/organisation/deleteImage`;
        const response = await privateApiCall(requestUrl, "DELETE", {
          imageUrl,
        });
        loadGallery();
        toast.error(response.data.message, {
          position: "top-right",
          transition: Bounce,
        });
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleViewImage = (image) => {
    setSelectedImage(image);
    setViewModal(true);
  };
  const handleCloseViewModal = () => {
    setSelectedImage(null);
    setViewModal(false);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeImage, setActiveImage] = useState(null);

  const handleClick = (event, image) => {
    setAnchorEl(event.currentTarget);
    setActiveImage(image);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveImage(null);
  };
 

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">
                    {STRING_CONSTANTS.YOUR_GALLERY}
                  </h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">
                        {STRING_CONSTANTS.DASHBOARD}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {STRING_CONSTANTS.GALLERY}
                    </li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <div className="row">
                    <div className="col-12">
                      <div className="p-20">
                      {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgGallery" && item.isCreate
              )) && (
              
                <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0baae5",
                  color: "#fff",
                  marginBottom: "20px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#0baae5",
                  },
                }}
                onClick={handleShowModal}
              >
                + {STRING_CONSTANTS.ADD_BROUCURE}
              </Button>
       
            )}
                
                       
                        <Modal
                          open={showModal}
                          onClose={handleCloseModal}
                          closeAfterTransition
                          BackdropProps={{
                            style: {
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                          }}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          disableBackdropClick
                        >
                          <Fade in={showModal}>
                            <Box
                              sx={{
                                bgcolor: "background.paper",
                                boxShadow: 1,
                                p: 4,
                                borderRadius: 2,
                                width: "60%",
                                marginBottom: "20px",
                              }}
                            >
                              <h2>{STRING_CONSTANTS.ADD_BROUCURE}</h2>
                              <form onSubmit={handleSubmit}>
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ marginTop: "0px" }}
                                >
                                  <Grid item xs={12} sm={6} md={4}>
                                    {fileType.startsWith("image/") ? (
                                      <img
                                        src={fileUrl}
                                        alt="Uploaded"
                                        height="150px"
                                        width="100%"
                                      />
                                    ) : fileType === "application/pdf" ? (
                                      <embed
                                        src={fileUrl}
                                        type="application/pdf"
                                        width="100%"
                                        height="150px"
                                      />
                                    ) : fileType === "application/msword" ||
                                      fileType ===
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                      <a
                                        href={fileUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {STRING_CONSTANTS.VIEW_DOCUMENT}
                                      </a>
                                    ) : (
                                      <img
                                        src="/assets/icons/try.jpg"
                                        alt=""
                                        height="150px"
                                        width="100%"
                                      />
                                    )}
                                    <div className="mt-3">
                                      <input
                                        type="file"
                                        id="file"
                                        name="brochure"
                                        className="mt-3 mb-3"
                                        onChange={(e) =>
                                          handleFileUpload(e.target.files[0])
                                        }
                                        accept=".pdf, .png, .jpeg, .jpg, .doc"
                                      />
                                      <p
                                        style={{
                                          color: "blue",
                                          whiteSpace: isSmallScreen ? 'normal' : 'nowrap',
                                          
                                        }}
                                      >
                                        {STRING_CONSTANTS.ALLOW_FORMAT}
                                      </p>
                                    </div>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <TextField
                                      fullWidth
                                      label="Title"
                                      variant="outlined"
                                      placeholder="Enter Title"
                                      name="title"
                                      value={data.title}
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          title: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  justifyContent="flex-end"
                                  style={{
                                    marginTop: "40px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    style={{ marginRight: "10px" }}
                                    onClick={handleCloseModal}
                                  >
                                    {STRING_CONSTANTS.CLOSE}
                                  </Button>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      backgroundColor: "#0baae5",
                                    }}
                                  >
                                    {STRING_CONSTANTS.ADD}
                                  </Button>
                                </Grid>
                              </form>
                            </Box>
                          </Fade>
                        </Modal>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <Grid container spacing={2}>
                      {galleryImages.map((image) => (
                        <Grid item xs={12} sm={6} md={4} key={image.url}>
                          <Card sx={{ height: 300 }}>
                            <Box
                              position="relative"
                              sx={{ height: "100%", width: "100%" }}
                            >
                              {image.url.includes(".pdf") ? (
                                <iframe
                                  title="PDF Viewer"
                                  src={image.url}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                />
                              ) : (
                                <CardMedia
                                  component="img"
                                  image={image.url}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                              <Box
                                position="absolute"
                                top={0}
                                right={0}
                                display="flex"
                                flexDirection="column"
                              >
                                <IconButton
                                  color="primary"
                                  onClick={(event) => handleClick(event, image)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                >

{(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgGallery" && item.isCreate
              )) && (
                <MenuItem
                onClick={() => {
                  handleViewImage(activeImage);
                  handleClose();
                }}
              >
                {STRING_CONSTANTS.VIEW}
              </MenuItem>
               
       
            )}
            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgGallery" && item.isCreate
              )) && (
                <MenuItem
                onClick={() => {
                  handleDeleteImage(activeImage.title);
                  handleClose();
                }}
              >
                {STRING_CONSTANTS.DELETE}
              </MenuItem>
               
       
            )}
                               
                                
                                </Menu>
                              </Box>
                            </Box>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </div>

                  <Modal
                    open={viewModal}
                    onClose={handleCloseViewModal}
                    closeAfterTransition
                    BackdropProps={{
                      style: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      },
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    disableBackdropClick
                  >
                    <Fade in={viewModal}>
                      <Box
                        sx={{
                          bgcolor: "background.paper",
                          boxShadow: 1,
                          p: 4,
                          borderRadius: 2,
                          width: "60%",
                          marginBottom: "20px",
                        }}
                      >
                        {selectedImage && (
                          <>
                            {selectedImage.url.includes(".pdf") ? (
                              <iframe
                                title="PDF Viewer"
                                src={selectedImage.url}
                                style={{
                                  width: "100%",
                                  height: "60vh",
                                  border: "none",
                                }}
                              />
                            ) : (
                              <img
                                src={selectedImage.url}
                                alt={selectedImage.title}
                                style={{
                                  width: "100%",
                                  maxHeight: "100vh",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                            <Typography variant="h6" component="div" mt={2}>
                              {STRING_CONSTANTS.TITLE}
                              {selectedImage.title}
                            </Typography>
                            <Typography variant="h6" color="text.secondary">
                              {STRING_CONSTANTS.UPLOAD_DATE}
                              {selectedImage.submittedDate}
                            </Typography>
                          </>
                        )}
                        <Grid
                          container
                          justifyContent="flex-end"
                          style={{
                            marginTop: "40px",
                            marginBottom: "0px",
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="error"
                            style={{ marginRight: "10px" }}
                            onClick={handleCloseViewModal}
                          >
                            {STRING_CONSTANTS.CLOSE}
                          </Button>
                        </Grid>
                      </Box>
                    </Fade>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
