import React, { useState } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import { Link } from "react-router-dom";
import Buyproduct from "./leads/buyproduct";
import Viewproduct from "./leads/viewproduct";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const Leads = () => {
  
  
  const [selectedProduct, setSelectedProduct] = useState(null);

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">{STRING_CONSTANTS.YOUR_LEADS}</h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                    </li>
                    <li className="breadcrumb-item active">{STRING_CONSTANTS.LEADS}</li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ flexBasis: "40%", padding: "10px" }}>
                <Buyproduct onSelectProduct={setSelectedProduct} />
              </div>
              <div style={{ flexBasis: "60%", padding: "10px" }}>
                <Viewproduct product={selectedProduct} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leads;
