import React, { useState,useEffect,useRef } from "react";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import data from "@emoji-mart/data";
import { io } from "socket.io-client";
import Picker from "@emoji-mart/react";
import Cookies from "universal-cookie";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SendIcon from "@mui/icons-material/Send";
import { ADD_EMPLOYEE } from "../../../constants/url";
import { privateApiCall } from "../../../api/privateApi";
import { API } from "../../../config";
const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingTop: "12px",
    paddingBottom: "12px",
  },
}));

const ChatBox = ({ selectedChate,onShowContactInfo }) => {
  const cookies = new Cookies();
  const [org_id] = useState(cookies.get("org_id"));
  const socket = useRef(); 
  const [openPicker,setOpenPicker]=useState(false)
  const [messageInput, setMessageInput] = useState(""); 
  const [messages, setMessages] = useState([]);
  const [chatID,setChatID]=useState(null)
  const userName = selectedChate?.otherParticipant?.name || "Unknown User";
  const userImage =
    selectedChate?.otherParticipant?.image ||
    "/static/images/default-avatar.jpg";
const handleUserClick = () => {
      setChatID(selectedChate.chatId)
        if (onShowContactInfo && selectedChate?.otherParticipant) {
            onShowContactInfo(selectedChate.otherParticipant);  // Pass the otherParticipant object
          }
};
useEffect(() => {
socket.current = io(API); 
socket.current.emit("new-user-add", org_id);
}, []);
useEffect(() => {
        const handleGetMessages = async (chatID) => {
          try {
            const response = await privateApiCall(`/api/organisation/getMessages/${chatID}`, "GET");
            console.log(response.data, "message data from api call");
            setMessages(response.data);
          } catch (error) {
            console.error(error);
          }
        };
    
        if (selectedChate?.chatId) {
          handleGetMessages(selectedChate.chatId);
        }
}, [selectedChate]); 
useEffect(() => {
  socket.current.on("receiver-msg", (data) => {
    console.log(data,"review")
    
      setMessages((prevMessages) => [...prevMessages, data]);
    
  });
}, [socket]);

  if (!selectedChate) {
    return (
      <Stack
        height={"100%"}
        maxHeight={"100vh"}
        width={"auto"}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="subtitle1">Tap to select a user chat</Typography>
      </Stack>
    );
  }
  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };
  const handleSendMessage=async()=>{
    if(!messageInput.trim()) return 
    const messageData={
      ChatId:selectedChate.chatId,
      receiverId:selectedChate.messages[0].senderId,
      senderId:org_id,
      productId:selectedChate.product.productId,
      text:messageInput

    }
    socket.current.emit("send-message", messageData);
    console.log(messageData,"message data")
      try {
        const requestUrl=`${ADD_EMPLOYEE}/admsg`;
        const response=await privateApiCall(requestUrl,"POST",messageData)
        console.log(response,"message send response")
        setMessages((prevMessages) => [...prevMessages, messageData]);
        setMessageInput(""); 
      } catch (error) {
        
      }
  }
console.log(selectedChate,"inside chatbox")

  return (
    <Stack height={"100%"} maxHeight={"100vh"} width={"auto"}>
      {/* Chat Header */}
      <Box
        p={2}
        sx={{
          width: "100%",
          backgroundColor: "#F8FAFF",
          //   boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
          boxShadow: "red",
        }}
      >
        <Stack
          alignItems={"center"}
          direction="row"
          justifyContent={"space-between"}
          sx={{ width: "100%", height: "100%" }}
        >
          <Stack direction={"row"} spacing={2}>
            <Box>
              <Badge>
                <Avatar src={userImage} sx={{ width: 50, height: 50 }} />
              </Badge>
            </Box>
            <Stack spacing={0.2}>
              <Typography  onClick={handleUserClick} variant="subtitle2" sx={{ cursor: 'pointer' }}>{userName}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      {/* Msg */}
      <Box
  width={"100%"}
  sx={{
    flexGrow: 1,
    padding: 2,
    height: 80,
    overflowY: "auto",
    backgroundColor: "#E9ECEF",
  }}
>
  {messages?.map((message, index) => (
    <Stack
      key={index}
      direction={message.senderId == org_id ? "row-reverse" : "row"}
      alignItems={"center"}
      spacing={1}
      sx={{ marginBottom: 2 }}
    >
      <Avatar
        src={
          message.senderId == org_id
            ? selectedChate.OrgLogo
            : selectedChate.otherParticipant.image
        }
        sx={{ width: 32, height: 32 }}
      />
      <Box
        sx={{
          backgroundColor:
            selectedChate.messages[0].senderId == org_id ? "#0baae5" : "#FFFFFF",
          borderRadius: 1,
          padding: 1.5,
          maxWidth: "75%",
        }}
      >
        {/* Render text if available */}
        {message.text && (
          <Typography variant="body2">{message.text}</Typography>
        )}
        
 
        {message.chatImage && Array.isArray(message.chatImage) && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, marginTop: 1 }}>
            {message.chatImage.map((image, imgIndex) => (
              <img
                key={imgIndex}
                src={image}
                alt={`chat-image-${imgIndex}`}
                style={{
                  maxWidth: "100px",
                  maxHeight: "100px",
                  borderRadius: "4px",
                  objectFit: "cover",
                }}
              />
            ))}
          </Box>
        )}
        
        {/* Time display */}
        <Typography
          variant="caption"
          sx={{
            display: "block",
            textAlign: "right",
            color: "gray",
            marginTop: 0.5,
          }}
        >
          {new Date(selectedChate.messages[0].date).toLocaleTimeString()}
        </Typography>
      </Box>
    </Stack>
  ))}
</Box>

      {/* Chat footer */}
      <Box
        p={2}
        sx={{ height: 100, width: "100%", backgroundColor: "#F8FAFF" }}
      >
        <Stack direction="row" alignItems={"center"} spacing={1}>
          <StyledInput 
            fullWidth
            placeholder="write a message..."
            variant="filled"
            value={messageInput}
            onChange={handleInputChange}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment>
                  <IconButton >
                    <AttachFileIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={()=>{setOpenPicker((prev)=>!prev)}}>
                    <Stack>
                      <Box sx={{display:openPicker?"inline":"none",zIndex:10,position:"fixed",bottom:81,right:100}}>
                        <Picker data={data} onEmojiSelect={console.log} />
                      </Box>
                    </Stack>
                    <SentimentSatisfiedAltIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box>
            <IconButton>
              <SendIcon onClick={handleSendMessage}/>
            </IconButton>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};

export default ChatBox;
