import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Cookies from "universal-cookie";
import  Swal  from "sweetalert2/dist/sweetalert2";

import { ADD_CATEGORY, DASHBOARD, EDIT_CATEGORY } from "../../constants/url";
import { ALERT_BUTTON_OK, ERR_ALERT_TITLE, ERR_GETTING,ERR_POSTING } from "../../constants/alertMessage";
import { Button, Modal } from "@mui/material"; // Import MUI components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"; // Import MUI components
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

class Category extends Component {
  constructor() {
    super();
    window.postMessage("renderTable", "*");
    this.state = {
      data: [],
      showModal: false,
      deleteId: "",
    };
  }

  componentDidMount() {
  const requestUrl = `${ADD_CATEGORY}/?token=${cookies.get("agritech_token")}`
  privateApiCall(requestUrl,"GET")
      .then(
        (res) => {
          const k = 1;

          for (let i = 0; i < res.data.length; i++) {
            res.data[i].sno = k + +i;
          }

          this.setState({ data: res.data });
        },
        (err) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`
          });
        }
      );
  }

  iconformat(cell, row) {
    return <img src={cell} style={{ height: "50px" }} alt="category" />;
  }

  handleEdit = (row) => {
    // Navigate to edit category page
    window.location.href=`${EDIT_CATEGORY}/${row._id}`
  };

  handleDelete = (id) => {
    // Show delete confirmation modal
    this.setState({ showModal: true, deleteId: id });
  };

  confirmDelete = (id) => {
    // Delete category
    
  const requestUrl = `/delete_category/${id}/?token=${cookies.get("asia_page_token")}`
privateApiCall(requestUrl,'POST',{})

      .then(() => {
        // Reload data after deletion
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: {ALERT_BUTTON_OK}
        });
      });
  };

  handleCloseModal = () => {
    // Close modal
    this.setState({ showModal: false, deleteId: "" });
  };

  render() {
    const { data, showModal, deleteId } = this.state;

    return (
      <div id="wrapper">
        <Topbar backgroundColor="#769A0F" />
        <Sidebar />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title float-left">{STRING_CONSTANTS.CATEGORY}</h4>
                    <ol className="breadcrumb float-right">
                      <li className="breadcrumb-item">
                        {" "}
                        <NavLink to={DASHBOARD}>{STRING_CONSTANTS.DASHBOARD}</NavLink>
                      </li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.CATEGORY}</li>
                    </ol>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card-box">
                    <div style={{ width: "98%" }}>
                      <Link
                        to={ADD_CATEGORY}
                        className="btn btn-primary"
                        style={{ float: "left", backgroundColor: "#11abff" }}
                      >
                       {STRING_CONSTANTS.ADD_CATEGORY}
                      </Link>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell width="80px">{STRING_CONSTANTS.SN_NO}</TableCell>
                              <TableCell width="180px">{STRING_CONSTANTS.CATEGORY_NAME}</TableCell>
                              <TableCell width="170px">
                                {STRING_CONSTANTS.CATEGORY_IMAGE}
                              </TableCell>
                              <TableCell width="80px">{STRING_CONSTANTS.EDIT}</TableCell>
                              <TableCell width="80px">{STRING_CONSTANTS.DELETE}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                  <img
                                    src={row.image}
                                    style={{ height: "50px" }}
                                    alt={STRING_CONSTANTS.CATEGORY}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() => this.handleEdit(row)}
                                    variant="contained"
                                    color="info"
                                  >
                                    {STRING_CONSTANTS.EDIT}
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() => this.handleDelete(row._id)}
                                    variant="contained"
                                    color="danger"
                                  >
                                    {STRING_CONSTANTS.DELETE}
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Modal open={showModal} onClose={this.handleCloseModal}>
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">{STRING_CONSTANTS.WARNING}</h5>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={this.handleCloseModal}
                              ></button>
                            </div>
                            <div className="modal-body">{STRING_CONSTANTS.ARE_YOU_SURE}</div>
                            <div className="modal-footer">
                              <Button
                                onClick={() => this.confirmDelete(deleteId)}
                                variant="contained"
                                color="primary"
                              >
                                {STRING_CONSTANTS.DELETE}
                              </Button>
                              <Button
                                onClick={this.handleCloseModal}
                                variant="contained"
                                color="secondary"
                              >
                                {STRING_CONSTANTS.CLOSE}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Category;
