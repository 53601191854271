import React, { useState, useEffect, useRef } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { privateApiCall } from "../../api/privateApi";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  ALERT_BUTTON_OK,
  ALERT_ICON_ERR,
  ERR_ALERT_TITLE,
  ERR_GETTING,
} from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import { GOOGLE_MAP_API_KEY } from "../../constants/apiKey";

function MapComponent() {
  const [coordinates, setCoordinates] = useState([]);
  const [modalOpen, setModalOpen] = useState(false); // Add modal open state
  const apiKey = GOOGLE_MAP_API_KEY;
  const mapRef = useRef(null);  
  const infoWindowRef = useRef(null);  

  useEffect(() => {
    fetchCoordinates();
  }, []);

  async function fetchCoordinates() {
    try {
      const requestUrl = "/treelocation";
      const response = await privateApiCall(requestUrl, "GET");
      setCoordinates(response.data);
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        icon: `${ALERT_ICON_ERR}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  }

  useEffect(() => {
    async function loadMapScript() {
      const script = document.createElement("script");
      script.innerHTML = `
        (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=\`https://maps.\${c}apis.com/maps/api/js?\`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
          key: "${apiKey}",
          v: "weekly"
        });
      `;
      script.defer = true;
      script.onload = initMap;
      document.head.appendChild(script);
    }

    async function initMap() {
      if (!window.google || !window.google.maps) {
        console.error("Google Maps API not loaded");
        return;
      }

      const { Map, InfoWindow } = await window.google.maps.importLibrary("maps");
      const { AdvancedMarkerElement, PinElement } = await window.google.maps.importLibrary("marker");

      const map = new Map(document.getElementById("map"), {
        zoom: 6,
        center: { lat: 11.060432245829782, lng: 76.07166445683364 },
        mapId: GOOGLE_MAP_API_KEY,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
      });

      mapRef.current = map;  
      const infoWindow = new InfoWindow({ disableAutoPan: true });
      infoWindowRef.current = infoWindow; // Store the info window instance in the reference

      const validCoordinates = coordinates.filter(coordinate =>
        coordinate.latitude !== undefined &&
        coordinate.longitude !== undefined &&
        coordinate.latitude !== "" &&
        coordinate.longitude !== ""
      );

      const markers = validCoordinates.map((coordinate, i) => {
        const latitude = parseFloat(coordinate.latitude);
        const longitude = parseFloat(coordinate.longitude);

        if (isNaN(latitude) || isNaN(longitude)) {
          console.error("Invalid latitude or longitude:", coordinate);
          return null;
        }

        const pinGlyph = new PinElement({ glyph: `1`, glyphColor: "white" });
        const marker = new AdvancedMarkerElement({
          position: { lat: latitude, lng: longitude },
          content: pinGlyph.element,
        });
        marker.addListener("click", async () => {
          try { 
            if (map.getZoom() < 17) {
              map.setZoom(map.getZoom() + 2);
              map.setCenter(marker.position);
              return;
            }

            const requestUrl = `/getmoreinfotree?latitude=${latitude}&longitude=${longitude}`;
            const response = await privateApiCall(requestUrl, "GET");
            const data = response.data;

            let content = "";
            if (data && data.length > 0) {
              const contentArray = data.map((rowData) => {
                return `
                <div style="width: 250px;">
                  <div id="carouselExampleIndicators${i}" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                      ${[rowData.image1, rowData.image2, rowData.image3, rowData.image4]
                        .map((image, index) => {
                          if (image !== "") {
                            return `<div class="carousel-item${index === 0 ? " active" : ""}">
                                      <img src="${image || "/assets/images/no_image.png"}" class="d-block w-100" alt="Image ${index + 1}">
                                    </div>`;
                          }
                          return "";
                        }).join("")}
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleIndicators${i}" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators${i}" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                  <div>
                  <p style="margin-bottom: 0px; font-weight: bold;">Farmer Name: ${rowData.name}</p>
                    <p style="margin-bottom: 0px; font-weight: bold;">Number of Jackfruit Trees: ${rowData.ans2}</p>
                     
                    <p style="margin-bottom: 0px; font-weight: bold;">Location: ${rowData.placeName}</p>
                    <p style="margin-bottom: 0px; font-weight: bold;">Harvesting Time: ${rowData.ans4}</p>
                    <p style="margin-bottom: 0px; font-weight: bold;">Special Variety Available: ${rowData.ans9}</p>
                  </div>
                </div>`;
              });

              content = contentArray.join("");
            } else {
              content = `<p>${STRING_CONSTANTS.LOADING_DATA}</p>`;
            }

            infoWindow.setContent(content);
            infoWindow.open(map, marker);
            setModalOpen(true); // Set modal open state to true
          } catch (error) {
            console.error(error);
          }
        });

        return marker;
      }).filter(marker => marker !== null);

      const markerCluster = new MarkerClusterer({ markers, map });

      markerCluster.addListener("click", (event) => {
        const markers = event.markers;
        if (markers.length > 0) {
          const bounds = new window.google.maps.LatLngBounds();
          markers.forEach(marker => bounds.extend(marker.position));
          map.fitBounds(bounds);
          map.setZoom(map.getZoom() + 1);
        }
      });

      // Handle click outside info window to close it
      const handleClickOutside = (event) => {
        const infoWindowElement = document.querySelector('.gm-style-iw');
        if (infoWindowElement && !infoWindowElement.contains(event.target)) {
          infoWindow.close();
          setModalOpen(false); // Set modal open state to false
        }
      };

      document.addEventListener("click", handleClickOutside);

      // Handle zoom changes to close the info window
      map.addListener("zoom_changed", () => {
        infoWindow.close();
        setModalOpen(false); // Set modal open state to false
      });

      return () => {
        const script = document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]');
        if (script) {
          document.head.removeChild(script);
        }
        document.removeEventListener("click", handleClickOutside);
        map.removeListener("zoom_changed", handleClickOutside);
      };
    }

    if (coordinates.length > 0) {
      initMap();
    } else {
      loadMapScript();
    }

  }, [coordinates]);

  return (
    <div
      id="map"
      style={{
        width: "100%",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
      }}
    ></div>
  );
}

export default MapComponent;
