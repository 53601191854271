export const SURVEY_QUESTIONS = {
    QUESTION1: "1. Are you a grower or seller?",
    QUESTION2: "2. How many Jack trees are there?",
    QUESTION2A: "2a. How many soft flesh?",
    QUESTION2B: "2b. How many hard flesh?",
    QUESTION3: "3. What are the types of Jack trees available in your property?",
    QUESTION4: "4. What is the usual harvesting time for your Jack Tree?",
    QUESTION5: "5. How much you earned last year from jackfruit sales?",
    QUESTION6: "6. Are you willing to sell for better advantage?",
    QUESTION7: "7. Are you willing to deliver Jackfruit to local collection center without any damage?",
    QUESTION8: "8. Are you doing any value addition of Jackfruit?",
    QUESTION9: "9. Do you have any special varieties of jackfruit?",
    QUESTION10: "10. Tag Your land to verify",
   
    // Additional details for specialJackfruitInfo
    SPECIAL_INFO_LOCAL_NAME: "9.a. Does Your unique variety have a local name?",
    SPECIAL_INFO_AGE: "9.b. What is the approximate age of the jackfruit tree?",
    SPECIAL_INFO_HEIGHT: "9.c. What is the approximate height of the jackfruit tree?",
    SPECIAL_INFO_WIDTH: "9.d. What is the width of the trunk (wood) of the jackfruit tree?",
    SPECIAL_INFO_YIELD: "9.e. what is the average fruit yield of the jackfruit tree?",
    SPECIAL_INFO_SEASON: "9.f. During which month(s) is the harvest season?",
    SPECIAL_INFO_SIZE: "9.g. Average size of the fruits?",
    SPECIAL_INFO_BULBS: "9.h. Number of approximate bulbs?",
    SPECIAL_INFO_BULB_COLOR: "9.i. Color of the bulb?",
    SPECIAL_INFO_RIND_COLOR: "9.j. Color of the Jackfruit rind?",
    SPECIAL_INFO_BULB_SIZE: "9.k. Size of the bulb?",
    SPECIAL_INFO_BULB_TEXTURE: "9.l. Texture of the bulbs?",
    SPECIAL_INFO_TRADITIONAL_USE: "9.m. Traditional use if any?",
    SPECIAL_INFO_OTHER_DETAILS: "9.n. Any other details?",



};
