export const PRODUCT_STATUS = Object.freeze({
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
  SOLD_OUT: 3,
  OFFER:4,
  AUCTION: 5,
  BID_ACCEPT: 6,
  BID_REJECT: 7,
  PAYMENT_PENDING:8,
  PAID: 9,
  PROCESS: 10,
  SHIP: 11,
  RECEIVE: 12,
  CLOSE: 13
});
export const getStatusString = (status) => {
   
  const statusMap = {
    [PRODUCT_STATUS.PENDING]: "Pending",
    [PRODUCT_STATUS.APPROVED]: "Approved",
    [PRODUCT_STATUS.REJECTED]: "Rejected",
    [PRODUCT_STATUS.OFFER]: "Offer",
    [PRODUCT_STATUS.AUCTION]: "Auction",
    [PRODUCT_STATUS.BID_ACCEPT]: "Bid accepted",
    [PRODUCT_STATUS.BID_REJECT]: "Bid rejected",
    [PRODUCT_STATUS.PAYMENT_PENDING]: "Payment pending",
    [PRODUCT_STATUS.PAID]: "Paid",
    [PRODUCT_STATUS.PROCESS]: "Process",
    [PRODUCT_STATUS.SHIP]: "Shipped",
    [PRODUCT_STATUS.RECEIVE]: "Received",
    [PRODUCT_STATUS.CLOSE]: "Closed",
  };
  return statusMap[status] || "";
};

