import React, { useState } from "react";
import axios from "axios";
import { API as url1 } from "../../config";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  ALERT_BUTTON_OK,
  ERR_ALERT_TITLE,
  ERR_POSTING,
  PASSWORD_MISSMATCH_MESSAGE,
  SUCC_ALERT_TITLE,
} from "../../constants/alertMessage";
import { Link } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2";
import { useLocation } from "react-router-dom";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const ResetPassword = () => {
  const [initialValues, setInitialValues] = useState({
    password: "",
    confirmPassword: "",
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    console.log(values, "values");
    if (values.password !== values.confirmPassword) {
      setSubmitting(false);
      return setFieldError(`${PASSWORD_MISSMATCH_MESSAGE}`);
    }
    axios
      .post(`${url1}/App/resetPassword`, {
        token,
        newPassword: values.password,
      })
      .then((res) => {
        setSubmitting(false);
        Swal.fire({
          title: `${SUCC_ALERT_TITLE}`,
          icon: "success",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        }).then(() => {
          window.location.href = "/";
        });
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_POSTING}`,
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`
        })
          .then(() => {
            setSubmitting(false);
            window.location.href = "/";
          })
          .finally(() => {
            setSubmitting(false);
          });
      });
  };

  return (
    <section
      style={{
        backgroundImage: `url("assets/images/login.png")`,
        height: "100vh",
        width: "100vw",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container">
        <div className="row justify-content-end pt-5">
          <div className="card bg-pattern" style={{ marginRight: "-3rem" }}>
            <div className="card-body mx-auto">
              <div className="text-center  ">
                <Link to="/">
                  <span>
                    <img src="assets/images/jack_logo.png" alt="" />
                  </span>
                </Link>
              </div>

              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="form-group">
                      <label htmlFor="reset password">{STRING_CONSTANTS.RESET_PASSWORD}</label>
                      <Field
                        className="form-control"
                        type="password"
                        id="password"
                        name="password"
                        placeholder={STRING_CONSTANTS.NEW_PASSWORD_PLACEHOLDER}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="confirm new password">
                        {STRING_CONSTANTS.CONFIRM_NEW_PASSWORD}
                      </label>
                      <Field
                        className="form-control"
                        type="password"
                        id="confirm-password"
                        name="confirmPassword"
                        placeholder={STRING_CONSTANTS.CONFIRM_NEW_PASS_PLACEHOLDER}
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group row align-items-center">
                      <div className="col-12">
                        <button
                          className="btn btn-block btn-primary waves-effect waves-light"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {STRING_CONSTANTS.SUBMIT}
                        </button>
                      </div>
                    </div>
                    <label htmlFor="back to login">
                      <a
                        style={{ textDecoration: "none", color: "inherit" }}
                        href="/"
                      >
                        {" "}
                       {STRING_CONSTANTS.BACK_TO_LOGIN}
                      </a>
                    </label>
                    <div className="form-check text-stone-500 text-sm font-normal align-items-center leading-none">
                      <div style={{ visibility: "hidden" }}>
                        <label
                          htmlFor="agreeTerms"
                          className="form-check-label mt-2"
                          style={{ width: "auto" }}
                        >
                          {STRING_CONSTANTS.BY_CLICKING_SING_IN}{" "}
                          <a
                            href="https://www.radtar.com/termsAndConditions.php"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="underline"
                            style={{ color: "green" }}
                          >
                            {" "}
                            {STRING_CONSTANTS.TERMS_AND_COND}
                          </a>{" "}
                          {STRING_CONSTANTS.AND}{" "}
                          <a
                            href="https://www.radtar.com/privacyPolicy.php"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "green" }}
                            className="underline"
                          >
                            {" "}
                           {STRING_CONSTANTS.PRIVACY_POLICY}
                          </a>
                          .
                        </label>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
