import React, { Component } from "react";
import $ from "jquery";
import { Chart } from "chart.js";

import { DASHBOARD_COUNT } from "../constants/url";
import { BsFillFileWordFill } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import { privateApiCall } from "../api/privateApi";
import {
  ALERT_BUTTON_OK,
  ERR_ALERT_TITLE,
  ERR_GETTING,
} from "../constants/alertMessage";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { STRING_CONSTANTS } from "../constants/stringConstants";

class Dashboard extends Component {
  componentDidMount() {
    const requestUrl = `${DASHBOARD_COUNT}/`;
    privateApiCall(requestUrl, "GET")
      .then(
        (res) => {
          this.setState({
            customer: res.data.puser,
            organisation: res.data.porg,
            member: res.data.pmem,
            proftotal: res.data.ptotal,
            sa: res.data.sa,
            ss: res.data.ss,
            se: res.data.se,
            sp: res.data.sp,
            sr: res.data.sr,
            st: res.data.st,
            bp: res.data.bp,
            ba: res.data.ba,
            br: res.data.br,
            bc: res.data.bc,
            be: res.data.be,
            bt: res.data.bt,
            bpp: res.data.bpp,
            bpa: res.data.bpa,
            bpr: res.data.bpr,
            bpt: res.data.bpt,
            keywordCount: res.data.keywordCount,
            active_count: res.data.active_count,
            inactive_count: res.data.inactive_count,
            surveyCount: res.data.surveyCount,
          });
        },
        (err) => {
          console.log("Error", err);
        }
      )
      .catch(() => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  }

  constructor() {
    super();
    this.state = {
      data1: [],
      city: [],
      count: [],
      data11: [],
      label: "",
      y: "",
      complement: "",
      complaint: "",
      customer: "",
      organisation: "",
      member: "",
      proftotal: "",

      sp: 0,
      sa: 0,
      sr: 0,
      se: 0,
      ss: 0,
      st: 0,

      bp: 0,
      ba: 0,
      br: 0,
      bc: 0,
      be: 0,
      bt: 0,

      bpp: 0,
      bpa: 0,
      bpr: 0,
      bpt: 0,

      sellPendingList: "",
      BuyPendingList: "",
      BussinessProfilePendingList: "",
      sellingCount: [],
      buyingCount: [],
      sales: "",

      keywordCount: 0,
      active_count: 0,
      inactive_count: 0,
      surveyCount: 0,
    };
  }

  render() {
    var ChartJs = function () {};

    ChartJs.prototype.respChart = function (selector, type, data, options) {
      var ctx = selector.get(0).getContext("2d");

      $(window).resize(generateChart);

      function generateChart() {
        switch (type) {
          case "Line":
            new Chart(ctx, { type: "line", data: data, options: options });
            break;
          case "Doughnut":
            new Chart(ctx, { type: "doughnut", data: data, options: options });
            break;
          case "Pie":
            new Chart(ctx, { type: "pie", data: data, options: options });
            break;
          case "Bar":
            new Chart(ctx, { type: "bar", data: data, options: options });
            break;
          case "Radar":
            new Chart(ctx, { type: "radar", data: data, options: options });
            break;
          case "PolarArea":
            new Chart(ctx, { data: data, type: "polarArea", options: options });
            break;
          default:
            break;
        }
      }
      generateChart();
    };

    return (
      <div id="wrapper">
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          {STRING_CONSTANTS.JACKFRUITWORLD_SMALL}
                        </li>
                        <li className="breadcrumb-item active">
                          {STRING_CONSTANTS.DASHBOARD}
                        </li>
                      </ol>
                    </div>
                    <h4 className="page-title">{STRING_CONSTANTS.DASHBOARD}</h4>

                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">
                  <div
                    className="card-box tilebox-one"
                    style={{ minHeight: "250px", color: "black" }}
                  >
                    <i className="fas fa-shopping-cart float-right"></i>
                    <h5 className="text-muted font-14 text-uppercase mb-3">
                      {STRING_CONSTANTS.BUY_PRODUCTS}
                    </h5>
                    <div className="content-container">
                      <div className="row">
                        <span>
                          <div id="Buy Product Total"></div>
                        </span>
                        <h4
                          className="ml-1"
                          data-tooltip-id="11"
                          data-tooltip-content={`Total Buy Products`}
                          style={{
                            fontSize: "50px",
                            fontWeight: "bold",
                            color: "green",
                          }}
                          data-plugin="counterup"
                        >
                          {this.state.bt}
                          <Tooltip id="11" style={{ fontSize: "12px" }} />
                        </h4>

                        <span>
                          <div className="ml-2" id="pending"></div>
                        </span>
                        <Link to="/buyProducts/penlist">
                          <h4
                            className="ml-1"
                            data-tooltip-id="12"
                            data-tooltip-content={`Pending`}
                            style={{ color: "orange" }}
                            data-plugin=""
                          >
                            {this.state.bp}
                            <Tooltip id="12" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>
                        <span>
                          <div className="ml-2" id="approved"></div>
                        </span>
                        <Link to="/buyProducts/applist">
                          <h4
                            className="ml-1"
                            data-tooltip-id="13"
                            data-tooltip-content={`Approved`}
                            style={{ color: "green" }}
                            data-plugin=""
                          >
                            {this.state.ba}
                            <Tooltip id="13" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>
                        <span>
                          <div className="ml-2" id="rejected"></div>
                        </span>
                        <Link to="/buyProducts/rejlist">
                          <h4
                            className="ml-1"
                            data-tooltip-id="14"
                            data-tooltip-content={`Rejected`}
                            style={{ color: "red" }}
                            data-plugin=""
                          >
                            {this.state.br}
                            <Tooltip id="14" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>
                        <span>
                          <div className="ml-2" id="expired"></div>
                        </span>
                        <Link to="/buyProducts/explist">
                          <h4
                            className="ml-1"
                            data-tooltip-id="9"
                            data-tooltip-content={`Expired`}
                            style={{ color: "blue" }}
                            data-plugin=""
                          >
                            {this.state.be}
                            <Tooltip id="9" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>
                        <span>
                          <div className="ml-2" id="completed"></div>
                        </span>
                        <Link to="/buyProducts/complist">
                          <h4
                            className="ml-1"
                            data-tooltip-id="33"
                            data-tooltip-content={`Completed`}
                            style={{ color: "brown" }}
                            data-plugin=""
                          >
                            {this.state.bc}
                            <Tooltip id="33" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">
                  <div
                    className="card-box tilebox-one"
                    style={{ minHeight: "250px", color: "black" }}
                  >
                    <i className="fas fa-list float-right"></i>
                    <h5 className="text-muted font-14 text-uppercase mb-3">
                      {STRING_CONSTANTS.SELL_PRODUCTS}
                    </h5>
                    <div className="content-container">
                      <div className="row">
                        <span>
                          <div id="Sell Product Total"></div>
                        </span>
                        <h4
                          className="ml-1"
                          data-tooltip-id="4"
                          data-tooltip-content="Total Sell Products"
                          style={{
                            fontSize: "50px",
                            fontWeight: "bold",
                            color: "green",
                          }}
                          data-plugin="counterup"
                        >
                          {this.state.st}
                          <Tooltip id="4" style={{ fontSize: "12px" }} />
                        </h4>

                        <span>
                          <div className="ml-2" id="pending"></div>
                        </span>
                        <Link to="/sellProducts/pendinglist">
                          <h4
                            className="ml-1"
                            data-tooltip-id="6"
                            data-tooltip-content="Pending"
                            style={{ color: "orange" }}
                            data-plugin=""
                          >
                            {this.state.sp}
                            <Tooltip id="6" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>

                        <span>
                          <div className="ml-2" id="approved"></div>
                        </span>
                        <Link to="/sellProducts/approvedlist">
                          <h4
                            className="ml-1"
                            data-tooltip-id="7"
                            data-tooltip-content="Approved"
                            style={{ color: "green" }}
                            data-plugin=""
                          >
                            {this.state.sa}
                            <Tooltip id="7" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>

                        <span>
                          <div className="ml-2" id="rejected"></div>
                        </span>
                        <Link to="/sellProducts/rejectedlist">
                          <h4
                            className="ml-1"
                            data-tooltip-id="8"
                            data-tooltip-content="Rejected"
                            style={{ color: "red" }}
                            data-plugin=""
                          >
                            {this.state.sr}
                            <Tooltip id="8" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>

                        <span>
                          <div className="ml-2" id="expired"></div>
                        </span>
                        <Link to="/sellProducts/expiredlist">
                          <h4
                            className="ml-1"
                            data-tooltip-id="9"
                            data-tooltip-content="Expired"
                            style={{ color: "blue" }}
                            data-plugin=""
                          >
                            {this.state.se}
                            <Tooltip id="9" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>

                        <span>
                          <div className="ml-2" id="sold"></div>
                        </span>
                        <Link to="/sellProducts/soldlist">
                          <h4
                            className="ml-1"
                            data-tooltip-id="10"
                            data-tooltip-content="Sold out"
                            style={{ color: "brown" }}
                            data-plugin=""
                          >
                            {this.state.ss}
                            <Tooltip id="10" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">
                  <div
                    className="card-box tilebox-one"
                    style={{ minHeight: "250px", color: "black" }}
                  >
                    <i className="fas fa-building float-right"></i>
                    <h5 className="text-muted font-14 text-uppercase mb-3">
                      {STRING_CONSTANTS.BUSINESS_PROFILE}
                    </h5>
                    <div className="content-container">
                      <div className="row">
                        <span>
                          <div id="Business profiles Total"></div>
                        </span>
                        <h4
                          className="ml-1"
                          data-tooltip-id="15"
                          data-tooltip-content={`Total Business Profiles`}
                          style={{
                            fontSize: "50px",
                            fontWeight: "bold",
                            color: "green",
                          }}
                          data-plugin="counterup"
                        >
                          {this.state.bpt}
                          <Tooltip id="15" style={{ fontSize: "12px" }} />
                        </h4>

                        <span>
                          <div className="ml-2" id="pending"></div>
                        </span>
                        <Link to="/BusinessProfile/pendinglist">
                          <h4
                            className="ml-1"
                            data-tooltip-id="16"
                            data-tooltip-content={`Pending`}
                            style={{ color: "orange" }}
                            data-plugin=""
                          >
                            {this.state.bpp}
                            <Tooltip id="16" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>

                        <span>
                          <div className="ml-2" id="approved"></div>
                        </span>
                        <Link to="/BusinessProfile/approvedlist">
                          <h4
                            className="ml-1"
                            data-tooltip-id="17"
                            data-tooltip-content={`Approved`}
                            style={{ color: "green" }}
                            data-plugin=""
                          >
                            {this.state.bpa}
                            <Tooltip id="17" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>

                        <span>
                          <div className="ml-2" id="rejected"></div>
                        </span>
                        <Link to="/BusinessProfile/rejectedlist">
                          <h4
                            className="ml-1"
                            data-tooltip-id="18"
                            data-tooltip-content={`Rejected`}
                            style={{ color: "red" }}
                            data-plugin=""
                          >
                            {this.state.bpr}
                            <Tooltip id="18" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">
                  <div
                    className="card-box tilebox-one"
                    style={{ minHeight: "250px", color: "black" }}
                  >
                    <i className="fas fa-users float-right"></i>
                    <h5 className="text-muted font-14 text-uppercase mb-3">
                      {STRING_CONSTANTS.PROFILES}
                    </h5>
                    <div className="content-container">
                      <div className="row">
                        <span>
                          <div id="Profile Total"></div>
                        </span>
                        <h4
                          className="ml-1"
                          data-tooltip-id="5"
                          data-tooltip-content={`Total Profiles`}
                          style={{
                            fontSize: "50px",
                            fontWeight: "bold",
                            color: "green",
                          }}
                          data-plugin="counterup"
                        >
                          {this.state.proftotal}
                          <Tooltip id="5" style={{ fontSize: "12px" }} />
                        </h4>

                        <span>
                          <div
                            className="ml-2"
                            id="customers"
                            data-tip="Customer"
                          ></div>
                        </span>
                        <Link to="/profiles/customer">
                          <h4
                            className="ml-1"
                            data-tooltip-id="1"
                            data-tooltip-content={`Customers`}
                            style={{ color: "orange" }}
                            data-plugin=""
                          >
                            {this.state.customer}
                            <Tooltip id="1" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>

                        <span>
                          <div className="ml-2" id="organisations"></div>
                        </span>
                        <Link to="/profiles/organisation">
                          <h4
                            className="ml-1"
                            data-tooltip-id="2"
                            data-tooltip-content={`Organisations`}
                            style={{ color: "green" }}
                            data-plugin=""
                          >
                            {this.state.organisation}
                            <Tooltip id="2" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>

                        <span>
                          <div className="ml-2" id="member"></div>
                        </span>
                        <Link to="/profiles/members">
                          <h4
                            className="ml-1"
                            data-tooltip-id="3"
                            data-tooltip-content={`Members`}
                            style={{ color: "red" }}
                            data-plugin=""
                          >
                            {this.state.member}
                            <Tooltip id="3" style={{ fontSize: "12px" }} />
                          </h4>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                
                <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">
                  <Link to="/keywords/keywords">
                    <div
                      className="card-box tilebox-one"
                      style={{ minHeight: "250px", color: "black" }}
                    >
                      <i className="fas fa-file-word float-right"></i>
                      <h5 className="text-muted font-14 text-uppercase mb-3">
                        {STRING_CONSTANTS.KEYWORDS}
                      </h5>
                      <div className="content-container">
                        <div className="row">
                          {/* Total Keywords */}
                          <span>
                            <div id="Keywords Total"></div>
                          </span>
                          <h4
                            className="ml-1"
                            data-tooltip-id="19"
                            data-tooltip-content="Total Keywords"
                            style={{
                              fontSize: "50px",
                              fontWeight: "bold",
                              color: "green",
                            }}
                            data-plugin="counterup"
                          >
                            {this.state.keywordCount}
                            <Tooltip id="19" style={{ fontSize: "12px" }} />
                          </h4>

                          <span>
                            <div className="ml-2" id="counts"></div>
                          </span>
                          <h4
                            className="ml-1"
                            data-tooltip-id="20"
                            data-tooltip-content="Active Keywords"
                            style={{ color: "orange" }}
                          >
                            {this.state.active_count}
                            <Tooltip id="20" style={{ fontSize: "12px" }} />
                          </h4>
                          <span>
                            <div className="ml-2" id="approved"></div>
                          </span>
                          <h4
                            className="ml-1"
                            data-tooltip-id="21"
                            data-tooltip-content="InActive Keywords"
                            style={{ color: "red" }}
                          >
                            {this.state.inactive_count}
                            <Tooltip id="21" style={{ fontSize: "12px" }} />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">
                  <Link to="/survey">
                    <div
                      className="card-box tilebox-one"
                      style={{ minHeight: "250px", color: "black" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5 className="text-muted font-14 text-uppercase mb-3">
                          {STRING_CONSTANTS.SURVEY}
                        </h5>
                        <div
                          style={{
                            backgroundColor: "#769A0F",
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <i
                            className="fas fa-clipboard-list"
                            style={{ fontSize: "24px", color: "white" }}
                          />
                        </div>
                      </div>
                      <div className="content-container">
                        <div className="row">
                          <span>
                            <div className="ml-2" id="Surveys"></div>
                          </span>
                          <div style={{ width: "30px" }}>
                            <h4
                              className="mb-3 ml-1"
                              data-tooltip-id="20"
                              data-tooltip-content={`Total Surveys`}
                              style={{
                                fontSize: "50px",
                                fontWeight: "bold",
                                color: "green",
                              }}
                              data-plugin="counterup"
                            >
                              {this.state.surveyCount}
                              <Tooltip id="20" style={{ fontSize: "12px" }} />
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
