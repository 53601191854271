import React, { Component } from "react";
import { Link } from "react-router-dom";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Menu,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Cookies from "universal-cookie";
import {
  CUSTOMERS,
  UPGRADE_SUBSCRIPTION,
  USER_SUBSCRIPTION,
  ORG_SUBSCRIPTION,
  CUSTOMER_SUB_SEARCH,
} from "../../constants/url";
import { privateApiCall } from "../../api/privateApi";
import { ALERT_BUTTON_OK, ERR_ALERT_TITLE } from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

class List extends Component {
  constructor() {
    const localRole = JSON.parse(localStorage.getItem("role"));
    super();
    this.state = {
      userData: [],
      organisationData: [],
      currentTab: `${STRING_CONSTANTS.USER}`,
      userSubscriptions: [],
      orgSubscriptions: [],
      anchorEl: null,
      selectedSubscription: null,
      selectedRowId: null,
      isOrgSubscriptionsLoaded: false,
      permissions:
        localRole && localRole.permissions ? localRole.permissions : "All",
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const requestUrl = `${CUSTOMERS}?token=${cookies.get("agritech_token")}`;
    privateApiCall(requestUrl, "GET")
      .then((res) => {
        if (res.data && Array.isArray(res.data)) {
          const userData = res.data.filter(
            (item) => item.type === `${STRING_CONSTANTS.USER}` || !item.type
          );
          const organisationData = res.data.filter(
            (item) => item.type === `${STRING_CONSTANTS.ORG}`
          );
          this.setState({
            userData: userData,
            organisationData: organisationData,
          });
        } else {
          throw new Error("");
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `${err}`,
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`
        });
      });
  }

  handleClick = (event, rowId) => {
    const { currentTab } = this.state;
    if (currentTab === `${STRING_CONSTANTS.USER}`) {
      this.fetchUserSubscriptions(rowId);
    } else if (currentTab === `${STRING_CONSTANTS.ORG}`) {
      this.fetchOrgSubscriptions(rowId);
    }
    this.setState({ anchorEl: event.currentTarget, selectedRowId: rowId });
  };

  fetchUserSubscriptions(rowId) {
    const requestUrl = `${USER_SUBSCRIPTION}/${rowId}?token=${cookies.get(
      "agritech_token"
    )}`;
    privateApiCall(requestUrl, "GET")
      .then((response) => {
        const userSubscriptions = response.data.filter(
          (subscription) => subscription.type === `${STRING_CONSTANTS.USER}`
        );
        this.setState({ userSubscriptions });
      })
      .catch((err) => {
        Swal.fire({
          title: `${err}`,
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`
        });
      });
  }

  fetchOrgSubscriptions(rowId) {
    const requestUrl = `${ORG_SUBSCRIPTION}/${rowId}?token=${cookies.get(
      "agritech_token"
    )}`;
    privateApiCall(requestUrl, "GET")
      .then((response) => {
        const orgSubscriptions = response.data.filter(
          (subscription) => subscription.type === `${STRING_CONSTANTS.ORG}`
        );
        this.setState({ orgSubscriptions, isOrgSubscriptionsLoaded: true });
      })
      .catch((err) => {
        Swal.fire({
          title: `${err}`,
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`
        });
      });
  }

  handleTabChange(tab) {
    this.setState({ currentTab: tab });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSubscriptionSelect = (subscription) => {
    const { selectedRowId, currentTab, userSubscriptions, orgSubscriptions } =
      this.state;
    if (selectedRowId) {
      const subscriptionStatus = subscription.subscription_id;

      const requestUrl = `${UPGRADE_SUBSCRIPTION}/${selectedRowId}`;
      privateApiCall(requestUrl, "POST", {
        newSubscriptionStatus: subscriptionStatus,
      })
        .then((response) => {
          this.fetchData();
        })
        .catch((error) => {
          Swal.fire({
            title: `${error}`,
            icon: "error",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`
          });
        });
    } else {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: "error",
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`
      });
    }
    this.setState({ anchorEl: null });

    const updatedSubscriptions =
      currentTab === `${STRING_CONSTANTS.USER}` ? userSubscriptions : orgSubscriptions;
    const filteredSubscriptions = updatedSubscriptions.filter(
      (sub) => sub.subscription_id !== subscription.subscription_id
    );

    this.setState({
      [currentTab === `${STRING_CONSTANTS.USER}` ? `${STRING_CONSTANTS.USER_SUBSCRIPTION}` : `${STRING_CONSTANTS.ORG_SUBSCRIPTION}`]:
        filteredSubscriptions,
    });
  };

  handleSearch = async (e) => {
    const searchValue = e.target.value;

    try {
      const requestUrl = `${CUSTOMER_SUB_SEARCH}?query=${searchValue}`;
      const response = await privateApiCall(requestUrl, "GET");
      if (response.data) {
        this.setState({
          userData: response.data.filter(
            (item) => item.type === `${STRING_CONSTANTS.USER}` || !item.type
          ),
          organisationData: response.data.filter(
            (item) => item.type === `${STRING_CONSTANTS.ORG}`
          ),
        });
      } else {
        this.setState({
          userData: [],
          organisationData: [],
        });
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        text: `${error}`,
        icon: "error",
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`
      });
    }
  };

  renderTable(data) {
    return (
      <div style={{ paddingTop: "10px" }}>
        <TableContainer component={Paper}>
          <Table aria-label="material-ui table">
            <TableHead>
              <TableRow>
                <TableCell align="center" width="80px">
                  {STRING_CONSTANTS.SN_NO}
                </TableCell>
                <TableCell align="center" width="180px">
                {STRING_CONSTANTS.NAME}
                </TableCell>
                <TableCell align="center" width="180px">
                {STRING_CONSTANTS.EMAIL}
                </TableCell>
                <TableCell align="center" width="180px">
                {STRING_CONSTANTS.MOBILE}
                </TableCell>
                <TableCell align="center" width="180px">
                {STRING_CONSTANTS.SUBSCRIPTION}
                </TableCell>
                <TableCell align="center" width="180px">
                {STRING_CONSTANTS.VALIDITY}
                </TableCell>
                <TableCell align="center" width="180px">
                {STRING_CONSTANTS.ACTIONS}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">{row.mobile_no}</TableCell>
                  <TableCell align="center">{row.sub}</TableCell>
                  <TableCell align="center">{row.val}</TableCell>
                  <TableCell align="center">
                    <div>
                      {this.state.permissions.some(
                        (item) =>
                          item.keyword === "subscriptionList" &&
                          item.isUpgrade === true
                      ) && (
                        <Button
                          sx={{
                            backgroundColor: "#769A0F",
                            color: "#ffffff",
                            border: "1px solid #769A0F",
                            borderRadius: "4px",
                            padding: "4px 8px",
                            cursor: "pointer",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#769A0F",
                            },
                          }}
                          aria-controls="subscription-menu"
                          aria-haspopup="true"
                          onClick={(event) => this.handleClick(event, row._id)}
                          // disabled={this.state.currentTab === 'Organisation' && this.state.orgSubscriptions.length === 0}
                        >
                          {STRING_CONSTANTS.UPGRADE}
                        </Button>
                      )}

                      <Menu
                        id="subscription-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                      >
                        {this.state.currentTab === "User"
                          ? this.state.userSubscriptions.map(
                              (subscription, index) => (
                                <MenuItem
                                  key={index}
                                  onClick={() =>
                                    this.handleSubscriptionSelect(subscription)
                                  }
                                >
                                  {subscription.name}
                                </MenuItem>
                              )
                            )
                          : this.state.orgSubscriptions
                              .filter(
                                (subscription) => subscription.name !== "Free"
                              )
                              .map((subscription, index) => (
                                <MenuItem
                                  key={index}
                                  onClick={() =>
                                    this.handleSubscriptionSelect(subscription)
                                  }
                                >
                                  {subscription.name}
                                </MenuItem>
                              ))}
                      </Menu>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  render() {
    const { currentTab, userData, organisationData } = this.state;

    return (
      <div id="wrapper">
        <Topbar backgroundColor="#769A0F" />
        <Sidebar type="subscription" />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                        </li>
                        <li className="breadcrumb-item active">{STRING_CONSTANTS.SUBSCRIPTION}</li>
                        <li className="breadcrumb-item active">{STRING_CONSTANTS.LIST}</li>
                      </ol>
                    </div>
                    <h4 className="page-title">{STRING_CONSTANTS.LIST}</h4>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card-box">
                    <div className="row mb-3">
                      <div
                        style={{
                          position: "relative",
                          marginLeft: "auto",
                          marginBottom: "5px",
                        }}
                      >

{this.state.permissions.some(
                        (item) =>
                          item.keyword === "subscriptionList" &&
                          item.isSearch === true
                      ) && (
                   <>
                   
                   <input
                          type="text"
                          placeholder="Search..."
                          className="form-control"
                          style={{
                            width: "200px",
                            paddingLeft: "35px",
                            boxSizing: "border-box",
                            marginLeft: "auto",
                          }}
                          onChange={(e) => {
                            this.handleSearch(e);
                            if (e.target.value.trim() === "") {
                              e.target.nextElementSibling.style.display =
                                "inline-block";
                            } else {
                              e.target.nextElementSibling.style.display =
                                "none";
                            }
                          }}
                        />
                        <i
                          className="fas fa-search"
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            color: "#aaa",
                          }}
                        ></i>
                   </>
                      )}

                      </div>

                      <div className="col-md-12">
                        <div
                          style={{
                            backgroundColor: "#CCCCCC",
                            // padding: '10px',
                            borderRadius: "4px",
                          }}
                        >
                          <Tabs
                            value={currentTab}
                            onChange={(event, newValue) =>
                              this.handleTabChange(newValue)
                            }
                            variant="fullWidth"
                            indicatorColor="transparent"
                            textColor="primary"
                          >
                            <Tab
                              label="User"
                              value="User"
                              sx={{
                                backgroundColor:
                                  currentTab === `${STRING_CONSTANTS.USER}` ? "#CCCCCC" : "#F5F5F5",
                                color:
                                  currentTab === `${STRING_CONSTANTS.USER}`? "#ffffff" : "#000000",
                                fontWeight: "bold",
                                "&:hover": {
                                  backgroundColor:
                                    currentTab === `${STRING_CONSTANTS.USER}`
                                      ? "#CCCCCC"
                                      : "#F5F5F5",
                                  color:
                                    currentTab === `${STRING_CONSTANTS.USER}`
                                      ? "#ffffff"
                                      : "#000000",
                                },
                                "&.Mui-selected": {
                                  color: "#000000",
                                },
                              }}
                            />
                            <div
                              style={{
                                width: "2px",
                                backgroundColor: "#F5F5F5",
                              }}
                            />
                            <Tab
                              label="Organisation"
                              value="Organisation"
                              sx={{
                                backgroundColor:
                                  currentTab === `${STRING_CONSTANTS.ORG}`
                                    ? "#CCCCCC"
                                    : "#F5F5F5",
                                color:
                                  currentTab === `${STRING_CONSTANTS.ORG}`
                                    ? "#ffffff"
                                    : "#000000",
                                fontWeight: "bold",
                                "&:hover": {
                                  backgroundColor:
                                    currentTab === `${STRING_CONSTANTS.ORG}`
                                      ? "#CCCCCC"
                                      : "#F5F5F5",
                                  color:
                                    currentTab === `${STRING_CONSTANTS.ORG}`
                                      ? "#ffffff"
                                      : "#000000",
                                },
                                "&.Mui-selected": {
                                  color: "#000000",
                                },
                              }}
                            />
                          </Tabs>
                        </div>
                      </div>
                    </div>

                    {currentTab === `${STRING_CONSTANTS.USER}` && this.renderTable(userData)}
                    {currentTab === `${STRING_CONSTANTS.ORG}` &&
                      this.renderTable(organisationData)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default List;
