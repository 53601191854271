import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Link, useNavigate } from "react-router-dom";

import { GET_ORG_PROFILE } from "../../constants/url";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { privateApiCall } from "../../api/privateApi";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ALERT_BUTTON_OK, ALERT_ICON_ERR, ERR_ALERT_TITLE } from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

const Topbar = ({ backgroundColor }) => {
  const [dname, setDname] = useState("");
  const [dimage, setDimage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const requestUrl = `${GET_ORG_PROFILE}/${cookies.get(
      "org_id"
    )}/?token=${cookies.get("org_token")}`;
    privateApiCall(requestUrl, "GET")
      .then((res) => {
        setDname(res.data.admin[0].name);
        setDimage(
          res.data.admin[0].logo === ""
            ? "/assets/images/organisationlogo.jpg"
            : res.data.admin[0].logo
        );
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  }, []);

  const logout = () => {
    cookies.remove("org_token");
    cookies.remove("org_id");
    navigate("/");
  };

  const handleMobileMenuClick = (event) => {
    event.preventDefault();
    document.body.classList.toggle("sidebar-enable");
    if (window.innerWidth >= 768) {
      document.body.classList.toggle("enlarged");
    } else {
      document.body.classList.remove("enlarged");
    }
  };

  const handleNavbarToggleClick = (event) => {
    event.preventDefault();
    const navigation = document.getElementById("navigation");
    if (navigation) {
      event.currentTarget.classList.toggle("open");
      navigation.style.transition = "height 0.4s ease-in-out";
      navigation.style.overflow = "hidden";
      navigation.style.height =
        navigation.clientHeight === 0 ? `${navigation.scrollHeight}px` : "0";
    }
  };

  return (
    <div className="navbar-custom" style={{ background: backgroundColor }}>
      <div className="container-fluid">
        <ul className="list-unstyled topnav-menu float-right mb-0">
          <li className="dropdown notification-list">
            <a
              className="navbar-toggle nav-link"
              onClick={handleNavbarToggleClick}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </li>
          <li className="dropdown">
            <Link
              className="nav-link waves-effect waves-light nav-user mr-0"
              data-toggle="dropdown"
              to="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img src={dimage} alt="" className="rounded-circle" />
              <span className="ml-1" style={{ color: "white" }}>
                {dname}
                <i className="mdi mdi-chevron-down"></i>
              </span>
            </Link>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link
                to="/organisation/viewprofile"
                className="dropdown-item notify-item"
              >
                <i className="fi-head"></i> <span>{STRING_CONSTANTS.MY_ACCOUNT}</span>
              </Link>
              <Link
                to="/organisation/mfasettings"
                className="dropdown-item notify-item"
              >
                <i className="fi-head"></i> <span>{STRING_CONSTANTS.MFA_CONFIGURATION}</span>
              </Link>
              <Link
                to="/"
                className="dropdown-item notify-item"
                onClick={logout}
              >
                <i className="fi-power"></i> <span>{STRING_CONSTANTS.LOGOUT}</span>
              </Link>
            </div>
          </li>
        </ul>

        <div className="logo-box">
          <a href="/organisation/dashboard" className="logo text-center">
            <span className="logo-lg">
              <img
                src="/assets/images/jack_logo.png"
                height={60}
                alt="jack_logo"
              />
            </span>
          </a>
        </div>

        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <button
              className="button-menu-mobile waves-effect waves-light"
              onClick={handleMobileMenuClick}
            >
              <i className="fe-menu"></i>
            </button>
          </li>
        </ul>

        <div className="tagline-container">
          <div className="tagline">
            {STRING_CONSTANTS.TAGLINE}
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="Bounce"
      />

      <style jsx>{`
        .tagline-container {
          overflow: hidden;
          white-space: nowrap;
          padding-top: 15px;
        }

        .tagline {
          font-size: 24px;
          color: white;
          font-family: initial;
          display: inline-block;
          animation: scroll 25s ease-in-out infinite;
        }

        @keyframes scroll {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(-100%);
          }
        }
      `}</style>
    </div>
  );
};

export default Topbar;
