import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Button, Typography, Paper, Table, TableBody, TableCell, TableRow, TableHead } from '@mui/material';
import { styled } from '@mui/system';
import { STRING_CONSTANTS } from '../../constants/stringConstants';


const dummyInvoice = {
  company: {
    name: 'RADTAR Innovation Pvt Ltd',
    address: '',
    phone: '',
    email: '',
    gstin: '',
    state: ''
  },
  billTo: {
    name: '',
    address: '',
    contactNo: '',
    gstin: '',
    state: ''
  },
  shippingTo: '',
  invoiceNumber: 'ABC-2022-0001',
  invoiceDate: 'DD/MM/YYYY',
  items: [
    { sno: 1, description: 'Item 1', quantity: 1, unit: 2, unitPrice: 200.00, discount: 20, gst: 5, amount: 189.00 },
    { sno: 2, description: 'Item 2', quantity: 1, unit: 4, unitPrice: 250.00, discount: 10, gst: 18, amount: 283.20 },
    { sno: 3, description: 'Item 3', quantity: 1, unit: 6, unitPrice: 340.00, discount: 20, gst: 5, amount: 336.00 },
 
  ],
  subtotal: 1890.00,
  discount: 140.00,
  sgst: 59.35,
  cgst: 59.35,
  total: 1868.70,
  terms: ''
};

const InvoiceContainer = styled(Paper)({
  padding: '20px',
  margin: '20px auto',
  backgroundColor: '#fff',
  maxWidth: '800px',
  position: 'relative',
});

const InvoiceHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
});

const CompanyInfoSection = styled('div')({
  marginBottom: '20px',
});

const InvoiceDetailsSection = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
});

const AddressSection = styled('div')({
  flex: 1,
  marginRight: '10px',
  '&:last-child': {
    marginRight: 0,
  },
});

const InvoiceFooter = styled('div')({
  marginTop: '20px',
  textAlign: 'center',
});

const TableHeadStyled = styled(TableHead)({
  backgroundColor: '#f0f0f0',
});

const handlePrint = () => {
  window.print();
};

const handleDownloadPDF = () => {
  const doc = new jsPDF();

  // Load the image directly without using canvas
  const img = new Image();
  img.src = '/assets/images/radtar.png';  
  img.onload = function() {
    doc.addImage(img, 'PNG', 140, 10, 40, 30);

    doc.setFontSize(18);
    doc.text('Tax Invoice', 20, 40);

    doc.setFontSize(12);
    doc.text(`Company: ${dummyInvoice.company.name}`, 20, 50);
    doc.text(`Address: ${dummyInvoice.company.address}`, 20, 60);
    doc.text(`Phone No.: ${dummyInvoice.company.phone}`, 20, 70);
    doc.text(`Email ID: ${dummyInvoice.company.email}`, 20, 80);
    doc.text(`GSTIN: ${dummyInvoice.company.gstin}`, 20, 90);
    doc.text(`State: ${dummyInvoice.company.state}`, 20, 100);

    doc.text(`Bill To: ${dummyInvoice.billTo.name}`, 20, 110);
    doc.text(`Name: ${dummyInvoice.billTo.name}`, 20, 120);
    doc.text(`Address: ${dummyInvoice.billTo.address}`, 20, 130);
    doc.text(`Contact No.: ${dummyInvoice.billTo.contactNo}`, 20, 140);
    doc.text(`GSTIN: ${dummyInvoice.billTo.gstin}`, 20, 150);
    doc.text(`State: ${dummyInvoice.billTo.state}`, 20, 160);

    doc.text(`Shipping To: ${dummyInvoice.shippingTo}`, 140, 110);
    doc.text(`Invoice No.: ${dummyInvoice.invoiceNumber}`, 140, 120);
    doc.text(`Date: ${dummyInvoice.invoiceDate}`, 140, 130);

    doc.autoTable({
      startY: 170,
      head: [['#', 'Item name', 'Quantity', 'Unit', 'Price/Unit', 'Discount', 'GST', 'Amount']],
      body: dummyInvoice.items.map(item => [
        item.sno,
        item.description,
        item.quantity,
        item.unit,
        item.unitPrice.toFixed(2),
        item.discount,
        item.gst + '%',
        item.amount.toFixed(2)
      ]),
      styles: { fontSize: 10, halign: 'center' },
      headStyles: { fillColor: [22, 160, 133] },
      columnStyles: {
        0: { halign: 'center' },
        1: { halign: 'left' },
        2: { halign: 'right' },
        3: { halign: 'right' },
        4: { halign: 'right' },
        5: { halign: 'right' },
        6: { halign: 'right' },
        7: { halign: 'right' }
      },
    });

    const finalY = doc.lastAutoTable.finalY;

    doc.text(`Subtotal: ${dummyInvoice.subtotal.toFixed(2)}`, 20, finalY + 10);
    doc.text(`Discount: ${dummyInvoice.discount.toFixed(2)}`, 20, finalY + 20);
    doc.text(`SGST: ${dummyInvoice.sgst.toFixed(2)}`, 20, finalY + 30);
    doc.text(`CGST: ${dummyInvoice.cgst.toFixed(2)}`, 20, finalY + 40);
    doc.text(`Total: ${dummyInvoice.total.toFixed(2)}`, 20, finalY + 50);

    doc.save(`Invoice-${dummyInvoice.invoiceNumber}.pdf`);
  };
};

const InvoiceDetails = () => {
  return (
    <InvoiceContainer id="invoice-container">
      <InvoiceHeader>
        <Typography variant="h4">{STRING_CONSTANTS.INVOICE}</Typography>
        <img src="/assets/images/radtar.png" alt="Logo" style={{ height: '90px' }} />
      </InvoiceHeader>

      <CompanyInfoSection>
        <Typography variant="body1"><strong>{STRING_CONSTANTS.COMPANY_NAME}:</strong> {dummyInvoice.company.name}</Typography>
        <Typography variant="body1"><strong>{STRING_CONSTANTS.ADDRESS}:</strong> {dummyInvoice.company.address}</Typography>
        <Typography variant="body1"><strong>{STRING_CONSTANTS.PHONE}:</strong> {dummyInvoice.company.phone}</Typography>
        <Typography variant="body1"><strong>{STRING_CONSTANTS.EMAIL}:</strong> {dummyInvoice.company.email}</Typography>
      </CompanyInfoSection>

      <InvoiceDetailsSection>
        <AddressSection>
          <Typography variant="body1"><strong>{STRING_CONSTANTS.BILL_TO}:</strong></Typography>
          <Typography variant="body1"><strong>{STRING_CONSTANTS.NAME}</strong> {dummyInvoice.billTo.name}</Typography>
          <Typography variant="body1"><strong>{STRING_CONSTANTS.ADDRESS}:</strong> {dummyInvoice.billTo.address}</Typography>
          <Typography variant="body1"><strong>{STRING_CONSTANTS.PHONE}:</strong> {dummyInvoice.billTo.contactNo}</Typography>
 
          <Typography variant="body1"><strong>{STRING_CONSTANTS.STATE}</strong> {dummyInvoice.billTo.state}</Typography>
        </AddressSection>
        <AddressSection>
          <Typography variant="body1"><strong>{STRING_CONSTANTS.SHIP_TO}:</strong> {dummyInvoice.shippingTo}</Typography>
          <Typography variant="body1"><strong>{STRING_CONSTANTS.INVOICE}:</strong> {dummyInvoice.invoiceNumber}</Typography>
          <Typography variant="body1"><strong>{STRING_CONSTANTS.DUE_DATE}:</strong> {dummyInvoice.invoiceDate}</Typography>
        </AddressSection>
      </InvoiceDetailsSection>

      <Table>
        <TableHeadStyled>
          <TableRow>
            <TableCell><strong>{STRING_CONSTANTS.SN_NO}</strong></TableCell>
            <TableCell><strong>{STRING_CONSTANTS.ITEMS}</strong></TableCell>
            <TableCell><strong>{STRING_CONSTANTS.QUANTITY}</strong></TableCell>
            <TableCell><strong>{STRING_CONSTANTS.UNIT_PRICE}</strong></TableCell>
            <TableCell><strong>{STRING_CONSTANTS.PRICE}</strong></TableCell>
            <TableCell><strong>{STRING_CONSTANTS.DISCOUNT}</strong></TableCell>
            <TableCell><strong>{STRING_CONSTANTS.GST}</strong></TableCell>
            <TableCell><strong>{STRING_CONSTANTS.AMOUNT}</strong></TableCell>
          </TableRow>
        </TableHeadStyled>
        <TableBody>
          {dummyInvoice.items.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.sno}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{item.unit}</TableCell>
              <TableCell>{item.unitPrice.toFixed(2)}</TableCell>
              <TableCell>{item.discount}</TableCell>
              <TableCell>{item.gst}%</TableCell>
              <TableCell>{item.amount.toFixed(2)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={7}><strong>{STRING_CONSTANTS.SUB_TOTAL}</strong></TableCell>
            <TableCell>{dummyInvoice.subtotal.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={7}><strong>{STRING_CONSTANTS.DISCOUNT}</strong></TableCell>
            <TableCell>{dummyInvoice.discount.toFixed(2)}</TableCell>
          </TableRow>
         
          <TableRow>
            <TableCell colSpan={7}><strong>{STRING_CONSTANTS.GST}</strong></TableCell>
            <TableCell>{dummyInvoice.cgst.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={7}><strong>{STRING_CONSTANTS.TOTAL}</strong></TableCell>
            <TableCell>{dummyInvoice.total.toFixed(2)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <InvoiceFooter>
        <Typography variant="h6" style={{ marginTop: '40px',  }}>{STRING_CONSTANTS.RADTAR}</Typography>
      </InvoiceFooter>

      <div style={{ textAlign: 'right', marginTop: '20px' }}>
        <Button onClick={handlePrint} color="primary" variant="contained" style={{ marginRight: '10px' }}>{STRING_CONSTANTS.PRINT}</Button>
        <Button onClick={handleDownloadPDF} color="info" variant="contained">{STRING_CONSTANTS.DOWNLOAD_PDF}</Button>
      </div>
    </InvoiceContainer>
  );
};

export default InvoiceDetails;