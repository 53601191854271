import React from "react";
import { STRING_CONSTANTS } from "../constants/stringConstants";

export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div>
        <link
          href="https://getbootstrap.com/docs/4.0/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <div className="container">
          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "center",
              fontStretch: "normal",
              fontSize: "18px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.PRIVACY_POLICY_CAPS}</strong>
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            {STRING_CONSTANTS.LAST_UPDATE}: January 16, 2024
          </p>
          <br />

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
       {STRING_CONSTANTS.PRIVACY_POLICY_DESCRIPTON}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>Interpretation and Definitions</strong>
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
          {STRING_CONSTANTS.PRIVACY_POLICY_CAPS_DEFENITON}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            {STRING_CONSTANTS.PRIVACY_POLICY_PURPOSES}:
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.ACCOUNT}</strong>{STRING_CONSTANTS.PRIVACY_POLICY_ACCOUNT_MEANING}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.AFFILIATE}</strong>
            {STRING_CONSTANTS.PRIVACY_POLICY_AFFILIATE}
          </p>
      
          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.COMPANY}</strong>{STRING_CONSTANTS.PRIVACY_POLICY_COMPANY}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.PERSONAL_DATA}</strong> {STRING_CONSTANTS.PERSONAL_DATA_MEANING}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.SERVICE_PROVIDER}</strong>{STRING_CONSTANTS.PRIVACY_POLICY_SERVICE_PROVIDER}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.USAGE_DATA}</strong>{STRING_CONSTANTS.PRIVACY_POLICY_USAGE_DATA}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
           {STRING_CONSTANTS.PRIVACY_POLICY_USAGE_DATA}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
             {STRING_CONSTANTS.PRIVACY_POLICY_USAGE_DATA_CONT_1}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            {STRING_CONSTANTS.PRIVACY_POLICY_USAGE_DATA_CONT_2}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
           {STRING_CONSTANTS.PRIVACY_POLICY_USAGE_DATA_CONT_3}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
          {STRING_CONSTANTS.PRIVACY_POLICY_USAGE_DATA_CONT_4}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
        {STRING_CONSTANTS.PRIVACY_POLICY_USAGE_DATA_CONT_5}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.PRIVACY_POLICY_TO_PROVIDE_AND_MAINTAIN}</strong>,{STRING_CONSTANTS.PRIVACY_POLICY_TO_MONITOR_THE_USEAGE}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.TO_MANAGE_YOUR_ACCOUNT}:</strong> {STRING_CONSTANTS.TO_MANAGE_REGISTARATIONS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.PERFOMANCE_OF_CONTRACT}:</strong> {STRING_CONSTANTS.PERFOMANCE_OF_CONTRACT_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.TO_CONTACT_US}:</strong> {STRING_CONSTANTS.TO_CONTACT_US_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.TO_PROVIDE_YOU}</strong> {STRING_CONSTANTS.TO_PROVIDE_YOU_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.TO_MANAGE_YOUR_REQUESTS}:</strong>{STRING_CONSTANTS.TO_MANAGE_YOUR_REQUESTS_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.FOR_BUSINESS_TRANSFERS}:</strong> {STRING_CONSTANTS.FOR_BUSINESS_TRANSFERS_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.FOR_OTHER_PURPOSE}</strong>: {STRING_CONSTANTS.FOR_OTHER_PURPOSE_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            {STRING_CONSTANTS.PERSONAL_INFORMATION_SHARE}:
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.WITH_SERVICE_PORVIDERS}:</strong> {STRING_CONSTANTS.WITH_SERVICE_PORVIDERS_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.FOR_BUSINESS_TRANSFERS}:</strong> {STRING_CONSTANTS.FOR_BUSINESS_TRANSFERS_DETAILS_2}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.WITH_AFFILIATES}:</strong> {STRING_CONSTANTS.WITH_AFFILIATES_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.WITH_BUSINESS_PARTNERS}:</strong> {STRING_CONSTANTS.WITH_BUSINESS_PARTNERSD_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.WITH_OTHER_USERS}:</strong> {STRING_CONSTANTS.WITH_OTHER_USERS_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.WITH_YOUR_CONCENT}</strong>:{STRING_CONSTANTS.WITH_YOUR_CONCENT_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
           {STRING_CONSTANTS.RETENTION_OF_YOUR_DATA}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
         {STRING_CONSTANTS.RETENTION_OF_YOUR_DATA_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
         {STRING_CONSTANTS.RETENTION_OF_YOUR_DATA_DETAILS_2}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
           {STRING_CONSTANTS.TRANSFER_YOUR_PERSONAL_DATA}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
       {STRING_CONSTANTS.TRANSFER_YOUR_PERSONAL_DATA_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >{STRING_CONSTANTS.TRANSFER_YOUR_PERSONAL_DATA_DETAILS_2}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
       {STRING_CONSTANTS.TRANSFER_YOUR_PERSONAL_DATA_DETAILS_3}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.DELETE_YOUR_PERSONAL_DATA}</strong>
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
          {STRING_CONSTANTS.DELETE_YOUR_PERSONAL_DATA}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
    {STRING_CONSTANTS.DELETE_YOUR_PERSONAL_DATA_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
         {STRING_CONSTANTS.DELETE_YOUR_PERSONAL_DATA_DETAILS_2}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
    {STRING_CONSTANTS.DELETE_YOUR_PERSONAL_DATA_DETAILS_2}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
        {STRING_CONSTANTS.DELETE_YOUR_PERSONAL_DATA_DETAILS_3}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            {STRING_CONSTANTS.DELETE_YOUR_PERSONAL_DATA_DETAILS_4}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.SECURITY_OF_YOUR_PERSONAL_DATA}</strong>
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
           {STRING_CONSTANTS.SECURITY_OF_YOUR_PERSONAL_DATA_DETAILS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.CHILDRENS_PRIVACY}</strong>
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
         {STRING_CONSTANTS.CHILDRENS_PRIVACY_DETAIS}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.LINK_OR_WEBSITE}</strong>
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
      {STRING_CONSTANTS.LINK_OR_WEBSIT_DETAILS}.
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
         {STRING_CONSTANTS.LINK_OR_WEBSIT_DETAILS_2}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.CHANGES_TO_THIS_PRIVACY}</strong>
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
          {STRING_CONSTANTS.CHANGES_TO_THIS_PRIVACY_2}
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            <strong>{STRING_CONSTANTS.CONTACT_US}</strong>
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
           {STRING_CONSTANTS.CONTACT_US_DETAILS}:
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
           {STRING_CONSTANTS.BY_EMAIL}: jackfruitworld@gmail.com
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
            {STRING_CONSTANTS.BY_VISITING_THIS_PAGE}:{" "}
            <a href="https://www.radtar.com/">https://www.radtar.com/</a>
          </p>

          <p
            style={{
              margin: "0px 0px 5px",
              textAlign: "justify",
              fontStretch: "normal",
              fontSize: "12px",
              lineHeight: "normal",
              fontFamily: '"Times New Roman"',
            }}
          >
           {STRING_CONSTANTS.MOBILE_NO}: 9188009623
          </p>
          <br />
        </div>
      </div>
    );
  }
}
