import React, { useEffect, useState } from "react";
import { privateApiCall } from "../../../api/privateApi";
import { STRING_CONSTANTS } from "../../../constants/stringConstants";
import {formatDate} from "../../../utils/dateUtils"

const Buyproduct = ({ onSelectProduct }) => {
  
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null);

  const fetchProducts = async () => {
    try {
      const requestUrl = "/api/organisation/listBuyproducts";
      const response = await privateApiCall(requestUrl, "GET");
      const fetchedProducts = response.data.data;
      setProducts(fetchedProducts);

      if (fetchedProducts.length > 0) {
        const firstProduct = fetchedProducts[0];
        setSelectedProductId(firstProduct.id);
        onSelectProduct(firstProduct);
      } else {
        onSelectProduct(null); // No products found
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const searchProducts = async (term) => {
    try {
      const requestUrl = `/api/organisation/searchBuyproducts?query=${term}`;
      const response = await privateApiCall(requestUrl, "GET");
      const searchedProducts = response.data.data;
      setProducts(searchedProducts);

      if (searchedProducts.length > 0) {
        const firstProduct = searchedProducts[0];
        setSelectedProductId(firstProduct.id);
        onSelectProduct(firstProduct);
      } else {
        onSelectProduct(null); // No products found
      }
    } catch (error) {
      console.error("Error searching products:", error);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      searchProducts(searchTerm);
    } else {
      fetchProducts();
    }
  }, [searchTerm]);

  const handleProductSelect = (product) => {
    setSelectedProductId(product.id);
    onSelectProduct(product);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        backgroundColor: "#f9f9f9",
        height: "100vh",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          padding: "10px",
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
                            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgCustomerSupport" && item.isNavigate
              )) && (
              
          <>
          
          <input
          type="text"
          placeholder="Name, Product, City, Company or Mobile"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            flex: 1,
            padding: "5px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            marginRight: "10px",
          }}
        />
        <button
          style={{
            padding: "5px",
            backgroundColor: "#0baae5",
            color: "white",
            border: "none",
            borderRadius: "5px",
          }}
        >
          {STRING_CONSTANTS.SEARCH}
        </button>
          </>
            )}
      
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {products.length > 0 ? (
          products.map((product) => (
            <div
              key={product.id}
              onClick={() => handleProductSelect(product)}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                backgroundColor:
                  selectedProductId === product.id ? "#e0f7fa" : "white",
                borderRadius: "5px",
                border:
                  selectedProductId === product.id
                    ? "2px solid #0baae5"
                    : "1px solid #ddd",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "5px",
                }}
              >
                <div>
                  <p style={{ margin: 0, fontWeight: "bold" }}>
                    <span>{product.customer}</span>
                     
                  </p>
                </div>
                <p style={{ color: "gray", margin: "2px 0",fontWeight: "bold" }}>
                {product.mobile_no}
                </p>
              </div>
              <p style={{ color: "gray", margin: "2px 0" }}>
                <strong><i class="fa fa-map-marker" aria-hidden="true" style={{ color: "red", marginRight: '5px' }}></i> {product.location.replace(/^\S+\s+/, '')}</strong>
              </p>
              <p style={{ color: "gray", margin: "2px 0" }}>
                <strong>{product.title}</strong>
              </p>
              <p style={{ color: "gray", margin: "2px 0" }}>
                
                {formatDate(product.startDate)}
              </p>
            </div>
          ))
        ) : (
          <p>{STRING_CONSTANTS.NO_DATA_FOUND}</p>
        )}
      </div>
    </div>
  );
};

export default Buyproduct;
