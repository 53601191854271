import axios from "axios";
import Cookies from "universal-cookie";
import { API as baseUrl } from "../config";
import { toast, Bounce } from 'react-toastify';

const cookies = new Cookies();
let isToastShown = false; 

export const privateApiCall = (url, method, data = null) => {
  return new Promise((resolve, reject) => {
    const jwtToken = cookies.get('jwtToken');
    const requestUrl = `${baseUrl}${url}`;

    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };

    axios({
      method,
      url: requestUrl,
      data: data,
      ...config,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          if (!isToastShown) {
            isToastShown = true;
            toast.warning('Your Session has been expired!', {
              transition: Bounce,
              onClose: () => {
                isToastShown = false;
              }
            });
          }
          window.location.href = '/';
        } else {
          reject(err);
        }
      });
  });
};
