import { Avatar, Typography, Stack, Box, Divider } from "@mui/material";
import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Contact = ({ selectedChat, selectedProduct }) => {
  if (!selectedChat) {
    return null;
  }

  console.log(selectedChat, "data productss", selectedProduct);

  const { name, email, phone, image } = selectedChat;
  const { productImage, productLocation, productName } = selectedProduct;

  return (
    <Box
      sx={{
        padding: 2,
        border: "1px solid #0baae5",
        borderRadius: 2,
        backgroundColor: "#F8FAFF",
        maxWidth: 350,
        margin: "0 auto",
      }}
    >
      {/* User Details */}
      <Stack spacing={2} alignItems="center">
        <Avatar
          src={image || "/static/images/default-avatar.jpg"}
          sx={{ width: 100, height: 100, border: "3px solid #0baae5" }}
        />
        <Typography variant="h6">{name || "Unknown User"}</Typography>
        <Typography
          variant="body2"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <MailOutlineIcon sx={{ marginRight: 1 }} />{" "}
          {email || "No email provided"}
        </Typography>
        <Typography
          variant="body2"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <LocalPhoneIcon sx={{ marginRight: 1 }} />{" "}
          {phone || "No phone number"}
        </Typography>
      </Stack>

      {/* Divider */}
      <Divider sx={{ my: 3, borderColor: "#0baae5" }} />
      {/* Product Details */}
      <Stack spacing={2} alignItems="center">
        <Box
          component="img"
          src={productImage || "/static/images/default-product.jpg"}
          alt={productName || "Product"}
          sx={{ width: 150, objectFit: "cover", borderRadius: 1 }}
        />
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {productName || "Unknown Product"}
        </Typography>
        <Typography
          variant="body2"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <LocationOnIcon sx={{ marginRight: 1 }} />{" "}
          {productLocation || "No location provided"}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Contact;
