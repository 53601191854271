import React, { useState, useEffect } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tabs,
  Tab,
  Modal,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  TextField,
  Fade,
  Button,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "react-tooltip";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import Cookies from "universal-cookie";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link } from "react-router-dom";
import { SUBSCRIPTION_LIST } from "../../constants/url";
import { EDIT, DELETE } from "../../constants/url";
import { privateApiCall } from "../../api/privateApi";
import {
  ALERT_BUTTON_OK,
  CONFIRM_TITLE,
  CONFIRM_TXT_DEL,
  CONFIRM_TXT_DEL_COIN,
  ERR_ALERT_TITLE,
  ERR_POSTING,
  ERR_SUB_DEL,
  SUCC_ALERT_TITLE,
  SUCC_SUB_DEL,
  SUCC_SUB_EDT_TEXT,
  SUCC_SUB_TEXT,
} from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
const cookies = new Cookies();

const Free = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";

  const [values, setValues] = useState({
    users: [],
    organizations: [],
  });

  const [currentTab, setCurrentTab] = useState("User");
  const { users, organizations } = values;
  const token = cookies.get("agritech_token");

  const [loading, setLoading] = useState(false); // Loading state
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    offerprice:"",
    validity: "",
    type: "",
    coins: "",
    customer_limit: "",
  });

  const [editModalData, setEditModalData] = useState(null);

  useEffect(() => {
    loadFreeUsers();
  }, []);

  const loadFreeUsers = () => {
    const requestUrl = `${SUBSCRIPTION_LIST}?token=${cookies.get(
      "agritech_token"
    )}`;
    privateApiCall(requestUrl, "GET")
      .then((response) => {
        const filteredUsers = response.data.filter(
          (user) => user.type === `${STRING_CONSTANTS.USER}`
        );
        const filteredOrganizations = response.data.filter(
          (user) => user.type === `${STRING_CONSTANTS.ORG}`
        );
        setValues({
          users: filteredUsers,
          organizations: filteredOrganizations,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`
        });
      });
  };

  const loadOrganizations = () => {
    const filteredOrganizations = users.filter(
      (user) => user.type === `${STRING_CONSTANTS.ORG}`
    );
    setValues({ ...values, organizations: filteredOrganizations });
  };

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    if (tab === `${STRING_CONSTANTS.ORG}` && organizations.length === 0) {
      loadOrganizations();
    }
  };

  const handleEdit = (row) => {
    setEditModalData(row);
    setFormData(row);
    setShowModal(true);
  };

  const handleDelete = (row) => {
    Swal.fire({
      title: `${CONFIRM_TITLE}`,
      text: `${CONFIRM_TXT_DEL}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "##3085d6 ",
      confirmButtonText: `${CONFIRM_TXT_DEL_COIN}`,
    }).then((result) => {
      if (result.isConfirmed) {
        const requestUrl = `/deleteSubscription/${row._id}?token=${token}`;
        privateApiCall(requestUrl, "delete", {})
          .then(() => {
            const updatedUsers = users.filter((user) => user._id !== row._id);
            const updatedOrganizations = organizations.filter(
              (org) => org._id !== row._id
            );
            setValues({
              ...values,
              users: updatedUsers,
              organizations: updatedOrganizations,
            });
            Swal.fire(`${SUCC_SUB_DEL}`);
          })
          .catch((err) => {
            console.error(err);
            Swal.fire(`${ERR_SUB_DEL}`);
          });
      }
    });
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setEditModalData(null);
    setFormData({
      name: "",
      price: "",
      validity: "",
      type: "",
      coins: "",
      customer_limit: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true); // Set loading to true when form is submitted
    const requestUrl = `/createSubscription`;
    privateApiCall(requestUrl, "POST", formData)
      .then((res) => {
        if (res.status === 200) {
          handleCloseModal(); // Close the modal immediately after successful submission
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            text: `${SUCC_SUB_TEXT}`,
            icon: "success",
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          }).then(() => {
            loadFreeUsers();
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_POSTING}`,
          icon: "error",
          confirmButtonText: `${ALERT_BUTTON_OK}`
        });
      })
      .finally(() => {
        setLoading(false); // Reset loading state
      });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();

    setLoading(true); // Set loading to true when form is submitted
    const { _id } = editModalData;

    const requestUrl = `/updateSubscription/${_id}?token=${token}`;
    privateApiCall(requestUrl, "POST", formData)
      .then((res) => {
        if (res.status === 200) {
          handleCloseModal(); // Close the modal immediately after successful submission

          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            text: `${SUCC_SUB_EDT_TEXT}`,
            icon: "success",
            confirmButtonText: `${ALERT_BUTTON_OK}`
          }).then(() => {
            handleCloseModal();
            loadFreeUsers();
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_POSTING}`,
          icon: "error",
          confirmButtonText: `${ALERT_BUTTON_OK}`
        });
      })
      .finally(() => {
        setLoading(false); // Reset loading state
      });
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="subscription" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">{STRING_CONSTANTS.MANAGE}</h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                    </li>
                    <li className="breadcrumb-item active">{STRING_CONSTANTS.MANAGE}</li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div className="row mb-2">
                    <div style={{ width: "98%" }}>
                      {permissions.some(
                        (item) =>
                          item.keyword === "subscriptionManage" &&
                          item.isCreate === true
                      ) && (
                          <button
                            className="btn btn-primary ml-2 mb-2"
                            style={{
                              float: "left",
                              backgroundColor: "#769A0F",
                              color: "#fff",
                            }}
                            onClick={handleShowModal}
                          >
                            <AddIcon style={{ marginRight: "5px" }} />
                            {STRING_CONSTANTS.ADD_SUBSCRIPTION}
                          </button>
                        )}
                    </div>
                    <Modal
                      open={showModal}
                      onClose={handleCloseModal}
                      closeAfterTransition
                      BackdropProps={{
                        style: {
                          backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the last value (0.5) to change the opacity
                        },
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      disableBackdropClick
                    >
                      <Fade in={showModal}>
                        <Box
                          sx={{
                            bgcolor: "background.paper",
                            boxShadow: 1,
                            p: 4,
                            borderRadius: 2,
                            width: "60%",
                            marginBottom: "20px",
                          }}
                        >
                          <h2>
                            {editModalData
                              ? `${STRING_CONSTANTS.EDIT_SUBSCRIPTION}`
                              : `${STRING_CONSTANTS.ADD_SUBSCRIPTION}`}
                          </h2>
                          <form
                            onSubmit={
                              editModalData ? handleEditSubmit : handleSubmit
                            }
                          >
                            <Grid
                              container
                              spacing={2}
                              style={{ marginTop: "0px" }}
                            >
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Name"
                                  variant="outlined"
                                  name="name"
                                  value={formData.name}
                                  onChange={handleInputChange}
                                  placeholder={STRING_CONSTANTS.ENTER_SUB_NAME}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Validity"
                                  variant="outlined"
                                  name="validity"
                                  value={formData.validity}
                                  onChange={handleInputChange}
                                  placeholder={STRING_CONSTANTS.VALIDITY}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Coins"
                                  variant="outlined"
                                  name="coins"
                                  value={formData.coins}
                                  onChange={handleInputChange}
                                  placeholder={STRING_CONSTANTS.ENTER_COINS}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Price"
                                  variant="outlined"
                                  name="price"
                                  value={formData.price}
                                  onChange={handleInputChange}
                                  placeholder={STRING_CONSTANTS.ENTER_PRICE}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="OfferPrice"
                                  variant="outlined"
                                  name="offerprice"
                                  value={formData.offerprice}
                                  onChange={handleInputChange}
                                  placeholder={STRING_CONSTANTS.ENTER_OFFPRICE}
                                  required
                                />
                              </Grid>
                            

                              <Grid item xs={12}>
                                <FormControl
                                  fullWidth
                                  variant="outlined"
                                  required
                                >
                                  <InputLabel>{STRING_CONSTANTS.TYPE}</InputLabel>
                                  <Select
                                    label={STRING_CONSTANTS.TYPE}
                                    name="type"
                                    value={formData.type}
                                    onChange={handleInputChange}
                                  >
                                    <MenuItem value="">
                                      <em>{STRING_CONSTANTS.SELECT_TYPE}</em>
                                    </MenuItem>
                                    <MenuItem value="User">{STRING_CONSTANTS.USER}</MenuItem>
                                    <MenuItem value="Organisation">
                                      {STRING_CONSTANTS.ORG}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              {formData.type === 'Organisation' && (
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    label={STRING_CONSTANTS.CUSTOMER_LIMIT}
                                    variant="outlined"
                                    name="customer_limit"
                                    value={formData.customer_limit}
                                    onChange={handleInputChange}
                                    placeholder={`Customer limit`}
                                    required
                                  />
                                </Grid>
                              )}
                            </Grid>
                            <Grid
                              container
                              justifyContent="flex-end"
                              style={{ marginTop: "40px", marginBottom: "0px" }}
                            >
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModal}
                                style={{ marginRight: "10px" }}
                              >
                                {STRING_CONSTANTS.CLOSE}
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                style={{ backgroundColor: "#769A0F" }}
                              >
                                {editModalData ? `${STRING_CONSTANTS.UPDATE}` : `${STRING_CONSTANTS.SUBMIT}`}
                              </Button>
                            </Grid>
                          </form>
                        </Box>
                      </Fade>
                    </Modal>
                    <div className="col-md-12">
                      <div
                        style={{
                          backgroundColor: "#CCCCCC",
                          borderRadius: "4px",
                        }}
                      >
                        <Tabs
                          value={currentTab}
                          onChange={(event, newValue) =>
                            handleTabChange(newValue)
                          }
                          variant="fullWidth"
                          indicatorColor="transparent"
                          textColor="primary"
                          aria-label="tabs"
                        >
                          <Tab
                            label="User"
                            value="User"
                            sx={{
                              backgroundColor:
                                currentTab === `${STRING_CONSTANTS.USER}` ? "#CCCCCC" : "#F5F5F5",
                              color:
                                currentTab === `${STRING_CONSTANTS.USER}` ? "#ffffff" : "#000000",
                              fontWeight:
                                currentTab === `${STRING_CONSTANTS.USER}` ? "bold" : "bold",
                              "&:hover": {
                                backgroundColor:
                                  currentTab === `${STRING_CONSTANTS.USER}` ? "#CCCCCC" : "#F5F5F5",
                                color:
                                  currentTab === `${STRING_CONSTANTS.USER}` ? "#ffffff" : "#000000",
                              },
                              "&.Mui-selected": {
                                color: "#000000",
                              },
                            }}
                          />
                          <div
                            style={{ width: "2px", backgroundColor: "#F5F5F5" }}
                          />

                          <Tab
                            label="Organisation"
                            value="Organisation"
                            sx={{
                              backgroundColor:
                                currentTab === `${STRING_CONSTANTS.ORG}`
                                  ? "#CCCCCC"
                                  : "#F5F5F5",
                              color:
                                currentTab === `${STRING_CONSTANTS.ORG}`
                                  ? "#ffffff"
                                  : "#000000",
                              fontWeight:
                                currentTab === `${STRING_CONSTANTS.ORG}` ? "bold" : "bold",
                              "&:hover": {
                                backgroundColor:
                                  currentTab === `${STRING_CONSTANTS.ORG}`
                                    ? "#CCCCCC"
                                    : "#F5F5F5",
                                color:
                                  currentTab === `${STRING_CONSTANTS.ORG}`
                                    ? "#ffffff"
                                    : "#000000",
                              },
                              "&.Mui-selected": {
                                color: "#000000",
                              },
                            }}
                          />
                        </Tabs>
                      </div>

                      {currentTab === `${STRING_CONSTANTS.USER}` && (
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell width="80px" align="center">
                                  {STRING_CONSTANTS.SN_NO}
                                </TableCell>
                                <TableCell width="100px" align="center">
                                  {STRING_CONSTANTS.NAME}
                                </TableCell>
                                <TableCell width="160px" align="center">
                                  {STRING_CONSTANTS.VALIDITY}
                                </TableCell>
                                <TableCell width="160px" align="center">
                                  {STRING_CONSTANTS.COINS}
                                </TableCell>
                               

                                <TableCell width="160px" align="center">
                                  {STRING_CONSTANTS.PRICE}
                                </TableCell>
                                <TableCell width="160px" align="center">
                                  {STRING_CONSTANTS.ACTIONS}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {users.map((row, index) => (
                                <TableRow key={row._id}>
                                  <TableCell align="center">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.name}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.validity}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.coins}
                                  </TableCell>
                                  
                                  <TableCell align="center">
                                    {row.price}
                                  </TableCell>
                                  <TableCell align="center">
                                    {permissions.some(
                                      (item) =>
                                        item.keyword === "subscriptionManage" &&
                                        item.isEdit === true
                                    ) && (
                                        <IconButton
                                          onClick={() => handleEdit(row)}
                                        >
                                          <FiEdit2
                                            size="18px"
                                            style={{ color: "#0978F2" }}
                                            data-tooltip-id="1"
                                            data-tooltip-content={`${EDIT}`}
                                          />
                                        </IconButton>
                                      )}

                                    {permissions.some(
                                      (item) =>
                                        item.keyword === "subscriptionManage" &&
                                        item.isDelete === true
                                    ) && (
                                        <IconButton
                                          onClick={() => handleDelete(row)}
                                        >
                                          <FiTrash2
                                            size="18px"
                                            style={{ color: "red" }}
                                            data-tooltip-id="2"
                                            data-tooltip-content={`${DELETE}`}
                                          />
                                        </IconButton>
                                      )}
                                    <Tooltip
                                      id="1"
                                      style={{ fontSize: "12px" }}
                                    />
                                    <Tooltip
                                      id="2"
                                      size="10px"
                                      style={{ fontSize: "12px" }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                      {currentTab === `${STRING_CONSTANTS.ORG}` && (
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell width="80px" align="center">
                                  {STRING_CONSTANTS.SN_NO}
                                </TableCell>
                                <TableCell width="100px" align="center">
                                  {STRING_CONSTANTS.NAME}
                                </TableCell>
                                <TableCell width="160px" align="center">
                                  {STRING_CONSTANTS.VALIDITY}
                                </TableCell>
                                <TableCell width="160px" align="center">
                                  {STRING_CONSTANTS.COINS}
                                </TableCell>
                                 <TableCell width="160px" align="center">
                                  {STRING_CONSTANTS.OFF_PRICE}
                                </TableCell>
                                <TableCell width="160px" align="center">
                                  {STRING_CONSTANTS.PRICE}
                                </TableCell>
                                <TableCell width="160px" align="center">
                                {STRING_CONSTANTS.CUSTOMER_LIMIT}
                                </TableCell>
                                <TableCell width="160px" align="center">
                                  {STRING_CONSTANTS.ACTIONS}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {organizations.map((row, index) => (
                                <TableRow key={row._id}>
                                  <TableCell align="center">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.name}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.validity}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.coins}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.offerprice}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.price}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.customer_limit}
                                  </TableCell>
                                  <TableCell align="center">
                                    <IconButton onClick={() => handleEdit(row)}>
                                      <FiEdit2
                                        size="18px"
                                        style={{ color: "#0978F2" }}
                                        data-tooltip-id="1"
                                        data-tooltip-content={`${EDIT}`}
                                      />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => handleDelete(row)}
                                    >
                                      <FiTrash2
                                        size="18px"
                                        style={{ color: "red" }}
                                        data-tooltip-id="2"
                                        data-tooltip-content={`${DELETE}`}
                                      />
                                    </IconButton>
                                    <Tooltip
                                      id="1"
                                      style={{ fontSize: "12px" }}
                                    />
                                    <Tooltip
                                      id="2"
                                      size="10px"
                                      style={{ fontSize: "12px" }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Free;
