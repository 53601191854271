import React, { useState, useEffect } from "react";
import "react-tagsinput/react-tagsinput.css";
import { API as url1 } from "../../config";
import { BUY_PRODUCT_DETAILS, SELL_PRODUCT_DETAILS } from "../../constants/url";

import { STRING_CONSTANTS } from "../../constants/stringConstants";
import { privateApiCall } from "../../api/privateApi";
const ViewProduct = (props) => {
  const [values, setValues] = useState({
    name: "",
    error: "",
    image1: props.data.product[0].image1,
    image2: "",
    image3: "",
    image4: "",
    images: [],
    title: props.data.product[0].title,
    desc: props.data.product[0].description,
    success: false,
    removed: false,
  });
  const {
    images,
    title,

    desc,
  } = values;
  useEffect(() => {
    var images = [];
    if (props.data.product[0].image1 !== "") {
      images.push({ id: 1, image: props.data.product[0].image1, status: true });
    }

    if (props.data.product[0].image2 !== "") {
      images.push({
        id: 2,
        image: props.data.product[0].image2,
        status: false,
      });
    }
    if (props.data.product[0].image3 !== "") {
      images.push({
        id: 3,
        image: props.data.product[0].image3,
        status: false,
      });
    }
    if (props.data.product[0].image4 !== "") {
      images.push({
        id: 4,
        image: props.data.product[0].image4,
        status: false,
      });
    }

    setValues({ ...values, images: images });
  }, []);
  const openLink = (e) => {
    window
      .open(
        "https://play.google.com/store/apps/details?id=com.jackfruitworld&hl=en-IN",
        "_blank"
      )
      .focus();
  };
  const imageSelected = (id) => (e) => {
    let items = images;
    for (let i = 0; i < items.length; i++) {
      items[i].status = false;
    }

    items[id].status = true;

    setValues({ ...values, images: items });
  };

  const dataTable = () => {
    return (
      <div id="wrapper" style={{ background: "white" }}>
        <head>
          <title>{props.data.product[0].title}</title>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <meta name="title" content={props.data.product[0].title} />
          <meta
            name="description"
            content={props.data.product[0].description}
          />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={props.data.product[0].sharelink}
            key="ogurl"
          />
          <meta
            property="og:title"
            content={props.data.product[0].title}
            key="ogtitle"
          />
          <meta
            property="og:description"
            content={props.data.product[0].description}
            key="ogdesc"
          />
          <meta
            property="og:image"
            content={props.data.product[0].image1}
            key="ogimage"
          />
          <meta property="og:image:width" content="200" />
          <meta property="og:image:height" content="200" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={props.data.product[0].sharelink}
          />
          <meta
            property="twitter:title"
            content={props.data.product[0].title}
          />
          <meta
            property="twitter:description"
            content={props.data.product[0].description}
          />
          <meta
            property="twitter:image"
            content={props.data.product[0].image1}
          />
        </head>
        <div className="col-12">
          <div className="row">
            <div className="top-header">
              <img
                src="/assets/images/jack_logo.png"
                alt=""
                style={{ width: "60px", height: "60px" }}
              />

              <div className="text-primary ml-1 app-header">
                {STRING_CONSTANTS.JACKFRUITWORLD}
              </div>
              <div>
                <button
                  onClick={openLink}
                  className="button-download text-white"
                >
                  {STRING_CONSTANTS.DOWNLOAD_NOW}
                </button>
              </div>
            </div>

            <div style={{ margin: "50px" }}>
              <div
                className="row justify-content-around"
                style={{ marginTop: "0px" }}
              >
                <div className="col-md-5">
                  {images.map((flogo, index) => {
                    if (flogo.status) {
                      return (
                        <img
                          className=" rounded img-responsive"
                          src={flogo.image}
                          alt="user"
                          style={{ height: "300px", width: "100%" }}
                        />
                      );
                    }
                  })}
                  {images.length > 1 && (
                    <div className="mt-3 row  justify-content-center">
                      {images.map((flogo, index) => {
                        return (
                          <img
                            className="border-image-selected img-responsive ml-2 mr-2"
                            src={flogo.image}
                            onClick={imageSelected(index)}
                            alt="user"
                            style={{ height: "50px", width: "50px" }}
                          />
                        );
                      })}
                    </div>
                  )}
                  <div
                    className="col-12 mt-3 text-dark font-18 strong"
                    style={{ textDecoration: "underline" }}
                  >
                    {" "}
                    {STRING_CONSTANTS.POSTED_BY}
                  </div>
                  <div className="col-12 mt-2">
                    <div
                      className="card "
                      style={{
                        boxShadow: "0px 6px 11px rgba(65, 59, 137, 0.1)",
                      }}
                    >
                      <div className="card-body">
                        <div className="row justify-content-center ml-1 mr-1">
                          <img
                            src={
                              props.data.product[0].userImage === ""
                                ? "/assets/images/useers.png"
                                : props.data.product[0].userImage
                            }
                            alt=""
                            className="rounded-circle avatar-xl"
                          />

                          <div className="ml- ">
                            <h4 className="text-dark font-18   mt-2 ">
                              {props.data.product[0].userName}
                            </h4>
                            <h4 className=" font-16   ">
                              {props.data.product[0].userEmail}
                            </h4>
                            <h4 className=" font-16">
                              {props.data.product[0].mobile_no}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 align-items-start">
                  <h1 className="text-dark">{title}</h1>
                  <h5 className="text-dark">{desc}</h5>

                  <h1 className="text-dark mt-3 strong">
                    &#8377;
                    {Intl.NumberFormat("en-IN").format(
                      props.data.product[0].price
                    )}
                  </h1>

                  <div className="row  mt-3">
                    <div
                      className="col-12 text-dark font-18 strong"
                      style={{ textDecoration: "underline" }}
                    >
                      {" "}
                      {STRING_CONSTANTS.DETAILS}
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row justify-content-between ml-1 mr-3">
                        <div className="text-dark font-18 font-weight-semibold">
                          {" "}
                          {STRING_CONSTANTS.UNIT}
                        </div>

                        <div className="text-dark font-18">
                          {" "}
                          {props.data.product[0].unit}
                        </div>
                      </div>
                      <div className="row mt-1 justify-content-between ml-1 mr-3">
                        <div className="text-dark font-18 font-weight-semibold">
                          {" "}
                          {STRING_CONSTANTS.QUANTITY}
                        </div>

                        <div className="text-dark font-18">
                          {" "}
                          {props.data.product[0].qty}
                        </div>
                      </div>
                      <div className="row mt-1 justify-content-between ml-1 mr-3">
                        <div className="text-dark font-18 font-weight-semibold">
                          {" "}
                          {STRING_CONSTANTS.END_DATE}
                        </div>

                        <div className="text-dark font-18">
                          {" "}
                          {props.data.product[0].expDate}
                        </div>
                      </div>

                      <div className="row mt-1 justify-content-start">
                        {props.data.product[0].safe_status === "1" &&
                          props.data.product[0].organic_status === "1" && (
                            <img
                              className="img-responsive mr-1"
                              src={"/assets/icons/organi_1.jpg"}
                              alt="user"
                              style={{ width: "50px", height: "50px" }}
                            />
                          )}

                        {props.data.product[0].safe_status === "1" &&
                          props.data.product[0].organic_status === "0" && (
                            <img
                              className="img-responsive mr-1 ml-1"
                              src={"/assets/icons/safe_icon.jpg"}
                              alt="user"
                              style={{ width: "50px", height: "50px" }}
                            />
                          )}

                        {props.data.product[0].transpot_status === "1" && (
                          <img
                            className="img-responsive ml-1"
                            src={"/assets/icons/trucks.png"}
                            alt="user"
                            style={{ width: "50px", height: "50px" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <React.Fragment>{dataTable()}</React.Fragment>;
};

ViewProduct.getInitialProps = async (ctx) => {
  const pid = ctx.query._id;
  const type = ctx.query.type;

  let apiUrl = "";

  if (type === "buy") {
    apiUrl = `${url1}${BUY_PRODUCT_DETAILS}/?product_id=${pid}`;
  } else if (type === "sell") {
    apiUrl = `${url1}${SELL_PRODUCT_DETAILS}/?product_id=${pid}`;
  } else {
    return { data: null };
  }

  const res = await privateApiCall(apiUrl, "GET");

  return { data: res };
};

export default ViewProduct;
