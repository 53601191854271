import React, { useState, useEffect } from "react";
import QRCode from 'qrcode.react';
import Cookies from "universal-cookie";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { MFAVERIFY_PATH } from "../../constants/url";
import { useNavigate } from "react-router-dom";
import { privateApiCall } from "../../api/privateApi";
import { ENTER_OTP, FAILD_OTP, MFA_QR_SCAN, VERIFY_OTP } from "../../constants/alertMessage";

const MFACheck = () => {
  const cookies = new Cookies();

  const MFASecret = cookies.get("MFASecret");
  const navigate = useNavigate();
  const [mfasecret, setMfasecret] = useState(MFASecret);
  const [loginType, setLoginType] = useState(cookies.get("login_type"));
  const [initialValues, setInitialValues] = useState({ email: "" });

  useEffect(() => {
    const MFASecret = cookies.get("MFASecret");
    const email = cookies.get("email");
    setInitialValues({ email: email });
    setMfasecret(MFASecret)
  }, []);

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required("OTP is required"),
  });

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    let data = {
      email: initialValues.email,
      otp: values.otp,
      MFABase32: mfasecret.base32
    };

    privateApiCall(`${MFAVERIFY_PATH}`,"POST",data).then((res) => {
      if (res.status === 200) {
        if (loginType === "admin") {
          navigate("/dashboard");
        }
        else if (loginType === "organisation") {
          navigate("/organisation/dashboard");
        }
      } else {
        setFieldError("otp",FAILD_OTP );
      }
    }).catch((err) => {
      
      setFieldError("otp", FAILD_OTP);
   
    })
    .finally(() => {
      setSubmitting(false);
    });
  };

  return (


    <div className="container">
      <div className="row justify-content-center ">
        <div className="card bg-pattern" >
          <div className="card-body mx-auto">
            <div className="text-center">
              <span>
                <img src="assets/images/jack_logo.png" alt="" />
              </span>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="text-center">
                    <h6>{MFA_QR_SCAN}</h6>
                    <QRCode value={mfasecret.otpauth_url} />
                  </div>
                  <div className="form-group">
               
                    <Field
                      className="form-control"
                      type="otp"
                      id="otp"
                      name="otp"
                      placeholder={ENTER_OTP}
                    />
                    <ErrorMessage
                      name="otp"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group row align-items-center">
                    <div className="col-12">
                      <button
                        className="btn btn-block btn-primary waves-effect waves-light"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {VERIFY_OTP}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MFACheck;