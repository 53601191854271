import React, { useState, useEffect, useCallback } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import QRCode from "qrcode.react";
import Cookies from "universal-cookie";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { MFAVERIFY_PATH, UPDATE_MFA } from "../../constants/url";
import { ALERT_BUTTON_OK, ALERT_ICON_ERR, MFA_FAILD_MESSAGE, MFA_SUCCESS, OTP_FAILD_MESSAGE} from "../../constants/alertMessage";

import {
  ERR_GETTING,
} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { toast, Bounce } from "react-toastify";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

const MFASettings = () => {
  const [mfasecret, setMfasecret] = useState(cookies.get("MFASecret"));
  const [loginType, setLoginType] = useState(cookies.get("login_type"));
  const [initialValuesMFA, setInitialValuesMFA] = useState({
    email: cookies.get("email"),
  });
  const [isEnabled2FA, setIsEnabled2FA] = useState(false);
  const [isDisabled2FA, setIsDisabled2FA] = useState(false);
  const [initial2FA, setinitial2FA] = useState(cookies.get("isEnabled2FA"));

  useEffect(() => {}, []);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    const mfaSetting =
      initial2FA && !isDisabled2FA && !isEnabled2FA
        ? true
        : !initial2FA && !isDisabled2FA && !isEnabled2FA
        ? false
        : isDisabled2FA
        ? false
        : isEnabled2FA
        ? true
        : false;
    let data = {
      email: initialValuesMFA.email,
      userType: loginType,
      isEnabled2FA: mfaSetting,
    };
    const requestUrl = `${UPDATE_MFA}`;
    privateApiCall(requestUrl, "POST", data)
      .then((res) => {
        if (res.status === 200) {
          cookies.remove("isEnabled2FA", { path: "/" });
          cookies.set("isEnabled2FA", mfaSetting);
          toast.success({MFA_SUCCESS}, {
            transition: Bounce,
            onClose: () => (window.location.href = "/dashboard"),
          });
        } else {
          Swal.fire({
            title: `${ERR_GETTING}`,
            icon: `${ALERT_ICON_ERR}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_GETTING}`,
          icon: `${ALERT_ICON_ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      })
      .finally(() => {});
  });

  const validationSchemaMFA = Yup.object().shape({
    otp: Yup.string().required(`${STRING_CONSTANTS.OTP_REQUIRED}`),
  });

  const handleSubmitMFA = (values, { setSubmitting, setFieldError }) => {
    let data = {
      email: initialValuesMFA.email,
      otp: values.otp,
      MFABase32: mfasecret.base32,
      userType: loginType,
    };
    const requestUrl = `${MFAVERIFY_PATH}`;
    privateApiCall(requestUrl, "POST", data)
      .then((res) => {
        if (res.status === 200) {
          cookies.remove("isEnabled2FA", { path: "/" });
          cookies.set("isEnabled2FA", isEnabled2FA);
          setSubmitting(false);
          toast.success(`${MFA_SUCCESS}`, {
            transition: Bounce,
            onClose: () => (window.location.href = "/dashboard"),
          });
        } else {
          setFieldError(`${MFA_FAILD_MESSAGE}`);
        }
      })
      .catch((err) => {
        setFieldError(`${OTP_FAILD_MESSAGE}`);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="profiles" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">{STRING_CONSTANTS.MFA_CONFIGURATION}</h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {STRING_CONSTANTS.MFA_CONFIGURATION}
                    </li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <div className="row">
                    <div className="col-12">
                      <div className="p-10">
                        {initial2FA && (
                          <div className="mb-4">
                            <span class="badge badge-info">
                              <h5>{STRING_CONSTANTS.MFA_ENABLED}</h5>
                            </span>
                          </div>
                        )}
                        {!initial2FA && (
                          <div className="mb-4">
                            <span class="badge badge-danger">
                              <h5>
                                {STRING_CONSTANTS.MFA_NOT_ENABLED}
                              </h5>
                            </span>
                          </div>
                        )}
                        <div className="mb-4">
                          {initial2FA && (
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="checkbox-2fa"
                                name="isEnabled2FA"
                                checked={isDisabled2FA}
                                onChange={(e) => {
                                  setIsDisabled2FA(!isDisabled2FA);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkbox-2fa"
                              >
                                {STRING_CONSTANTS.DISABLE_MFA}
                              </label>
                            </div>
                          )}
                          {!initial2FA && (
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="checkbox-2fa"
                                name="isEnabled2FA"
                                checked={isEnabled2FA}
                                onChange={(e) => {
                                  setIsEnabled2FA(!isEnabled2FA);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkbox-2fa"
                              >
                               {STRING_CONSTANTS.ENABLE_MFA}
                              </label>
                            </div>
                          )}
                          <div style={{ clear: "both" }}></div>
                        </div>
                        {!initial2FA && isEnabled2FA && !isDisabled2FA && (
                          <Formik
                            initialValues={initialValuesMFA}
                            validationSchema={validationSchemaMFA}
                            onSubmit={handleSubmitMFA}
                            enableReinitialize={true}
                          >
                            {({ isSubmitting }) => (
                              <Form>
                                <div className="mb-4">
                                  <h5>
                                    {" "}
                                  {STRING_CONSTANTS.MFA_STEP_1}
                                  </h5>
                                  <span>
                                    <mark>
                                      {" "}
                                    {STRING_CONSTANTS.MFA_SKIP}
                                    </mark>
                                  </span>
                                </div>
                                <div className="mb-4">
                                  <QRCode value={mfasecret.otpauth_url} />
                                </div>
                                <div className="mb-4">
                                  <h5>
                                    {" "}
                                   {STRING_CONSTANTS.MFA_STEP_2}
                                  </h5>
                                  <Field
                                    className="form-control"
                                    style={{ width: "145px" }}
                                    type="otp"
                                    id="otp"
                                    name="otp"
                                    placeholder={STRING_CONSTANTS.MFA_PLACEHOLDER}
                                  />
                                  <ErrorMessage
                                    name="otp"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div class="mb-4 button-list">
                                  <Link to="/dashboard">
                                    <button
                                      type="button"
                                      className="btn btn-danger waves-light waves-effect width-md"
                                    >
                                      {STRING_CONSTANTS.CANCEL}
                                    </button>
                                  </Link>
                                  <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="btn btn-success waves-light waves-effect width-md"
                                  >
                                    {STRING_CONSTANTS.SAVE}
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        )}
                        {!(!initial2FA && isEnabled2FA) && (
                          <div class="mb-4 button-list">
                            <Link to="/dashboard">
                              <button
                                type="button"
                                className="btn btn-danger waves-light waves-effect width-md"
                              >
                                {STRING_CONSTANTS.CANCEL}
                              </button>
                            </Link>
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="btn btn-success waves-light waves-effect width-md"
                            >
                              {STRING_CONSTANTS.SAVE}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MFASettings;
