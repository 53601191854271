import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getIntrestedCrops } from "../../actions/adminAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Cookies from "universal-cookie";
import { EDIT_CROP, ADD_CROP } from "../../constants/url";
import { ALERT_BUTTON_OK, ERR_ALERT_TITLE } from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

const Crops = () => {
  const [values, setValues] = useState({
    name: "",
    error: "",
    header: "Add Crop",
    success: false,
    crops: [],
    message: "",
    imageName: "",
    type_id: "",
    type: "add",
    image: "",
    removed: false,
  });
  

  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const { name, type, header, crops, type_id, image } = values;
  const token = cookies.get("agritech_token");
  useEffect(() => {
    loadCrops();
  }, []);

  const loadCrops = () => {
    getIntrestedCrops(token).then((data) => {
      if (data.error) {
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: data.error,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      } else {
        setValues({
          ...values,
          crops: data.inrtrestedCrops,
          name: "",
          image: "",
          type: "add",
          type_id: "",
          header: `${STRING_CONSTANTS.ADD_CROP}`,
        });
      }
    });
  };

  const update_image = (event) => {
    setValues({
      ...values,
      error: false,
      imageName: event.target.files[0].name,
    });
    ///  console.log(event.target.files[0].name)
    if (event.target.files && event.target.files[0]) {
      // setValues({ ...values,imageName:event.target.files[0].name});
      let reader = new FileReader();
      reader.onload = (e) => {
        setValues({ ...values, image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      setValues({
        ...values,
        image: "http://placehold.it/60/c2c2c2?text=User",
      });
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };
  const dataTable = () => {
    return (
      <div id="wrapper">
        <Topbar backgroundColor="#769A0F" />
        <Sidebar type="settings" />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          {" "}
                          <Link to="/dashboard">
                            {STRING_CONSTANTS.DASHBOARD}
                          </Link>
                        </li>
                        <li className="breadcrumb-item active">
                          {STRING_CONSTANTS.INTRESTED_CROP}
                        </li>
                      </ol>
                    </div>
                    <h4 className="page-title">
                      {STRING_CONSTANTS.INTRESTED_CROP}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="card-box" style={{ paddingBottom: "50px" }}>
                    <div className="form-group form-inline">
                      <label>{header}</label>
                    </div>

                    <div>
                    {permissions.some(
                    (item) =>
                      item.keyword === "settingsIntrestedCrops" && item.isCreate === true
                  ) && (
                    <>
               
               {type === "add" && (
                      <form
                        method="post"
                        enctype="multipart/form-data"
                        action={`${ADD_CROP}/?token=${token}`}
                      >
                        <div className="form-group">
                          <img
                            className="img-responsive"
                            src={image}
                            alt="user"
                            style={{ width: "150px", height: "150px" }}
                          />
                          <br />
                          <input
                            type="file"
                            className="form-control"
                            id="image"
                            name="image"
                            onChange={update_image}
                            placeholder=""
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={name}
                            placeholder={STRING_CONSTANTS.NAME}
                            id="name"
                            name="name"
                            onChange={handleChange("name")}
                            required
                          />
                        </div>

                        <button type="submit" className="btn btn-primary">
                          {STRING_CONSTANTS.SUBMIT}
                        </button>
                      </form>
                    )}
                    </>
                  )}
                    
                    {permissions.some(
                    (item) =>
                      item.keyword === "settingsIntrestedCrops" && item.isEdit === true
                  ) && (
                    <>
                {type === "edit" && (
                      <form
                        method="post"
                        enctype="multipart/form-data"
                        action={`${EDIT_CROP}/?token=${token}&type_id=${type_id}`}
                      >
                        <div className="form-group">
                          <img
                            className="img-responsive"
                            src={image}
                            name="image"
                            alt="user"
                            style={{ width: "150px", height: "150px" }}
                          />
                          <br />
                          <input
                            type="file"
                            className="form-control"
                            id="image"
                            name="image"
                            onChange={update_image}
                            placeholder=""
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={name}
                            placeholder={STRING_CONSTANTS.NAME_PLACEHOLDER}
                            id="name"
                            name="name"
                            onChange={handleChange("name")}
                            required
                          />
                        </div>

                        <button type="submit" className="btn btn-primary">
                          {STRING_CONSTANTS.SUBMIT}
                        </button>
                      </form>
                    )}
                    </>
                  )}
                   
                    </div>
                   
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="card-box">
                    <div style={{ width: "98%" }}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell width="80px" align="center">
                                {STRING_CONSTANTS.SN_NO}
                              </TableCell>
                              <TableCell width="180px" align="center">
                                {STRING_CONSTANTS.NAME}
                              </TableCell>
                              <TableCell width="120px" align="center">
                                {STRING_CONSTANTS.ACTIONS}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {crops.map((crop, index) => (
                              <TableRow key={index}>
                                <TableCell align="center">
                                  {index + 1}
                                </TableCell>
                                <TableCell align="center">
                                  {crop.name}
                                </TableCell>
                                <TableCell align="center">
                                  {/* Add your action components here */}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <React.Fragment>{dataTable()}</React.Fragment>;
};

export default Crops;
