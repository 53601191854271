import { useState, useEffect } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import { Link } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import {
    Modal,
    Button,
    FormControl,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    TableRow,
    Grid,
  } from "@mui/material"
  import {
    ALERT_BUTTON_NO,
    ALERT_BUTTON_OK,
  
    ALERT_BUTTON_YES,
    DISABLE,
    DELETE_YES_BUTTON,
    DO_YOU_WANT_TO,
    DO_YOU_WANT_TO_DELETE,
    ENABLE, THE,
    ERR_ALERT_TITLE,
    ERR_GETTING,
    ERR_POSTING,
    SUCC_ALERT_TITLE,
    SUCC_DELETE,
    SUCCESSFULLY,
  } from "../../constants/alertMessage";
import AddIcon from "@mui/icons-material/Add";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
const Crop = () => {
    const localRole = JSON.parse(localStorage.getItem("role"));
    const permissions =
      localRole && localRole.permissions ? localRole.permissions : "All";
      const [crops,setCrops]=useState([])
      const [id, setId] = useState("");
      const [crop, setCrop] = useState("");
      const [filteredCrops, setFilteredCrops] = useState([]);
      const [isEdit, setIsEdit] = useState(false);
      const [page,setPage]=useState(0)
      const [noData, setNoData] = useState(false);
      const [rowsPerPage,setRowsPerPage]=useState(10)
      const [showModal, setShowModal] = useState(false);
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
      useEffect(()=>{
        fetchCropList()
      },[])
      const fetchCropList=async ()=>{
        try {
            const requestUrl='/api/crop/CropList';
            const response=await privateApiCall(requestUrl,"GET")
            console.log(response,"crop list")
            setCrops(response.data.crops)
            setFilteredCrops(response.data.crops)
            setNoData(response.data.crops.length===0)

        } catch (error) {
            Swal.fire({
                title: `${ERR_ALERT_TITLE}`,
                icon: `error`,
                text: `${ERR_GETTING}`,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`
              });
            
        }
      }
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const endpoint = isEdit
            ? `/api/crop/updateCrop?cropId=${id}`
            : `/api/crop/addCrop`;
          const method = isEdit ? "put" : "post";
          const data = isEdit
            ? { crop }
            : { crop, status: "active" };
    
          const response = await privateApiCall(endpoint, method, data);
          console.log(response,"response added")
    
          if (response.status === 200) {
            
            Swal.fire({
              title: `${SUCC_ALERT_TITLE}`,
              text: response.data.message,
              icon: "success",
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
            fetchCropList();
            handleModalClose();
          } else {
            setShowModal(false)
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `error`,
              text: response.data.message,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText:`${ALERT_BUTTON_OK}`,
            });
          }
        } catch (error) {
          if (error.response) {
            setShowModal(false)
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `error`,
              text: error.response.data.message,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText:`${ALERT_BUTTON_OK}`
            });
            
          } else {
            setShowModal(false)
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `error`,
              text: `${ERR_POSTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`
            });
          }
        }
      };
      const handleModalClose = () => {
        setShowModal(false);
        setCrop("");
        setIsEdit(false);
        setId("");
      };
    
      const handleModalOpen = (editMode = false, item = null) => {
        setIsEdit(editMode);
        if (editMode && item) {
            setCrop(item.cropName);
           
          setId(item._id);
        } else {
            setCrop("");
           
          setId("");
        }
        setShowModal(true);
      };
      const handleSearchCrop = async (e) => {
        try {
          const searchValue = e.target.value;
          if (searchValue === "") {
            setFilteredCrops(crops)
            setCrops(crops);
            fetchCropList();
            return;
          }
          const requestUrl = `/api/crop/search/${searchValue}`;
          const response = await privateApiCall(requestUrl, "GET");
          console.log(response,"response")
    
          setFilteredCrops(response.data.crop);
          setCrops(response.data.crop);
          setNoData(response.data.crop.length === 0);
        } catch (error) {
            console.log(error,"error****")
           
            if (error.response && error.response.status === 404) {
                setNoData(true);
              } else {
                setNoData(false);
              }
        }
      };
      const actionFormatter = (item) => {
        console.log(item,"items.....")
        const handleDisable = async () => {
          try {
            const cropId = item._id;
            const result = await Swal.fire({
              title: `${DO_YOU_WANT_TO} ${
                item.status === "active" ? `${DISABLE}` : `${ENABLE}`
              } ${THE} ${item.cropName}?`,
              icon: "question",
              showCancelButton: true,
              confirmButtonText: `${ALERT_BUTTON_YES}`,
              cancelButtonText: `${ALERT_BUTTON_NO}`
            });
    
            if (result.isConfirmed) {
              const requestUrl = `/api/crop/updateStatus?cropId=${cropId}`;
    
              const response = await privateApiCall(requestUrl, "PUT", {});
              if (response.data.status) {
                Swal.fire({
                  title: "Success",
                  text: `${item.cropName} ${
                item.status === "active" ? `${DISABLE}` : `${ENABLE}`
                  } ${SUCCESSFULLY}`,
                  icon: "success",
                  confirmButtonText: `${ALERT_BUTTON_OK}`,
                });
                fetchCropList();
              } else {
                Swal.fire({
                  title: `${ERR_ALERT_TITLE}`,
                  text: response.data.message,
                  icon: "error",
                  confirmButtonText:`${ALERT_BUTTON_OK}`,
                });
              }
            }
          } catch (error) {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `error`,
              text: `${ERR_POSTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText:`${ALERT_BUTTON_OK}`,
            });
          }
        };
    
        const handleDelete = async () => {
          try {
            const result = await Swal.fire({
              title: `${DO_YOU_WANT_TO_DELETE} ${item.cropName}?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#d33",
              cancelButtonColor: " #3085d6",
              confirmButtonText: `${DELETE_YES_BUTTON}`,
       
            });
    
            if (result.isConfirmed) {
              const requestUrl = `/api/crop/${item._id}`;
    
              const response = await privateApiCall(requestUrl, "DELETE", {});
    
              if (response.data.status) {
                Swal.fire({
                  title: `${SUCC_ALERT_TITLE}`,
                  text: `${item.cropName} ${SUCC_DELETE}`,
                  icon: "success",
                  confirmButtonText:`${ALERT_BUTTON_OK}`,
                });
                fetchCropList();
              } else {
                Swal.fire({
                  title: `${ERR_ALERT_TITLE}`,
                  text: response.data.message,
                  icon: "error",
                  confirmButtonText: `${ALERT_BUTTON_OK}`,
                });
              }
            }
          } catch (error) {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `error`,
              text: `${ERR_POSTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        };
    
        return (
          <span>
            {permissions.some(
              (item) => item.keyword === "settingsCrops" && item.isEdit === true
            ) && (
              <>
                <FiEdit2
                  size="18px"
                  type="button"
                  data-tooltip-id="1"
                  data-tooltip-content="Edit"
                  color="#0978F2"
                  onClick={() => handleModalOpen(true, item)}
                ></FiEdit2>
                <Tooltip id="1" />
              </>
            )}
    
            <i onClick={handleDisable}>
              {item.status === "active"
                ? permissions.some(
                    (item) => item.keyword === "settingsCrops" && item.isBlock === true
                  ) && (
                    <FaRegThumbsDown
                      size="18px"
                      color="red"
                      data-tooltip-id="2"
                      data-tooltip-content="Disable"
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    />
                  )
                : permissions.some(
                    (item) => item.keyword === "settingsVariety" && item.isUnblock === true
                  ) && (
                    <FaRegThumbsUp
                      size="18px"
                      color="green"
                      data-tooltip-id="2"
                      data-tooltip-content="
                  Enable"
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    />
                  )}
              <Tooltip id="2" style={{ fontStyle: "normal" }} />
            </i>
            {permissions.some(
              (item) => item.keyword === "settingsCrops" 
            ) && (
              <i onClick={handleDelete}>
                <FiTrash2
                  size="18px"
                  color="red"
                  data-tooltip-id="3"
                  data-tooltip-content="Delete"
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
                <Tooltip id="3" style={{ fontStyle: "normal" }} />
              </i>
            )}
          </span>
        );
      };
  
  return (
    <div id="wrapper">
    <Topbar backgroundColor="#769A0F" />
    <Sidebar />
    <div className="content-page">
        <div className="content">
          <div className="container-fluid">
          <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                      </li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.CROP}</li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.CROP}</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  {permissions.some(
                    (item) =>
                      item.keyword === "settingsCrops" && item.isCreate === true
                  ) && (
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => handleModalOpen()}
                      sx={{
                        backgroundColor: "#769A0F",
                        color: "#fff",
                        marginBottom: "20px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#769A0F",
                        },
                      }}
                    >
                      {STRING_CONSTANTS.ADD_CROP}
                    </Button>
                  )}
                  <div
                    style={{
                      position: "relative",
                      float: "right",
                      width: "200px",
                    }}
                  >
                     {permissions.some(
                    (item) =>
                      item.keyword === "settingsCrops" 
                  ) && (
                   <>
                   
                   <IoSearch
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    />
                     <input
                      type="text"
                      placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                      className="form-control"
                      style={{
                        paddingLeft: "30px",
                      }}
                      onChange={handleSearchCrop}
                    />
                   </>
                  )}
                  
                  </div>
                  <Modal
                    open={showModal}
                    onClose={handleModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <div
                      style={{
                        padding: "20px",
                        background: "white",
                        width: "60%",
                        margin: "100px auto",
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <h2>
                        {isEdit ? "Edit" : "Add"} 
                        {STRING_CONSTANTS.CROP}</h2>
                      <form  onSubmit={handleSubmit}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                          <TextField
                            label="Crop"
                            type="text"
                            value={crop}
                            onChange={(e) => setCrop(e.target.value)}
                            required
                            style={{ marginTop: "20px" }}
                          />
                        </FormControl>
                         
                        <Grid
                          container
                          justifyContent="flex-end"
                          sx={{ mt: 3 }}
                        >
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={handleModalClose}
                            sx={{ mt: 2, mr: 1 }}
                          >
                            {STRING_CONSTANTS.CLOSE}
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            sx={{ mt: 2 }}
                            style={{ backgroundColor: "#769A0F" }}
                          >
                            {isEdit ? `${STRING_CONSTANTS.UPDATE}`: `${STRING_CONSTANTS.SUBMIT}`}
                          </Button>
                        </Grid>
                      </form>
                    </div>
                  </Modal>
                   {noData ? (
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                      {STRING_CONSTANTS.NO_CROP_AVAILABLE}
                    </div>
                  ) : (
                    <div style={{ width: "98%" }}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell width="80px" align="center">
                                {STRING_CONSTANTS.SN_NO}
                              </TableCell>
                              <TableCell width="180px" align="left">
                                {" "}
                                {STRING_CONSTANTS.CROP}
                              </TableCell>
                              <TableCell width="180px" align="left">
                              {STRING_CONSTANTS.STATUS}
                              </TableCell>
                              <TableCell width="80px" align="left">
                              {STRING_CONSTANTS.ACTIONS}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredCrops.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            ).map((crop, index) => (
                              <TableRow key={index}>
                                <TableCell width="80px" align="center">
                                  {page * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell width="180px" align="left">
                                  {crop.cropName}
                                </TableCell>
                                
                                <TableCell>
                                  {" "}
                                  {crop.status === "active" ? (
                                    <span className="btn-sm btn-success ">
                                      {STRING_CONSTANTS.ACTIVE}
                                    </span>
                                  ) : (
                                    <span className="btn-sm btn-danger">
                                      {STRING_CONSTANTS.INACTIVE}
                                    </span>
                                  )}{" "}
                                </TableCell>
                                <TableCell width="80px" align="left">
                                  {actionFormatter(crop)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={filteredCrops.length}
                        rowsPerPage={rowsPerPage||0}
                        page={page||0}
                       onPageChange={handleChangePage}
                       onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  )}
                  
                   
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Crop