
const isProduction = true;

export const API = isProduction
  ? "https://qa.jackfruitworld.com:3002"
  : "https://dev.jackfruitworld.com:3002";

// export const API = "http://localhost:3002";

export const APP_NAME = "JackFruitWorld";
