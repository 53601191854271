import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { IoSearch } from "react-icons/io5";
import Cookies from "universal-cookie";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link } from "react-router-dom";
import {
  DATE_SEARCH_CUSTOMER,
  ORG_CUSTOMER,
  ORG_HANDLE_REQ,
  ORG_SEARCH_CUSTOMER,
  SEARCH_CUSTOMER_ORG,
} from "../../constants/url";
import { privateApiCall } from "../../api/privateApi";
import {
  ALERT_BUTTON_NO,
  ALERT_BUTTON_OK,
  ALERT_BUTTON_YES,
  ALERT_ICON_ERR,
  ALERT_ICON_QUESTON,
  ALERT_ICON_WARNING,
  CONFIRM_ACCEPT_CUS,
  ERR_ALERT_TITLE,
  ERR_GETTING,
} from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import { STATUS_ACCEPTED, STATUS_INACTIVE, STATUS_PENDING, STATUS_REJECTED } from "../../constants/orgStatus";

const cookies = new Cookies();
const Customer = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const [searchValue, setSearchValue] = useState("");
  const [startDat, setStartDat] = useState("");
  const [endDat, setEndDat] = useState("");
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [startDateSearch, setStartDateSearch] = useState("");
  const [endDateSearch, setEndDateSearch] = useState("");
  const [values, setValues] = useState({
    data: [],
    success: false,
    removed: false,
    img1: "",
    img2: "",
    img3: "",
    img4: "",
    keywords: "",
    title: "",
    description: "",
    qty: "",
    price: "",
    unit: "",
    location: "",
    latitude: "",
    longitude: "",
    expdate: "",
    availableDate: "",
    auction_status: "",
    organic_status: "",
    safe_status: "",
    transpot_status: "",
    chargesAfter: "",
    freeDelivery: "",
    startDate: null,
    endDate: null,
  });
  const { startDate, endDate, data } = values;

  // useEffect(() => {
  //   loadData();
  // }, []);
  useEffect(() => {
    if (searchValue || startDat || endDat) {
      handleSearch();
    } else {
      loadCustomer();
    }
  }, [startDat, endDat, searchValue]);

  const loadCustomer = async () => {
    const requestUrl = `${ORG_CUSTOMER}`;
    const response = await privateApiCall(requestUrl, "GET");

    const k = 1;
    for (let i = 0; i < response.data.length; i++) {
      response.data[i].sno = k + i;
    }
    setValues({ ...values, data: response.data });
  };

  const handleSearch = async (e) => {
    try {
      const requestUrl = `/api/organisation/searchCustomer?query=${searchValue}&startDat=${startDat}&endDat=${endDat}`;
      const response = await privateApiCall(requestUrl, "GET");

      if (response.data.status) {
        const customerWithSno = response.data.data.map((customer, index) => {
          return {
            ...customer,
            sno: index + 1,
          };
        });
        setValues({ ...values, data: customerWithSno });
        setPage(0)
      } else {
        setValues({ ...values, data: [] });
      }
    } catch (error) {}
  };
  const handleSearchMember = async (e) => {
    const searchValue = e.target.value;

    try {
      const requestUrl = `${SEARCH_CUSTOMER_ORG}?org_id=${cookies.get(
        "org_id"
      )}&query=${searchValue}`;
      const response = await privateApiCall(requestUrl, "GET");

      if (response.error) {
        return;
      }

      if (Array.isArray(response.data.data)) {
        const customersWithSno = response.data.data.map((customer, index) => {
          return {
            ...customer,
            sno: index + 1,
          };
        });

        setValues({ ...values, data: customersWithSno });
      } else {
        setValues({ ...values, data: [] });
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  // const handleChangeStart = (date) => {
  //   console.log(date,"start Date");
  //   loadData1("start",(date ? dayjs(date).format("MM/DD/YYYY") : ""))
  // };
  // const handleChangeEnd = (date) => {
  //   console.log(date,"endDate")
  //   setEndDateSearch(date ? dayjs(date).format("MM/DD/YYYY") : "");
  //   loadData1("end",endDateSearch)
  // };
  const handleChangeStart = (date) => {
    setStartDat(date ? dayjs(date).format("MM/DD/YYYY") : "");
  };
  const handleChangeEnd = (date) => {
    setEndDat(date ? dayjs(date).format("MM/DD/YYYY") : "");
  };

  // const handleOrgRequest = (id, status) => {
  //   if (status === "0") {
  //     let swalTitle;
  //     let showButtons = false;
  //     showButtons = true;
  //     Swal.fire({
  //       title:"Please choose an action for this customer's request",
  //       icon: `${ALERT_ICON_WARNING}`,
  //       showCancelButton: true,
  //       confirmButtonText: `${ALERT_BUTTON_YES}`,
  //       cancelButtonText: `${ALERT_BUTTON_NO}`,
  //       showDenyButton: showButtons,
  //       denyButtonText: "Accept",
  //       confirmButtonText: "Reject",
  //       showConfirmButton: showButtons,
  //       confirmButtonColor: "#d33",
  //       denyButtonColor:"#3085d6"
  //     }).then((result) => {
  //       console.log(result,"result data")
  //       if (result.isDenied) {

  //         privateApiCall(`${ORG_HANDLE_REQ}`, "POST", { id, status: "2" }) // "2" for accept
  //           .then((response) => {
  //             loadCustomer();

  //           })
  //           .catch((error) => {
  //             Swal.fire(`${STRING_CONSTANTS.ERR_BLOCK_MESSAGE}`);
  //           });
  //       } else if (result.isConfirmed) {

  //         privateApiCall(`${ORG_HANDLE_REQ}`, "POST", { id, status: "1" }) // "1" for reject
  //           .then((response) => {
  //             loadCustomer();

  //           })
  //           .catch((error) => {
  //             Swal.fire(`${STRING_CONSTANTS.ERR_UPDATE_STATUS}`);
  //           });
  //       }
  //     });
  //   } else {
  //     const swalTitle =
  //       status === "1"
  //         ? "Do you want to remove this customer from this organization?"
  //         : status === "2"
  //         ? "You want to accept  this customer's request to join the organisation?"
  //         : "You want to   this customer's request to join the organisation?";
  //     Swal.fire({
  //       title: `${swalTitle}`,
  //       //  text: `${CONFIRM_ACCEPT_CUS}`,
  //       icon: `${ALERT_ICON_WARNING}`,
  //       showCancelButton: true,
  //       confirmButtonText: `${ALERT_BUTTON_YES}`,

  //       cancelButtonText: `${ALERT_BUTTON_NO}`,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         const reqeustUrl = `${ORG_HANDLE_REQ}`;
  //         privateApiCall(reqeustUrl, "POST", { id, status })
  //           .then(async (response) => {
  //             loadCustomer();
  //             Swal.fire({
  //               title: `${STRING_CONSTANTS.SUCC_UPDATE_STATUS}`,
  //               icon: "success",
  //               confirmButtonText: "OK",
  //               allowOutsideClick: false,
  //             });
  //           })
  //           .catch((error) => {
  //             Swal.fire(`${STRING_CONSTANTS.ERR_UPDATE_STATUS}`);
  //           });
  //       }
  //     });
  //   }
  // };
  const handleOrgRequest = (id, status) => {
    console.log(status, "statussss");
  if(status===STATUS_INACTIVE){
    return
  }
    if (status === STATUS_PENDING) {
      Swal.fire({
        title: "Choose an action",
        icon: `${ALERT_ICON_QUESTON}`,
        showCancelButton: true,
        confirmButtonText: "Reject",
        cancelButtonText: "Cancel",
        showDenyButton: true,
        denyButtonText: "Accept",
        confirmButtonColor: "#d33",
        denyButtonColor: "#3085d6",
      }).then((result) => {
        if (result.isDenied) {
          // Handle "accept" action
          privateApiCall(`${ORG_HANDLE_REQ}`, "POST", { id, status: STATUS_ACCEPTED }) // "2" for accept
            .then(() => {
              loadCustomer();
            })
            .catch(() => {
              Swal.fire(`${STRING_CONSTANTS.ERR_BLOCK_MESSAGE}`);
            });
        } else if (result.isConfirmed) {
          // Prompt for reason before rejecting
          promptForRejectionReason(id);
        }
      });
    } else if (status === STATUS_REJECTED) {
      // Prompt for rejection reason when status is 1
      Swal.fire({
        title: "Do you want to reject this customer?",
        icon: `${ALERT_ICON_QUESTON}`,
        showCancelButton: true,
        confirmButtonText: "Reject",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          promptForRejectionReason(id);
        }
      });
    } else {
      // Handle other statuses (e.g., accept with status 2)
      const swalTitle = "Do you want to accept this customer?";
      Swal.fire({
        title: swalTitle,
        icon: `${ALERT_ICON_QUESTON}`,
        showCancelButton: true,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        cancelButtonText: `${ALERT_BUTTON_NO}`,
      }).then((result) => {
        if (result.isConfirmed) {
          privateApiCall(`${ORG_HANDLE_REQ}`, "POST", { id, status })
            .then(() => {
              loadCustomer();
            })
            .catch(() => {
              Swal.fire(`${STRING_CONSTANTS.ERR_UPDATE_STATUS}`);
            });
        }
      });
    }
  };
  
  // Helper function to prompt for rejection reason
  const promptForRejectionReason = (id) => {
    Swal.fire({
      title: "Please provide a reason for rejection",
      input: "text",
      inputPlaceholder: "Enter reason here...",
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "A rejection reason is required!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle "reject" action with reason and status 3
        privateApiCall(`${ORG_HANDLE_REQ}`, "POST", {
          id,
          status: STATUS_INACTIVE, // "3" for rejected with reason
          reason: result.value,
        })
          .then(() => {
            loadCustomer();
          })
          .catch(() => {
            Swal.fire(`${STRING_CONSTANTS.ERR_UPDATE_STATUS}`);
          });
      }
    });
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };
  const handleRowPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  const dataTable = () => {
    return (
      <div id="wrapper">
        <Topbar backgroundColor="#0baae5" />
        <Sidebar type="profiles" />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          {" "}
                          <Link to="/organisation/dashboard">
                            {STRING_CONSTANTS.DASHBOARD}
                          </Link>
                        </li>
                        <li className="breadcrumb-item active">
                          {STRING_CONSTANTS.CUSTOMER}
                        </li>
                      </ol>
                    </div>
                    <h4 className="page-title">{STRING_CONSTANTS.CUSTOMER}</h4>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <div className="col-md-12">
                  <div
                    className="row container-fluid"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {(permissions === "All" ||
                      permissions.some(
                        (item) =>
                          item.keyword === "orgProfileCustomers" &&
                          item.isDateSearch
                      )) && (
                      <>
                        <div className="col-md-3 ">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                label=" Start Date&nbsp;&nbsp;&nbsp;"
                                format="DD/MM/YYYY"
                                // defaultValue={  dayjs(formData.endDate)}
                                defaultValue={dayjs(startDat)}
                                name="startDate"
                                onChange={handleChangeStart}
                                required
                                // minDate={dayjs()}
                                slotProps={{
                                  textField: {
                                    error:
                                      !!startDat && !dayjs(startDat).isValid(),
                                    sx: {
                                      width: "160px",
                                      "& .MuiInputBase-root": {
                                        fontSize: "11px",
                                        padding: "-4px",
                                      },
                                      "& .MuiInputLabel-root": {
                                        fontSize: "14px",
                                      },
                                    },
                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                        <div className="col-md-3 ">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                label="End Date&nbsp;&nbsp;&nbsp;"
                                format="DD/MM/YYYY"
                                defaultValue={dayjs(endDat)}
                                name="endDate"
                                onChange={handleChangeEnd}
                                required
                                // You can control the error state with this condition
                                slotProps={{
                                  textField: {
                                    error: !!endDat && !dayjs(endDat).isValid(),
                                    sx: {
                                      width: "160px",
                                      "& .MuiInputBase-root": {
                                        fontSize: "11px",
                                        padding: "-4px",
                                      },
                                      "& .MuiInputLabel-root": {
                                        fontSize: "14px",
                                      },
                                    },
                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </>
                    )}

                    {(permissions === "All" ||
                      permissions.some(
                        (item) =>
                          item.keyword === "orgProfileCustomers" &&
                          item.isSearch
                      )) && (
                      <>
                        <div
                          style={{ position: "relative", marginLeft: "auto" }}
                        >
                          <input
                            type="text"
                            placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                            className="form-control"
                            style={{
                              width: "200px",
                              paddingLeft: "35px",
                              boxSizing: "border-box",
                              marginLeft: "auto",
                            }}
                            onChange={(e) => {
                              setSearchValue(e.target.value);
                              // handleSearchMember(e);
                              // if (e.target.value.trim() === "") {
                              //   e.target.nextElementSibling.style.display =
                              //     "inline-block";
                              // } else {
                              //   e.target.nextElementSibling.style.display =
                              //     "none";
                              // }
                            }}
                          />
                          <IoSearch
                            style={{
                              position: "absolute",
                              left: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div style={{ width: "98%", marginTop: "20px" }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            {STRING_CONSTANTS.SN_NO}
                          </TableCell>
                          <TableCell align="center">
                            {STRING_CONSTANTS.NAME}
                          </TableCell>
                          <TableCell align="center">
                            {STRING_CONSTANTS.EMAIL}
                          </TableCell>
                          <TableCell align="center">
                            {STRING_CONSTANTS.MOBILE}
                          </TableCell>
                          <TableCell align="center">
                            {STRING_CONSTANTS.SUBSCRIPTION}
                          </TableCell>
                          <TableCell align="center">
                            {STRING_CONSTANTS.VALIDITY}
                          </TableCell>
                          <TableCell align="center">
                            {STRING_CONSTANTS.STATUS}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            ).map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">{row.sno}</TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.email}</TableCell>
                            <TableCell align="center">
                              {row.mobile_no}
                            </TableCell>
                            <TableCell align="center">{row.sub}</TableCell>
                            <TableCell align="center">{row.val}</TableCell>
                            <TableCell>
                              {row.orgStatus === STATUS_PENDING ? (
                                <span
                                  className="btn-sm btn-warning"
                                  onClick={() => handleOrgRequest(row._id, STATUS_PENDING)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {STRING_CONSTANTS.PENDING}
                                </span>
                              ) : row.orgStatus === STATUS_ACCEPTED ? (
                                <span
                                  className="btn-sm btn-primary"
                                  onClick={() => handleOrgRequest(row._id, STATUS_REJECTED)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {STRING_CONSTANTS.ACCEPTED}
                                </span>
                              ) : row.orgStatus === STATUS_REJECTED ? (
                                <span
                                  className="btn-sm btn-danger"
                                  onClick={() => handleOrgRequest(row._id, STATUS_ACCEPTED)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {STRING_CONSTANTS.REJECTED}
                                </span>
                              ) : row.orgStatus === STATUS_INACTIVE ? (
                                <span
                                  className="btn-sm btn-secondary"
                                  onClick={() => handleOrgRequest(row._id, STATUS_INACTIVE)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {STRING_CONSTANTS.WAITING}
                                </span>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                  rowsPerPageOptions={[10, 50, 100]}
                  page={page||0}
                  count={data.length}
                  rowsPerPage={rowsPerPage||0}
                  component="div"
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowPageChange}
                ></TablePagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <React.Fragment>{dataTable()}</React.Fragment>;
};

export default Customer;
