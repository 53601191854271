import React, { useState, useEffect } from 'react';
import Topbar from './topBar';
import Sidebar from './sideBar';
import { Link } from 'react-router-dom';
import { STRING_CONSTANTS } from '../../constants/stringConstants';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AiOutlineClockCircle } from 'react-icons/ai'; // Example icon

const Marketplace = () => {
  const dotStyle = {
    display: 'inline-block',
    width: '10px',
    height: '10px',
    margin: '0 5px',
    backgroundColor: '#0baae5',
    borderRadius: '50%',
    animation: 'pulse 1.5s infinite ease-in-out',
  };

  const loadingDotsStyle = {
    display: 'inline-block',
    marginLeft: '10px',
  };

  const keyframes = `
    @keyframes pulse {
      0% { opacity: 1; }
      50% { opacity: 0.5; }
      100% { opacity: 1; }
    }
  `;

  const calculateTimeLeft = () => {
    const targetDate = new Date('2024-12-31T23:59:59'); // Set your target date here
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">
                    {STRING_CONSTANTS.MARKETPLACE}
                  </h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">
                        {STRING_CONSTANTS.DASHBOARD}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {STRING_CONSTANTS.MARKETPLACE}
                    </li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="p-20"
                        style={{
                          textAlign: 'center',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '8px',
                          padding: '40px',
                          position: 'relative',
                        }}
                      >
                        <Box sx={{ mb: 2 }}>
                          <AiOutlineClockCircle size={50} color="#0baae5" />
                        </Box>
                         
                        <Typography
                          variant="h5"
                          component="h2"
                          gutterBottom
                          sx={{ mb: 3 }}
                          style={{ fontWeight: 'bold', display: 'inline' }}
                        >
                          An exciting global marketplace is coming soon
                        </Typography>
                        <div style={loadingDotsStyle}>
                          <div style={dotStyle}></div>
                          <div style={{ ...dotStyle, animationDelay: '0.3s' }}></div>
                          <div style={{ ...dotStyle, animationDelay: '0.6s' }}></div>
                        </div>
                        <style>
                          {keyframes}
                        </style>
                        <img  src="/assets/images/marketplace.jpg"  alt="Marketplace"
                        style={{
                          width: "100%",
                          height: "auto",
                          maxWidth: "600px",
                          borderRadius: "8px",
                          marginTop: "20px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}/>
                        
                         
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
