// Alert
export const ERR_POSTING = "An Error occurred while Submitting data.";
export const ERR_GETTING = "An Error occurred while Fetching data.";
export const SUCC_FEEDBACK = "Feedback added successfully!";
export const ENTER_FEEDBACK = "Please Add Feedback.";
export const ERR_ALERT_TITLE = "Error";
export const SUCC_ALERT_TITLE = "Success!";
export const SUCC_APPROVE_TITLE = "Approved!";
export const SUCC_APPROVE_TEXT = "The product has been approved successfully!";
export const SUCC_REJECTPRODUCT_TXT =
  "The product has been rejected successfully!";
export const SUCC_REJECTPRODUCT_TITLE = "Product Rejected!";
export const ERROR = "ERROR";
export const ERR = "error";
export const CONFIRM = "OK";
export const CONFIRM_TITLE = "Are you sure?";
export const CONFIRM_TXT_REJ_ALL =
  "You are about to reject the selected product(s).";
export const CONFIRM_TXT_ACC_ALL =
  "You are about to approve the selected product(s).";
export const CONFIRM__ACC_ALL_YES_BUTTON = "Yes, approve them!";
export const CONFIRM__REJ_ALL_YES_BUTTON = "Yes, reject them!";
export const CONFIRM__DEL_COIN_YES_BUTTON = "Yes, delete it!";
export const CONFIRM_TXT_DEL_COIN = "You won't be able to revert this!";
export const SUCC_COIN_DEL =
  "'Deleted!', 'Your coin has been deleted.', 'success'";
export const CONFIRM_PASSWORD_ERR =
  "New password and Confirm password do not match!";
export const SUCC_ADD_CUS = "Customer added Successfully !";
export const SUCC_EDIT_PROFILE = "Your profile has been updated successfully!";
export const CONFIRM_BLOCK_CUS = "You want to block this customer?";
export const CONFIRM_ACCEPT_CUS = "You want to accept  this customer's request to join the organisation?";
export const CONFIRM_UNBLOCK_CUS = "You want to unblock this customer?";
export const SUCC_MFA_TITLE = "MFA Configuration updated";
export const SUCC_MFA_TXT = "MFA Configuration updated successfully!";
export const SUCC_EDIT_BTYPE = "Business Type updated successfully.";
export const SUCC_ADD_BTYPE = "Business Type added successfully.";
export const NETWORK_NOT_OK = "Network response was not ok";

export const CONFIRM_TXT_DEL = "You won't be able to revert this!";
export const SUCC_SUB_TEXT = "Subscription added successfully!";
export const SUCC_SUB_EDT_TEXT = "Subscription updated successfully!";
export const SUCC_MAIL_SEND = "Email sent successfully!";

export const SUCCESS_SAVE_PERMISSIONS = "Permissions Saved Successfully!";
export const VOUCHER_SUCCESS = "Voucher Assigned Successfully";
export const VOUCHER_ERROR = "An error occurred while submitting data.";
export const VALUES = "Updated state values:";
export const CURRENT_VALUES = "Current state values:";
export const ORG = "Fetched organizations:";
export const UPT_ORG = "Updated state values with organizations:";
export const ERR_ORG = "Error loading organizations:";
export const REND_ORG = "Rendering organizations in dropdown:";
export const ERR_EMAIL = "Invalid Email Address!";
export const EMAIL_REQ = "Email Is Required!";
export const PASSWORD_REQ = "Password Is Required!";
export const MFA_CODE_REQ = "6 Digit Code Is Required";
export const INVALID_CRED = "Invalid Credentials!";
export const MFA_FAILD = "MFA verification failed.";
export const ALERT_ICON_SUCC = "success";
export const ALERT_ICON_ERR = "error";
export const NO_LOCATION="Location not set";
export const VALID_LOCATION="Please select a valid location";
export const NO_DATA="No data found for the searched criteria";
export const  NO_DATA_FOUND="No data found";
export const NO_OFFER="No Accepted Offer Found";
export const ALERT_BUTTON_OK = "OK";
export const NO_COM="There are no completed buy products available.";
export const NO_APP="There are no approved buy products available.";
export const NO_EXP="There are no expired buy products available.";
export const NO_PEN="There are no pending buy products available.";
export const NO_REJ="There are no rejected buy products available.";
export const NO_S_APP="There are no approved sell products available."
export const NO_S_EXP="There are no expired sell products available.";
export const NO_S_PEN="There are no pending sell products available.";
export const NO_S_REJ="There are no rejected sell products available.";
export const NO_S_SOLD="There are no soldout sell products available.";
export const NO_BUS_APP="There are no approved business profile available.";
export const NO_BUS_PEN="There are no pending business profile available";
export const NO_BUS_REJ="There are no rejected business profile available"
export const EMAIL_PLACEHOLDER_FIELD = "Enter your email";
export const PASSWORD_PLACEHOLDER_FIELD = "Enter your password";
export const EMAIL_LABEL = "Email Address";
export const PASSWORD_LABEL = "Password";
export const REMEMBER_ME = "Remember Me";
export const FORGOT_PASS = "Forgot Password?";
export const SIGN_IN = "Sign In";
export const AGGRED_TO = "By clicking Sign In you are agreeing to our";
export const TERMS_COND = "Terms & Conditions";
export const AND = "and";
export const PRIVACY_POLICY = "Privacy Policy";
export const MFA_6_DIGIT_ENTER =
  "Enter the 6 digit code shown in Google Authenticator app";
export const FAILD_OTP = "OTP verification failed.";
export const VERIFY_MFA_CODE = "Verify MFA Code";
export const SEND_RESET = "Send Reset Link";
export const MFA_CODE_PLACEHOLDER = "Enter 6 digit code";
export const BACK_TO_LOGIN = "Back to Login";
export const MFA_QR_SCAN = "Scan this QR Code with Google Authenticator";
export const ENTER_OTP = "Enter OTP";
export const VERIFY_OTP = "Verify OTP";
export const CANCEL = "Cancel";

export const TOKEN_EXP = "Your Secure Token is expired";
export const PASSWORD_MIN_CHAR = "Your password must contain 6 letter";
export const PASSWORD_CHANGE_SUCC = "Password changed successfully";
export const INCORRECT_PASSWORD = "Incorrect password";
export const PASSWORD_MISSMATCH = "Password & Confirm Password  is Mismatched";
export const RESET_PASSWORD_LABEL = "Reset Password";
export const NEW_PASSWORD_LABEL = "New Password";
export const CONFIRM_NEW_PASS = "Confirm New Password";
export const RE_ENTER_PASSWORD = "Re-enter your password";
export const SUBMIT_LABEL = "Submit";
export const REJECT_TITLE = "Reject";
export const ALERT_BUTTON_YES = "Yes";
export const APPROVE = "Approve";
export const WARNING = "warning";
export const DELETE_YES_BUTTON = "Yes, delete";
export const DELETED = "Deleted!";
export const REJECTED = "Rejected!";
export const SUCC_REJECT_ALL_PRODUCTS_TITLE =
  "Selected product(s) have been rejected.";
export const COIN_UPDATE_SUCC = "Coin updated successfully!";
export const COIN_ADD_SUCC = "Coin added successfully!";
export const DO_YOU_WANT_TO = "Do you want to";
export const DISABLE = "disable";
export const ENABLE = "enable";
export const THE = "the";
export const ALERT_ICON_QUESTON = "question";
export const ALERT_CHOOSE="Choose an action";
export const ALERT_BUTTON_NO = "No";
export const SUCCESSFULLY = "successfully";
export const DO_YOU_WANT_TO_DELETE = "Do you want to  delete";
export const ALERT_ICON_WARNING = "warning";
export const SUCC_DELETE = "has been deleted successfully!";
export const MFA_SUCCESS = "MFA Configuration updated successfully!";
export const MFA_FAILD_MESSAGE="'otp', 'MFA Configuration failed. Please try again'"
export const OTP_FAILD_MESSAGE="'otp', 'OTP verification failed.'"
export const PASSWORD_MISSMATCH_MESSAGE="'password', Passwords do NOT match"
export const RESTORE_ORG="Restore Organisation"
export const DELETE_QUESTION="Delete question"
export const SUCC_UPDATE_USER_TYPE="'Updated!', 'User type updated successfully.', 'success'"
export const SUCC_ADD_USER_TYPE="'Added!', 'User type added successfully.', 'success'"
export const SUCC_DEL_USER_TYPE="'Deleted!', 'User type has been deleted.', 'success'"
export const SUCC_SUB_DEL="'Deleted!', 'Subscription has been deleted.', 'success'"
export const ERR_SUB_DEL="'Error!', 'Failed to delete subscription.', 'error'"
export const BLOCK_EMPLOYEE="Block Employee"
export const BLOCKED_EMPLOYEE="Blocked Employee"
export const UNBLOCK_EMPLOYEE="Unblock Employee "
export const UNBLOCKED_EMPLOYEE="Unblocked Employee "
export const SUCC_EMP_ADDED="Employee added successfully."
export const DO_YOU_WANT_TO_DELETE_BROCHURE="Do You Want to delete this brochure"
export const OTP_REQUIRED="OTP is required"
export const REMOVE_ORG="Remove Organisation"
export const BLOCK_VOLUNTEER="Block Volunteer"
export const UN_BLOCK_VOLUNTEER="Unblock Volunteer"
export const COPIED_TO_CLIPBOARD="copied to clipboard"
export const DO_YOU_WANT_TO_DELETE_BILLILNG_ADDRESS="Do You Want to delete this Billing Address?"
export const RESTORE_ACC="Restore access for"
export const REMOVE_ACC="Remove access for"
