import { useState, useEffect, useCallback } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import "react-tagsinput/react-tagsinput.css";
import { Link } from "react-router-dom";

import Cookies from "universal-cookie";
import {
  GET_ROLES,
  GET_PERMISSIONS,
  GET_ROLEBYID,
  MAP_PERMISSIONS,
  IMPORT_MOBILE_PERMISSIONS,
  EXPORT_MOBILE_PERMISSIONS,
  IMPORT_ROLE_PERMISSIONS,
  EXPORT_ROLE_PERMISSIONS,
} from "../../constants/url";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Paper,
} from "@mui/material";
import {
  ERR_GETTING,
  ERR_POSTING,
  SUCCESS_SAVE_PERMISSIONS,
} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { toast, Bounce } from "react-toastify";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const RolePermissions = () => {
  const cookies = new Cookies();
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [roleDetails, setRoleDetails] = useState();
  
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    fetchRoles();
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (roleId) {
      fetchRoleById();
      console.log(permissions);
    }
  }, [roleId]);

  const fetchRoles = async () => {
    try {
      const requestUrl = `${GET_ROLES}`;
      const response = await privateApiCall(requestUrl, "GET");
      setRoles(response.data.roles);
    } catch (error) {
      toast.error(`${ERR_GETTING}`, { transition: Bounce });
    }
  };

  const fetchRoleById = async () => {
    try {
      const requestUrl = `${GET_ROLEBYID}/${roleId}`;
      const response = await privateApiCall(requestUrl, "GET");
      setRoleDetails(response.data);
      setPermissions(response.data.permissions);
    
      
    } catch (error) {
      toast.error(`${ERR_GETTING}`, { transition: Bounce });
    }
  };

  const fetchPermissions = async () => {
    try {
      const requestUrl = `${GET_PERMISSIONS}`;
      const response = await privateApiCall(requestUrl, "GET");
      setPermissions(response.data.permissions);
    } catch (error) {
      toast.error(`${ERR_GETTING}`, { transition: Bounce });
    }
  };

  const handleCheckboxChange = (keyword, property) => {
    
    
    setPermissions((prevPermissions) =>
      prevPermissions.map((permission) =>
                     

        permission.keyword === keyword
          ? { ...permission, [property]: !permission[property] }
          : permission
         
      )
    );
    
    
  };

  const handleSubmit = useCallback(async (event) => {
    const roleData = { roleId: roleId, permissions: permissions };
    event.preventDefault();
    const requestUrl = `${MAP_PERMISSIONS}`;
    try {
    const updatedRole =  await privateApiCall(requestUrl, "POST", roleData);
    

    console.log(updatedRole.data,"role...");
    
    localStorage.setItem("role", JSON.stringify(updatedRole.data))
    let localRole = JSON.parse(localStorage.getItem("role"))
    console.log(localRole.name)
      toast.success(`${SUCCESS_SAVE_PERMISSIONS}`, { transition: Bounce });
    } catch (err) {
      toast.error(`${ERR_POSTING}`, { transition: Bounce });
    }
  });



  const handleExport = useCallback(async (event) => {
    event.preventDefault();
  
    const requestUrl = `${EXPORT_ROLE_PERMISSIONS}`; // API endpoint for exporting Role permissions
  
    try {
      // Make the API call using privateApiCall
      const response = await privateApiCall(requestUrl, 'GET', null, {
        responseType: 'blob', // To handle file downloads
      });
      
      
      const jsonString = JSON.stringify(response.data, null, 2); 
  
   
  
      // Create a new Blob for the file data
      const blob = new Blob([jsonString], { type: 'application/json' });
  
      // Use the File System Access API to prompt the user for a save location
      const options = {
        suggestedName: `RolePermissions.json`, // Suggest the file name with .json extension
        types: [
          {
            description: 'JSON Files',
            accept: { 'application/json': ['.json'] }, // Specify file types
          },
        ],
      };
  
      // Show the save file dialog
      const fileHandle = await window.showSaveFilePicker(options);
  
      // Create a writable stream
      const writable = await fileHandle.createWritable();
  
      // Write the blob data to the file
      await writable.write(blob);
      
      // Close the writable stream to save the file
      await writable.close();
  
      // Show success notification
      toast.success('Role permissions exported successfully!', { transition: Bounce });
    } catch (err) {
      // Show error notification in case of failure
      toast.error('Error exporting role permissions!', { transition: Bounce });
    }
  }, []);
  




  



  const handleImport = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = async(e) => {
      try {
        const jsonData = JSON.parse(e.target.result);
    
        const requestUrl = `${IMPORT_ROLE_PERMISSIONS}`; // API endpoint for importing role permissions
        //         console.log("enteing here");
        
                // Make the API call using privateApiCall
                const response = await privateApiCall(requestUrl, 'POST', jsonData, {
                  responseType: 'json', // Adjust based on your API response
                });
             
                toast.success('Role permissions imported successfully!', { transition: Bounce });
                window.location.reload()
               
     // Pass JSON data to the import handler
      } catch (error) {
        console.error('Invalid JSON file:', error);
        toast.error('Error importing Role permissions!', { transition: Bounce });
      }
    };
    if (file) {
      reader.readAsText(file);
    }
  };


  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="configurations" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.ROLE_PERMISSON}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.ROLE_PERMISSON}</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel>{STRING_CONSTANTS.SELECT_ROLE}</InputLabel>
                    <Select
                      label="select role"
                      value={roleId}
                      onChange={(e) => setRoleId(e.target.value)}
                      required
                    >
                      <MenuItem value="">
                        {" "}
                        <em>{STRING_CONSTANTS.SELECT_ROLE}</em>{" "}
                      </MenuItem>
                      {roles
                        .sort((a, b) => a.roleId - b.roleId)
                        .map((role) => (
                          <MenuItem key={role.roleId} value={role.roleId}>{role.name}</MenuItem>
                        ))}
                    </Select>
                    <Grid container justifyContent="flex-end" sx={{ mt: 3 }}>
                      <Link to="/dashboard">
                        {" "}
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{ mt: 2, mr: 1 }}
                        >
                          {" "}
                          {STRING_CONSTANTS.CANCEL}{" "}
                        </Button>
                      </Link>
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="success"
                        sx={{ my: 2,mr:1 }}
                        style={{ backgroundColor: "#769A0F" }}
                      >
                        {" "}
                        {STRING_CONSTANTS.SAVE}{" "}
                      </Button>
                      <Button
                        onClick={handleExport}
                        variant="contained"
                        color="success"
                        sx={{ my:2,mr:1}}
                        style={{ backgroundColor: "#769A0F" }}
                      >
                        Export Permission
                      </Button>


                          <>
      <input
        accept=".json"
        style={{ display: 'none' }}
        id="import-file"
        type="file"
        onChange={handleImport}
      />
      <label htmlFor="import-file">
        <Button
          variant="contained"
          color="success"
          component="span"
          sx={{ mt: 2}}
          style={{ backgroundColor: "#769A0F" }}
        >
          Import Permission
        </Button>
      </label>
    </>
                    </Grid>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{STRING_CONSTANTS.PERMISSON}</TableCell>
                          <TableCell>{STRING_CONSTANTS.VIEW}</TableCell>
                          <TableCell>{STRING_CONSTANTS.CREATE}</TableCell>
                          <TableCell>{STRING_CONSTANTS.EDIT}</TableCell>
                          <TableCell>{STRING_CONSTANTS.DELETE}</TableCell>
                          <TableCell>{STRING_CONSTANTS.FEEDBACK_LABEL}</TableCell>
                          <TableCell>{STRING_CONSTANTS.APPROVE}</TableCell>
                          <TableCell>{STRING_CONSTANTS.REJECT}</TableCell>
                          <TableCell>{STRING_CONSTANTS.OFFER}</TableCell>
                          <TableCell>{STRING_CONSTANTS.POST_PRODUCT}</TableCell>
                          <TableCell>{STRING_CONSTANTS.ADD_BUSSINESS}</TableCell>
                          <TableCell>{STRING_CONSTANTS.REQUEST_PRODUCT}</TableCell>
                          <TableCell>{STRING_CONSTANTS.BLOCK}</TableCell>
                          <TableCell>{STRING_CONSTANTS.UNBLOCK}</TableCell>
                          <TableCell>{STRING_CONSTANTS.UPGRADE}</TableCell>
                          <TableCell>{STRING_CONSTANTS.TO_DIASPORA}</TableCell>
                          <TableCell>{STRING_CONSTANTS.TREE_LOCATION}</TableCell>
                          <TableCell>{STRING_CONSTANTS.SEARCH}</TableCell>
                          <TableCell>{STRING_CONSTANTS.DATE_WISE_SEARCH}</TableCell>
                          <TableCell>{STRING_CONSTANTS.DETAILED_INFO}</TableCell>
                          <TableCell>{STRING_CONSTANTS.SORT}</TableCell>
                          <TableCell>{STRING_CONSTANTS.IMAGE_SHOW}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {permissions.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isView") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isView}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isView"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isCreate") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isCreate}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isCreate"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isEdit") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isEdit}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isEdit"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isDelete") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isDelete}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isDelete"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isFeedback") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isFeedback}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isFeedback"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isApprove") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isApprove}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isApprove"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isReject") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isReject}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isReject"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isOffer") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isOffer}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isOffer"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isPostProduct") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isPostProduct}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isPostProduct"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isAddBusiness") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isAddBusiness}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isAddBusiness"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isRequestProduct") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isRequestProduct}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isRequestProduct"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isBlock") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isBlock}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isBlock"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isUnblock") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isUnblock}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isUnblock"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isUpgrade") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isUpgrade}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isUpgrade"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isDiaspora") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isDiaspora}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isDiaspora"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                          
                            <TableCell>
                              {item.hasOwnProperty("isTreeLocation") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isTreeLocation}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isTreeLocation"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>  <TableCell>
                              {item.hasOwnProperty("isSearch") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isSearch}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isSearch"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isDateWiseSearch") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isDateWiseSearch}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isDateWiseSearch"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                          
                            <TableCell>
                              {item.hasOwnProperty("isDetailedInfo") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isDetailedInfo}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isDetailedInfo"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isSort") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isSort}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isSort"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isShowImage") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isShowImage}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isShowImage"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Link to="/dashboard">
                      {" "}
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{ mt: 2, mr: 1 }}
                      >
                        {" "}
                        {STRING_CONSTANTS.CANCEL}{" "}
                      </Button>
                    </Link>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      color="success"
                      sx={{ mt: 2 }}
                      style={{ backgroundColor: "#769A0F" }}
                    >
                      {" "}
                      {STRING_CONSTANTS.SAVE}{" "}
                    </Button>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolePermissions;
