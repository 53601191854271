import fetch from "isomorphic-fetch";
import { API as url1 } from "../config";
import {
  ADD_VOUCHER,
  DELETE_VOUCHER,
  EDIT_VOUCHER,
  FAQ,
  VOUCHER_LIST,
 
} from "../constants/url";
import { ERR_POSTING, ERR_GETTING, ERR_ALERT_TITLE, ERR } from "../constants/alertMessage";

import Swal from "sweetalert2/dist/sweetalert2";
import Cookies from "universal-cookie";
import { privateApiCall } from "../api/privateApi";
const cookies = new Cookies();
//FAQ
export const faqList = () => {
  const requestUrl=`${FAQ}/all`
  return privateApiCall(requestUrl,'GET')
 
    .then((response) => {
      return response.data
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_GETTING}`,
            confirmButtonText: 'OK'  
          })
    });
};

export const deleteFaq = (faqId) => {
  const requestUrl = `${FAQ}/${faqId}`
  return privateApiCall(requestUrl,"DELETE",{})
    .then((response) => response.data)
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_POSTING}`,
            confirmButtonText: 'OK'  
          })
    });
};
export const addFAQ = (faq) => {
  const requestUrl = `${FAQ}/add`
 return privateApiCall(requestUrl,'POST',faq)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_POSTING}`,
            confirmButtonText: 'OK'  
          })
    });
};
export const updateFAQ = (faq, category, faqId) => {
  const requestUrl =`${FAQ}/${category}/${faqId}`
  return privateApiCall(requestUrl,'PUT',faq)
 
    .then((response) => {
      return response.data
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_POSTING}`,
            confirmButtonText: 'OK'  
          })
    });
};
//Vocher
export const voucherList = (from) => {
  
  const requestUrl =`${VOUCHER_LIST}?from=${from}`
  return privateApiCall(requestUrl,'GET')
 
    .then((response) => {
      return response.data
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_GETTING}`,
            confirmButtonText: 'OK'  
          })
    });
};
export const deleteVocher = (_id, status) => {
  
  const requestUrl = `${DELETE_VOUCHER}?id=${_id}&status=${status}`
  return privateApiCall(requestUrl,"GET")

    .then((response) => {
      return response.data
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_GETTING}`,
            confirmButtonText: 'OK'  
          })
    });
};
export const addVocher = (codes) => {
  const requestUrl = `${ADD_VOUCHER}`
  return privateApiCall(requestUrl,'POST',codes)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_POSTING}`,
            confirmButtonText: 'OK'  
          })
    });
};

export const assignVocher = (codes) => {
  const requestUrl = `/app/assignVocher`
  return privateApiCall(requestUrl,'POST',codes)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_POSTING}`,
            confirmButtonText: 'OK'  
          })
    });
};
export const editVocher = (codes, id) => {
  const requestUrl = `${EDIT_VOUCHER}?id=${id}`
  return privateApiCall(requestUrl,"POST",codes)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_POSTING}`,
            confirmButtonText: 'OK'  
          })
    });
};
