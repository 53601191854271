import { useState, useEffect } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import "react-tagsinput/react-tagsinput.css";
import { Link } from "react-router-dom";
import { GET_ROLES } from "../../constants/url";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import { ERR_GETTING } from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { toast, Bounce } from "react-toastify";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState("");

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const requestUrl = `${GET_ROLES}`;
      const response = await privateApiCall(requestUrl, "GET");
      setRoles(response.data.roles);
    } catch (error) {
      toast.error(`${ERR_GETTING}`, { transition: Bounce });
    }
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="configurations" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Roles</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Roles</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{STRING_CONSTANTS.ROLE_ID}</TableCell>
                        <TableCell>{STRING_CONSTANTS.ROLE}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {roles
                        .sort((a, b) => a.roleId - b.roleId) // Sort by roleId
                        .map((item) => (
                          <TableRow key={item.roleId}>
                            <TableCell>{item.roleId}</TableCell>
                            <TableCell>{item.name}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roles;
