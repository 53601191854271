import React from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Cookies from "universal-cookie";
import { getOrganisation } from "../../actions/adminAction";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  voucherList,
  addVocher,
  editVocher,
  deleteVocher,
  assignVocher,
} from "../../actions/settingsAction";
import { AdminSubscription } from "../../actions/subscriptionsActions";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { EDIT } from "../../constants/url";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  Modal,
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  Collapse,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableRow,
} from "@mui/material";
import { FiEdit2 } from "react-icons/fi";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import {
  ERR_ALERT_TITLE,
  ERR_GETTING,
  VOUCHER_SUCCESS,
  VOUCHER_ERROR,
  ERR,
  CONFIRM,
  ORG,
  ERR_ORG,
  ALERT_BUTTON_OK,
  ALERT_BUTTON_YES,
} from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import { formatDate } from "../../utils/dateUtils";

const cookies = new Cookies();
const Voucher = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [assignDropdownValue, setAssignDropdownValue] = useState("");
  const [voucherId, setVoucherId] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = (id) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  };

  const [subscription_id, setSubscription] = useState("");
  const [values, setValues] = useState({
    name: "",
    refCode: "",
    limit: "",
    from: dayjs().format("MM/DD/YYYY"),
    sub_id: "",
    end: dayjs().add(1, "day").format("MM/DD/YYYY"),
    error: false,
    header: "",
    success: false,
    vochers: [],
    sub: [],
    assignedTo: [],
    message: "",
    _id: "",
    type: "add",
    removed: false,
    errorMessage: "",

    org: [],
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const {
    refCode,
    limit,
    from,
    end,
    sub,
    vochers,
    name,
    success,
    type,
    _id,
    removed,
    org,
  } = values;

  const token = cookies.get("agritech_token");
  useEffect(() => {
    loadData();
    loadOrganisations();
  }, [success, removed]);

  const handleStartDateChange = (date) => {
    setValues((prevData) => ({
      ...prevData,
      from: date ? dayjs(date).format("MM/DD/YYYY") : "",
    }));
  };
  const handleEndDateChange = (date) => {
    setValues((prevData) => ({
      ...prevData,
      end: date ? dayjs(date).format("MM/DD/YYYY") : "",
    }));
  };

  const loadData = () => {
    voucherList("vocher").then((data) => {
      console.log(data, "voucher list");
      if (data.error) {
        Swal.fire({
          icon: `${ERR}`,
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_GETTING}`,
          confirmButtonText: `${CONFIRM}`,
        });
      } else {
        AdminSubscription(token).then((sub) => {
          console.log(sub, "subssss");
          if (sub.error) {
            Swal.fire({
              icon: `${ERR}`,
              title: `${ERR_ALERT_TITLE}`,
              text: `${ERR_GETTING}`,
              confirmButtonText: `${CONFIRM}`,
            });
          } else {
            const userSubscriptions = sub.filter(
              (item) => item.type === "User"
            );
            console.log(data.vocher, "vouchersss");
            const updatedVouchers = data.vocher.map((voucher) => ({
              ...voucher,
              // Here you can decide how you want to format the assigned organizations
              assignedOrganizations: Array.isArray(voucher.assignedTo)
                ? voucher.assignedTo.join(", ") // Join if more than one
                : voucher.assignedTo || "No Organization Assigned", // Handle case with no org
            }));
            setValues((prevValues) => ({
              ...prevValues,
              // vochers: data.vocher,
              vochers: updatedVouchers,
              sub: userSubscriptions,
              _id: "",
              header: "Create Vocher",
              sub_id: "",
            }));
          }
        });
      }
    });
  };

  const loadOrganisations = () => {
    getOrganisation(token, "All", "All")
      .then((data) => {
        if (data.error) {
          Swal.fire({
            title: data.error,
            icon: `${ERR}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${CONFIRM}`,
          });
        } else {
          console.log(data.org, "organisation assigned");
          setValues((prevValues) => ({
            ...prevValues,
            org: data.org,
          }));
        }
      })
      .catch((error) => {
        Swal.fire({
          title: `${ERR_GETTING}`,
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${CONFIRM}`,
        });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true, error: false });
    const chapter = { name, refCode, subscription_id, from, end, limit };

    if (type === "add") {
      addVocher(chapter).then((data) => {
        console.log(data, "dataa");
        if (data.error) {
          setValues({
            ...values,
            error: data.error,
            message: data.message,
            loading: false,
          });
          handleClose();
          Swal.fire({
            icon: "error",
            title: `${ERR_ALERT_TITLE}`,
            text: data.message,
          });
        } else {
          Swal.fire({
            title: data.message,
            text: "",
            icon: "success",
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          });
          handleClose();
          loadData();
        }
      });
    } else if (type === "edit") {
      editVocher(chapter, _id).then((data) => {
        console.log(data, "edit checkss");
        if (data.error) {
          setValues({
            ...values,
            error: data.error,
            message: data.message,
            loading: false,
          });
          handleClose();
          Swal.fire({
            icon: "error",
            title: `${ERR_ALERT_TITLE}`,
            text: data.message,
          });
        } else {
          Swal.fire({
            title: data.message,
            text: "",
            icon: "success",
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          });
          setVoucherId(null);
          handleClose();
          loadData();
        }
      });
    }
  };

  const handleChange = (name) => (e) => {
    const value = e.target.value;
    if (name === "limit" && value <= 0) {
      setValues({
        ...values,
        error: true,
        errorMessage: `${STRING_CONSTANTS.MIN_LIM}`,
      });
    } else {
      setValues({ ...values, error: false, [name]: value });
    }
  };
  function handlesubChange(e) {
    setSubscription(e.target.value);
  }

  function Status(cell, row) {
    if (row.deleteStatus) {
      return (
        <span>
          <span className="btn-sm btn-danger">{STRING_CONSTANTS.INACTIVE}</span>
        </span>
      );
    } else {
      return (
        <span>
          <span className="btn-sm btn-success">{STRING_CONSTANTS.ACTIVE}</span>
        </span>
      );
    }
  }
  const handleClearData = () => {
    setValues({
      ...values,
      name: "",
      refCode: "",
      limit: "",
      // from: "",
      // end: "",
      from: dayjs().format("MM/DD/YYYY"),
      end: dayjs().add(1, "day").format("MM/DD/YYYY"),
      sub_name: "",
      sub_id: "",
      errorMessage: "",
    });
    setShow(true);
    setVoucherId(null);
    setSubscription("");
  };

  const handleAssignButtonClick = (voucherId) => {
    setAssignModalOpen(true);
    setVoucherId(voucherId);
  };

  const handleAssignDropdownChange = (e) => {
    setAssignDropdownValue(e.target.value);
  };

  const handleAssignModalClose = () => {
    setAssignModalOpen(false);
    setAssignDropdownValue("");
  };

  const handleAssignSubmit = () => {
    const data = {
      vocher_id: voucherId,
      org_id: assignDropdownValue,
    };
    console.log(data, "assinged organisation data");
    assignVocher(data)
      .then((response) => {
        if (response.error) {
          Swal.fire({
            icon: `${ERR}`,
            title: `${ERR_ALERT_TITLE}`,
            text: response.message,
            confirmButtonText: `${CONFIRM}`,
          });
          handleAssignModalClose();
          loadData();
        } else {
          Swal.fire({
            title: `${VOUCHER_SUCCESS}`,
            text: "",
            icon: "success",
            confirmButtonText: `${CONFIRM}`,
          });
          handleAssignModalClose();
          loadData();
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: `${ERR}`,
          title: `${ERR_ALERT_TITLE}`,
          text: `${VOUCHER_ERROR}`,
          confirmButtonText: `${CONFIRM}`,
        });
      });
  };

  function Actions({ row }) {
    const handleEditClick = (e) => {
      console.log(row, "row edit data");
      setValues({
        ...values,
        error: false,
        name: row.name,
        header: "Edit Vocher",
        type: "edit",
        _id: row._id,
        sub_name: row.subscription_name,
        // sub_id: row.subscription_id,
        subscription_id: row.subscription_id,
        refCode: row.refCode,
        limit: row.limit,
        from: row.from,
        end: row.end,
      });
      setVoucherId(row._id);
      setShow(true);
      setSubscription(row.subscription_id);
    };

    const handleClick = (e) => {
      if (row.deleteStatus) {
        Swal.fire({
          title: `${STRING_CONSTANTS.RESTORE_VOUCHER} ${row.name} ?`,
          text: "",
          icon: "warning",
          allowOutsideClick: false,
          confirmButtonText: `${ALERT_BUTTON_YES}`,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            deleteVocher(row._id, false).then((data) => {
              if (data.error) {
                setValues({ ...values, error: data.error, loading: false });
              } else {
                loadData();
              }
            });
          }
        });
      } else {
        Swal.fire({
          title: `${STRING_CONSTANTS.REMOVE_VOUCHER} ${row.name} ?`,
          text: "",
          icon: "question",
          allowOutsideClick: false,
          confirmButtonText: `${ALERT_BUTTON_YES}`,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            deleteVocher(row._id, true).then((data) => {
              if (data.error) {
                setValues({ ...values, error: data.error, loading: false });
              } else {
                loadData();
              }
            });
          }
        });
      }
    };
    if (row.deleteStatus) {
      return (
        <span>
          {permissions.some(
            (item) =>
              item.keyword === "settingsVouchers" && item.isEdit === true
          ) && (
            <>
              <i className="mr-2" onClick={handleEditClick}>
                <FiEdit2
                  size="18px"
                  color="#0978F2"
                  className="waves-effect waves-light"
                  data-tooltip-id="1"
                  data-tooltip-content={`${EDIT}`}
                />
              </i>
              <Tooltip id="1" style={{ fontSize: "12px" }} />
            </>
          )}
          {permissions.some(
            (item) =>
              item.keyword === "settingsVouchers" && item.isUnblock === true
          ) && (
            <>
              <i className="ml-2" onClick={handleClick}>
                <FaRegThumbsUp
                  size="18px"
                  color="green"
                  data-tooltip-id="3"
                  data-tooltip-content="Active"
                />
              </i>
              <Tooltip id="3" style={{ fontSize: "12px" }} />
            </>
          )}
        </span>
      );
    } else {
      return (
        <span>
          {permissions.some(
            (item) =>
              item.keyword === "settingsVouchers" && item.isEdit === true
          ) && (
            <>
              <i className="mr-2" onClick={handleEditClick}>
                <FiEdit2
                  size="18px"
                  color="#0978F2"
                  className="waves-effect waves-light"
                  data-tooltip-id="1"
                  data-tooltip-content={`${EDIT}`}
                />
              </i>
              <Tooltip id="1" style={{ fontSize: "12px" }} />
            </>
          )}

          {permissions.some(
            (item) =>
              item.keyword === "settingsVouchers" && item.isBlock === true
          ) && (
            <>
              <i className="ml-2" onClick={handleClick}>
                <FaRegThumbsDown
                  size="18px"
                  color="red"
                  data-tooltip-id="2"
                  data-tooltip-content="InActive"
                />
              </i>
              <Tooltip id="2" style={{ fontSize: "12px" }} />
            </>
          )}
        </span>
      );
    }
  }

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="settings" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.SETTINGS}
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.VOUCHER}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.VOUCHER}</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div style={{ width: "98%" }}>
                    {permissions.some(
                      (item) =>
                        item.keyword === "settingsVouchers" &&
                        item.isCreate === true
                    ) && (
                      <>
                        <button
                          className="btn btn-icon waves-effect waves-light btn-primary mb-3"
                          onClick={(e) => handleClearData()}
                        >
                          {" "}
                          <i className="fa fa-plus"></i>{" "}
                          {STRING_CONSTANTS.ADD_VOUCHER}
                        </button>
                      </>
                    )}

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{STRING_CONSTANTS.SN_NO}</TableCell>
                            <TableCell>{STRING_CONSTANTS.NAME}</TableCell>
                            <TableCell>
                              {STRING_CONSTANTS.SUBSCRIPTION}
                            </TableCell>
                            <TableCell>{STRING_CONSTANTS.USER_LIMIT}</TableCell>
                            {/* <TableCell>{STRING_CONSTANTS.START_DATE}</TableCell> */}
                            {/* <TableCell>{STRING_CONSTANTS.END_DATE}</TableCell> */}
                            <TableCell>{STRING_CONSTANTS.STATUS}</TableCell>
                            <TableCell>{STRING_CONSTANTS.ASSIGN_TO}</TableCell>
                            <TableCell>{STRING_CONSTANTS.ACTIONS}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {vochers
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((voucher, index) => (
                              <TableRow
                                key={voucher._id}
                                onClick={() =>
                                  console.log(voucher, "voucher data..")
                                }
                              >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{voucher.name}</TableCell>
                                <TableCell>
                                  {voucher.subscription_name}
                                </TableCell>
                                <TableCell>{voucher.limit}</TableCell>
                                {/* <TableCell>{formatDate(voucher.from)}</TableCell> */}
                                {/* <TableCell>{formatDate(voucher.end)}</TableCell> */}
                                <TableCell>
                                  {Status(null, {
                                    deleteStatus: voucher.deleteStatus,
                                  })}
                                </TableCell>
                                <TableCell>
                                  {(() => {
                                    if (Array.isArray(voucher.assignedTo)) {
                                      return (
                                        <>
                                          <IconButton
                                            onClick={() =>
                                              handleExpandClick(voucher._id)
                                            }
                                          >
                                            {expanded ? (
                                              <ExpandLessIcon />
                                            ) : (
                                              <ExpandMoreIcon />
                                            )}
                                          </IconButton>
                                          <span>{voucher.assignedTo[0]}</span>{" "}
                                          {/* Display the first organization */}
                                          <Collapse
                                            in={expanded[voucher._id]}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <List>
                                              {voucher.assignedTo
                                                .slice(1)
                                                .map((org, index) => (
                                                  <ListItem key={index}>
                                                    {org}
                                                  </ListItem>
                                                ))}
                                            </List>
                                          </Collapse>
                                        </>
                                      );
                                    } else {
                                      return (
                                        voucher.assignedTo ||
                                        "No Organizations Assigned"
                                      );
                                    }
                                  })()}
                                </TableCell>
                                <TableCell>
                                  <Actions row={voucher} />
                                  {permissions.some(
                                    (item) =>
                                      item.keyword === "settingsVouchers" &&
                                      item.isCreate === true
                                  ) && (
                                    <>
                                      <Button
                                        sx={{
                                          backgroundColor: "#769A0F",
                                          color: "#ffffff",
                                          border: "1px solid #769A0F",
                                          borderRadius: "4px",
                                          padding: "4px 8px",
                                          marginLeft: "20px",
                                          cursor: "pointer",
                                          textTransform: "none",
                                          "&:hover": {
                                            backgroundColor: "#769A0F",
                                          },
                                        }}
                                        aria-controls="assign-menu"
                                        aria-haspopup="true"
                                        onClick={() =>
                                          handleAssignButtonClick(voucher?._id)
                                        }
                                      >
                                        {STRING_CONSTANTS.ASSIGN}
                                      </Button>
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 50, 100]}
                      component="div"
                      count={vochers.length}
                      rowsPerPage={rowsPerPage || 0}
                      page={page || 0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="add-voucher-modal-title"
        aria-describedby="add-voucher-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "100px",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "900px",
            height: "auto",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: 24,
            outline: "none",
          }}
        >
          <h3>{voucherId ? "Edit Voucher" : "Add Voucher"}</h3>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Name"
                  value={name}
                  placeholder={STRING_CONSTANTS.NAME_PLACEHOLDER}
                  onChange={handleChange("name")}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth required>
                  <InputLabel>{STRING_CONSTANTS.SUBSCRIPTION}</InputLabel>
                  <Select
                    label="Subscription"
                    value={subscription_id}
                    onChange={handlesubChange}
                  >
                    {console.log(subscription_id, sub)}
                    {sub.map((item) => (
                      <MenuItem key={item._id} value={item.subscription_id}>
                        {item.name} {item.validity} {STRING_CONSTANTS.DAYS}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="User Limit"
                  type="number"
                  value={limit}
                  placeholder={STRING_CONSTANTS.ENTER_USER_LIMIT}
                  onChange={handleChange("limit")}
                  required
                  fullWidth
                  error={values.error}
                  helperText={values.error ? values.errorMessage : ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Valid Start Date"
                      format="DD/MM/YYYY"
                      defaultValue={dayjs(from)}
                      name="from"
                      onChange={handleStartDateChange}
                      required
                      minDate={dayjs()}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Valid End Date"
                      format="DD/MM/YYYY"
                      defaultValue={dayjs(end)}
                      name="end"
                      onChange={handleEndDateChange}
                      required
                      minDate={dayjs(from).add(1, "day")}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ marginTop: "20px" }}
            >
              <Grid item>
                <Button variant="outlined" color="error" onClick={handleClose}>
                  {STRING_CONSTANTS.CLOSE}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    backgroundColor: "#769A0F",
                    color: "#fff",
                  }}
                >
                  {voucherId ? "Update" : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>

      <Modal
        open={assignModalOpen}
        onClose={handleAssignModalClose}
        aria-labelledby="assign-modal-title"
        aria-describedby="assign-modal-description"
        BackdropProps={{
          sx: { backgroundColor: "transparent" },
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "500px",
            height: "auto",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: 24,
            outline: "none",
          }}
        >
          <h3>{STRING_CONSTANTS.ASSIGN_VOUCHER}</h3>
          <FormControl fullWidth>
            <InputLabel>{STRING_CONSTANTS.ASSIGN_TO}</InputLabel>
            <Select
              label="Assign To"
              value={assignDropdownValue}
              onChange={handleAssignDropdownChange}
            >
              {org.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Grid
            container
            justifyContent="flex-end"
            style={{ marginTop: "20px" }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleAssignModalClose}
              style={{ marginRight: "10px" }}
            >
              {STRING_CONSTANTS.CLOSE}
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "#769A0F", color: "#fff" }}
              // Add onClick function to handle assignment
              onClick={handleAssignSubmit}
            >
              {STRING_CONSTANTS.ASSIGN}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default Voucher;
