import React from 'react';
import { Modal, Box, Grid, Typography, Button, Table, TableRow, TableCell } from '@mui/material';
import { formatDate } from '../../../utils/dateUtils';
const SellViewModal = ({ open, handleClose, sellDetails,STRING_CONSTANTS}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80vw",
          maxHeight: "80vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
        }}
      >
        <Grid container justifyContent="center" alignItems="center" className="modal-content">
          <Grid item xs={12} className="modal-header">
            <Typography id="modal-title" variant="h6" component="h2">
              {STRING_CONSTANTS.DETAILS}
            </Typography>
            <Button onClick={handleClose} color="inherit">
              <span style={{ fontSize: "20px" }} aria-hidden="true">
                &#128473;
              </span>
            </Button>
          </Grid>
          <Grid item xs={12} className="modal-body">
            <Table className="col-12">
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.TITLE}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {sellDetails.title}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.DESCRIPTION}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {sellDetails.description}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.PRICE}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {sellDetails.price}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.EXPIRY_DATE}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {formatDate(sellDetails.expDate)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.AVAIL_DATE}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {formatDate(sellDetails.availableDate)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.LOCATION}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {sellDetails.location}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.LATITUDE}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {sellDetails.latitude}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.LONGITUDE}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {sellDetails.longitude}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.KEYWORDS}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {sellDetails.keywords
                    ? sellDetails.keywords.join(", ")
                    : `${STRING_CONSTANTS.NO_KEYWORDS}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.UNIT}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {sellDetails.unit}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.QUANTITY}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {sellDetails.qty}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.SHIP_CMT}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {sellDetails.shipment_comment}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.SHIP_DATE}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {formatDate(sellDetails.shipped_date)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">
                  {STRING_CONSTANTS.FEEDBACK_LABEL}
                </TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {sellDetails.feedback}
                </TableCell>
              </TableRow>
            </Table>
          </Grid>
          <Grid item xs={12} className="modal-footer">
            <Button onClick={handleClose} variant="outlined" color="error">
              {STRING_CONSTANTS.CLOSE}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default SellViewModal;
