import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Cookies from "universal-cookie";
import { CATEGORY, DASHBOARD, ADD_CATEGORY } from "../../constants/url";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
const cookies = new Cookies();

class Addcategory extends Component {
  constructor() {
    super();
    window.postMessage("renderTable", "*");
    this.state = {
      name: "",
      image: "",
      data: [],
      description: "",
      color: "",
    };

    this.handlenameChange = this.handlenameChange.bind(this);
    this.update_image = this.update_image.bind(this);
    this.handledescriptionChange = this.handledescriptionChange.bind(this);
    this.color = this.color.bind(this);
  }

  update_image(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      this.setState({ image: "http://placehold.it/60/c2c2c2?text=User" });
    }
  }

  handlenameChange(event) {
    this.setState({ name: event.target.value });
  }

  handledescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  color(event) {
    this.setState({ color: event.target.value });
  }

  render() {
    return (
      <div id="wrapper">
        <Topbar backgroundColor="#769A0F" />
        <Sidebar />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title float-left">{STRING_CONSTANTS.ADD_CATEGORY}</h4>

                    <ol className="breadcrumb float-right">
                      <li className="breadcrumb-item">
                        {" "}
                        <NavLink to={DASHBOARD}>{STRING_CONSTANTS.DASHBOARD}</NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        {" "}
                        <NavLink to={CATEGORY}>{STRING_CONSTANTS.CATEGORY}</NavLink>
                      </li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.ADD_CATEGORY}</li>
                    </ol>

                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="card-box" style={{ paddingBottom: "50px" }}>
                    <form
                      method="post"
                      enctype="multipart/form-data"
                      action={`${ADD_CATEGORY}/?token=${cookies.get(
                        "agritech_token"
                      )}`}
                    >
                      <div className="form-group">
                        <label>{STRING_CONSTANTS.CATEGORY_NAME}</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter name"
                          name="name"
                          onChange={this.handlenameChange}
                          required
                        />
                      </div>

                      <div className="row">
                        <label for="exampleInputEmail1">
                          {STRING_CONSTANTS.CATEGORY_IMAGE}<sup className="color-danger">*</sup>
                        </label>
                        <div className="form-group">
                          <img
                            className="img-responsive"
                            src={this.state.image}
                            alt="user"
                            style={{ width: "150px", height: "150px" }}
                          />
                          <br />
                          <input
                            type="file"
                            className="form-control"
                            id="image"
                            name="image"
                            onChange={this.update_image}
                            placeholder=""
                          />
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ float: "left", backgroundColor: "#11abff" }}
                      >
                        {STRING_CONSTANTS.SUBMIT}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Addcategory;
