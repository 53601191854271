import Topbar from "../topBar";
import Sidebar from "../sideBar";
import React from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { useState, useEffect } from "react";
import {
  faqList,
  deleteFaq,
  addFAQ,
  updateFAQ,
} from "../../actions/settingsAction";
import { Link } from "react-router-dom";
import { FiTrash2, FiEdit2 } from "react-icons/fi";

import { Button, TextField, Grid, Modal, Fade } from "@mui/material";
import { Tooltip } from "react-tooltip";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
} from "@mui/material";
import { DELETE, EDIT } from "../../constants/url";
import { ALERT_BUTTON_OK, DELETE_QUESTION, DELETE_YES_BUTTON, ERR_ALERT_TITLE } from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const FAQ = () => {
  const [data, setData] = useState({
    category: "",
    faqs: [],
  });
  const { faqs } = data;

  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [show, setShow] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [faqId, setFaqId] = useState(null);
  const [page,setPage]=useState(0)
  const [rowsPerPage,setRowsPerPage]=useState(10)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    loadData();
  }, []);

  const addSerialNumbers = (array) => {
    if (array) {
      return array.map((item, index) => ({
        ...item,
        sn: index + 1,
      }));
    } else {
      return [];
    }
  };

  const loadData = () => {
    faqList().then((response) => {
      if (!response.status) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          text: response.msg,
          icon: "error",
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      } else {
        let newArrayWithSerialNumbers = addSerialNumbers(
          response.data.faqs.faq
        );
        newArrayWithSerialNumbers.reverse();
        setData({
          category: response.data.faqs.category,
          faqs: newArrayWithSerialNumbers,
        });
      }
    });
  };

  const callAlert = (type, response) => {
    let title;
    let icon;
    if (type === "success") {
      title = `${STRING_CONSTANTS.FAQ_UPDATED}`;
      icon = "success";
    } else if (type === "add") {
      title = `${STRING_CONSTANTS.FAQ_ADDED}`;
      icon = "success";
    } else {
      title = response.msg;
      icon = "error";
    }

    Swal.fire({
      title,
      text: response.msg,
      icon,
      confirmButtonText: `${ALERT_BUTTON_OK}`,
      showCancelButton: false,
    });

    loadData();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = { question, answer };
    if (faqId) {
      updateFAQ(body, "all", faqId).then((response) => {
        if (!response.status) {
          callAlert("error", response);
        } else {
          callAlert("success", response);
        }
      });
    } else {
      addFAQ(body).then((response) => {
        if (!response.status) {
          callAlert("error", response);
        } else {
          callAlert("add", response);
        }
      });
    }

    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClearData = () => {
    setAnswer("");
    setQuestion("");
    setShow(true);
    setFaqId(null);
  };

  const Actions = ({ faq }) => {
    const handleEditClick = () => {
      setQuestion(faq.question);
      setAnswer(faq.answer);
      setFaqId(faq._id);
      setShow(true);
    };

    const handleClick = () => {
      Swal.fire({
        title: `${DELETE_QUESTION} ${faq.question} ? `,
        text: "",
        icon: "warning",
        allowOutsideClick: false,
        confirmButtonColor: "#d33",
        cancelButtonColor: " #3085d6",
        confirmButtonText: `${DELETE_YES_BUTTON}`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          deleteFaq(faq._id).then((response) => {
            if (!response.status) {
              callAlert("error", response);
            } else {
              loadData();
              callAlert("success", response);
            }
          });
        }
      });
    };

    return (
      <span>
          {permissions.some(
          (item) => item.keyword === "settingsFaq" && item.isEdit === true
        ) && (
          <i
          className="mr-2 cursor-pointer"
          data-tooltip-id="1"
          data-tooltip-content={`${EDIT}`}
          onClick={handleEditClick}
        >
          <FiEdit2 size="18px" color="#0978F2" />
        </i>
        )}
         {permissions.some(
          (item) => item.keyword === "settingsFaq" && item.isDelete === true
        ) && (
          <i
          className="ml-2 cursor-pointer"
          data-tooltip-id="2"
          data-tooltip-content={`${DELETE}`}
          onClick={handleClick}
        >
          <FiTrash2 size="18px" color="red" />
        </i>
        )}
        
       
        <Tooltip id="1" />
        <Tooltip id="2" />
      </span>
    );
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="settings" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                      </li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.SETTINGS}</li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.FAQ}</li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.FAQ}</h4>
                </div>
              </div>
            </div>
            <Modal
              open={show}
              onClose={handleClose}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Fade in={show}>
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "20px",
                    width: "60%",
                    margin: "auto",
                  }}
                >
                  <h3>{faqId ? "Edit FAQ" : "Add FAQ"}</h3>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={2}
                          label="Question"
                          variant="outlined"
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                          placeholder={STRING_CONSTANTS.ENTER_QUESTION_PLACEHOLDER}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={5}
                          label="Answer"
                          variant="outlined"
                          value={answer}
                          onChange={(e) => setAnswer(e.target.value)}
                          placeholder={STRING_CONSTANTS.ANSWER}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="flex-end"
                      style={{ marginTop: "20px" }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        style={{ marginRight: "10px" }}
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: "#769A0F" }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </form>
                </div>
              </Fade>
            </Modal>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">

                {permissions.some(
          (item) => item.keyword === "settingsFaq" && item.isCreate === true
        ) && (
          <>
          <button
                    className="btn btn-icon waves-effect waves-light btn-primary mb-3"
                    onClick={handleClearData}
                  >
                    {" "}
                    <i className="fa fa-plus"></i> Add FAQ
                  </button>
          </>
        )}
                  
                  <div style={{ width: "98%" }}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.SN_NO}
                            </TableCell>
                            <TableCell width="180px" align="left">
                              {STRING_CONSTANTS.QUESTION}
                            </TableCell>
                            <TableCell width="180px" align="left">
                              {STRING_CONSTANTS.ANSWER}
                            </TableCell>
                            <TableCell width="80px" align="left">
                              {STRING_CONSTANTS.ACTIONS}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {faqs.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            ).map((faq, index) => (
                            <TableRow key={faq._id}>
                              <TableCell width="80px" align="center">
                              {page * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell width="180px" align="left">
                                {faq.question}
                              </TableCell>
                              <TableCell width="180px" align="left">
                                {faq.answer}
                              </TableCell>
                              <TableCell width="80px" align="left">
                                <Actions faq={faq} />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={faqs.length}
                        rowsPerPage={rowsPerPage||0}
                        page={page||0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
