// Chat.js
import React,{useState} from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import { Link } from "react-router-dom";
import { Grid, Paper } from "@mui/material";
import ChatList from "./components/ChatList";
import ChatBox from "./components/chatBox";
import Contact from './components/contact'

const Chat = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [contactInfo, setContactInfo] = useState(null);
  const [productInfo,setProductInfo]=useState(null)

  // Function to handle showing contact information
  const handleShowContactInfo = (contact) => {
    console.log(contact,"contact selected ")
    setContactInfo(contact);
    setProductInfo(selectedChat.product)
  };
  console.log(selectedChat,"selcted dta")
  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">Chat</h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Chat</li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <Grid container spacing={2} sx={{ height: "100vh", padding: 2 }}>
              {/* Sidebar */}
              <Grid item xs={4}>
                 
              <ChatList selectedChatt={selectedChat} onSelectChat={(chat) =>{setContactInfo(null); setSelectedChat(chat)}} />
                
              </Grid>

              {/* Chat Box */}
              <Grid item xs={5}>
                <Paper
                  elevation={3}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ChatBox selectedChate={selectedChat} onShowContactInfo={handleShowContactInfo}/>
                </Paper>
              </Grid>
              {/* contact information */}
              <Grid item xs={3}>
                {contactInfo && <Contact selectedChat={contactInfo}  selectedProduct={productInfo} />}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
