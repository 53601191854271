const BASE_URL = '/app';
const BASE_URL_OLD = '/App';
const API_URL = '/api';
// const ORG_URL = '/org'
// general 
export const EDIT = 'Edit'
export const DELETE = 'Delete'
export const BLOCK = 'Block'
export const UNBLOCK = 'Unblock'
export const ADD_BUS = 'Add Business'
export const REQ_PDT = 'Request Product'
export const POST_PDT = 'Post Product'
export const VIEW_DET = 'View Details'
export const VIEW_BIDS = 'View Bids'
export const VIEW_OFFERS='View Offers'
export const VIEW_IMG = 'View Image'
export const TREE_LOC = 'Tree Location'
export const FEED = 'Feedback'
export const REJECT = 'Reject'
export const APPROVE = 'Approve'
export const ACTIVE = 'Enable'
export const DEACT = 'Disable'
export const DEFAULT='default'
export const LONG='long'
export const NUMERIC='numeric'
// constants.js
export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

export const STAGES=["Primary Tender", "Tender", "Green Jackfruit", "Matured"];
  

// /app
export const ADMIN_ADD_USER_URL = `${BASE_URL}/admin_add_user`;
export const UPDATE_EMAIL_URL = `${BASE_URL}/updateEmail`;
export const CUSTOMER_PATH = `${BASE_URL}/customer`;
export const SEARCH_CUSTOMER = `${BASE_URL}/customerSearch`;
export const SEARCH_ORDERS=`${BASE_URL}/orderSearch`;
export const ENABLE_OFFER_PATH = `${BASE_URL}/enableOffer`;
export const ADMIN_SELL_PRODUCT_ADD_PATH = `${BASE_URL}/admin_sell_product_add`;
export const ADMIN_ADD_BUSINESS_PROFILE_PATH = `${BASE_URL}/admin_add_bussinessProfile`;
export const ADMIN_REQUEST_PRODUCT_ADD_PATH = `${BASE_URL}/admin_request_product_add`;
export const ADD_COINS_PATH = '/addCoins';
export const COINS_LIST = '/coinsList';;
export const DELETE_COINS = '/deletecoins';
export const EDIT_COINS = `/editcoins`
export const UPDATE_COINS = `/updateCoins`
export const BLOCK_USER_PATH = `${BASE_URL}/block_user`;
export const UNBLOCK_USER_PATH = `${BASE_URL}/unblock_user`
export const GET_PROFILE_PATH = `${BASE_URL}/get_profile`
export const HANDLE_ORG_REQ = `${BASE_URL}/handleOrgRequest`

export const EDIT_PROFILE_PATH = `${BASE_URL}/edit_profile`
export const EDIT_IMAGE_PATH = `${BASE_URL}/edit_image`
export const LOGIN_PATH = `${BASE_URL_OLD}/login`;
export const MFAVERIFY_PATH = `${BASE_URL_OLD}/mfaverify`;
export const UPDATE_MFA = `${BASE_URL_OLD}/updatemfastatus`;
export const GET_EMAIL_MOBILE_PATH = `${BASE_URL}/get_emailmobile`;
export const DASHBOARD = `${BASE_URL}/dashboard`
export const CATEGORY = `${BASE_URL}/category`
export const ADD_CATEGORY = `${BASE_URL}/addcategory`
export const EDIT_CATEGORY = `${BASE_URL}/edit_category`
export const SEARCH_BTYPE = `${BASE_URL}/search_btypeList`
export const ADD_BYTYPE = `${BASE_URL}/addbtype`
export const EDIT_BYTPE = `${BASE_URL}/editbtype`
export const DEL_BYTPE = `${BASE_URL}/deletebtype`
export const BYTPE_LIST = `${BASE_URL}/btypeList`
export const BPROFILE_PEN = `${BASE_URL}/bProfilePendingList`
export const BPROFILE_APP = `${BASE_URL}/bProfileApprovedList`
export const BPROFILE_REJ = `${BASE_URL}/bProfileRejectedList`
export const REJ_BPROFILE = `${BASE_URL}/rejectbprofile`
export const APP_BPROFILE = `${BASE_URL}/approvebprofile`
export const APP_ALL_BPROFILE = `${BASE_URL}/approveAllBprofile`
export const REJ_ALL_BPROFILE = `${BASE_URL}/rejectAllBprofile`
export const REPLAY_BPROFILE = `${BASE_URL}/replayToBProfile`
export const SEARCH_BPROFILE_PEN = `${BASE_URL}/search_bProfilePendingList`
export const SEARCH_BPROFILE_APR = `${BASE_URL}/search_bProfileApprovedList`
export const SEARCH_BPROFILE_REJ = `${BASE_URL}/search_bProfileRejectedList`

// call Support
export const GET_CALL_SUPPORT = `${BASE_URL}/getCallSupportTeam`
export const CREATE_CALL_SUPPORT = `${BASE_URL}/createCallSupportTeam`
export const EDIT_CALL_SUPPORT = `${BASE_URL}/editCallSupportTeam`
export const UPDATE_CALL_SUPPORT = `${BASE_URL}/updateCallSupportTeamStatus`

// api for buy product
export const BUY_PRODUCT = `${API_URL}/buyproduct`;

export const SELL_PRODUCT = `${API_URL}/sellproduct`;

// employee
export const ADD_EMPLOYEE = `${API_URL}/organisation`;

export const FAQ = `${API_URL}/faq`

export const BUY_PRODUCT_DETAILS = `${BASE_URL}/buy_product_details`;
export const SELL_PRODUCT_DETAILS = `${BASE_URL}/sell_product_details`;

// crop
export const EDIT_CROP = `${BASE_URL}/editCrop`
export const ADD_CROP = `${BASE_URL}/addCrop`

// subscription
export const OFFER = `${BASE_URL}/offer`;
export const UPDATE_OFFER = `${BASE_URL}/update_offer_price`
export const PREMIUM = `${BASE_URL}/premium`;
export const UPDATE_PREMIUM = `${BASE_URL}/update_premium_price`
export const STANDARD = `${BASE_URL}/standard`;
export const UPDATE_STANDARD = `${BASE_URL}/update_standard_price`
export const SUBSCRIPTION_LIST = '/subscriptions';
export const UPGRADE_SUBSCRIPTION = '/upgradeSubscription';
export const USER_SUBSCRIPTION = '/getuserSubscription';
export const ORG_SUBSCRIPTION = '/getorgSubscription';

export const EDIT_SUBSCRIPTION = '/editsubscription';
export const UPDATE_SUBSCRIPTION = '/updateSubscription';
export const CUSTOMERS = '/customers';
export const CUSTOMER_SUB_SEARCH = '/search_customers';

// organisation

// userType
export const USERTYPE = `${BASE_URL}/userType`
export const SEARCH_USERTYPE = `${BASE_URL}/search_userType`
export const ADDUSER_TYPE = `${BASE_URL}/addUserType`
export const EDITUSER_TYPE = `${BASE_URL}/editUserType`
export const DELETE_USERYTPE = `${BASE_URL}/deleteUserType`
export const INTRESTED_CROP = `${BASE_URL}/intrestedCrops`
export const DELETE_CROP = `${BASE_URL}/deleteCrop`
export const ORG_SEARCH_SURVEY = `${API_URL}/organisation/search_survey`

// Andriod
export const ANDRIOD = `${BASE_URL}/android_version`
export const UPDATE_ANDRIOD = `${BASE_URL}/update_android_version`

// count
export const DASHBOARD_COUNT = `${BASE_URL}/getdashboardCount`
export const BUSINESS_PROFILE_COUNT = `${BASE_URL}/bussinessProfileCount`
export const SELLING_PRODUCT_COUNT = `${BASE_URL}/sellingProductCounts`
export const BUYING_PRODUCT_COUNT = `${BASE_URL}/buyingProductsCount`
export const SELLING_COUNT = `${BASE_URL}/sellingCount`
export const BUYING_COUNT = `${BASE_URL}/buyingCount`
export const KEYWORD_COUNT = `${BASE_URL}/getKeywordCount`
export const SURVEYS_COUNT = `${BASE_URL}/getSurveyCount`

export const REJECT_PRODUCT = `${BASE_URL}/rejectProduct`;
export const REPLAY_PRODUCT = `${BASE_URL}/replayToSProduct`
export const APPROVED_LIST = `${BASE_URL}/approvedlist`

// survey 
export const SURVEY = '/survey'
export const SURVEY_DELETE = `/surveyDelete`
export const SURVEY_SEARCH = `/surveySearch`
export const GET_ORG_FILTER = `${BASE_URL}/getOrgFilters`
export const GET_ORG = `${BASE_URL}/getOrganisation`
export const ADD_ORG = `${BASE_URL}/addOrganisation`
export const EDIT_ORG = `${BASE_URL}/editOrganisation`
export const DELETE_ORG = `${BASE_URL}/deleteOrganisation`
export const ADD_ORG_MEM = `${BASE_URL}/addOrganisationMember`
export const EDIT_ORG_MEM = `${BASE_URL}/editOrganisationMember`
export const GET_ORG_MEMBER = `${BASE_URL}/getOrganisationMember`
export const DELETE_ORG_MEM = `${BASE_URL}/deleteOrganisationMember`
export const APP_ORG_MEM = `${BASE_URL}/approveOrganisationMember`

// orders
export const GET_ALL_ORDERS = `${BASE_URL}/getAllOrders`

//Payments
export const GET_ALL_PAYMENTS = `${API_URL}/payments/getAllPayments`
//
export const GET_SELLER_INVOICES = `${BASE_URL}/sellerInvoices`
export const GET_SUPPLIER_INVOICES = `${BASE_URL}/supplierInvoices`


export const MY_SELL_PRODUCT_DETAILS = `${BASE_URL}/my_sell_product_details`

// organisation
export const SEARCH_ORG = `/orgSearch`
export const SEARCH_ORG_VTR = `/orgVolunteerSearch`
export const EDIT_CHILD_ORG = `${API_URL}/organisation/editChildOrganisation`
export const ADD_CHILD_ORG = `${API_URL}/organisation/addChildOrganisation`
export const SEARCH_CHILD_ORG=`${API_URL}/organisation/searchChildOrganisation`


// export const GET_ORG_PROFILE = `${ORG_URL}/get_profile`
export const GET_ORG_PROFILE = `${API_URL}/organisation/get_profile`
export const ORG_SURVEY = `${API_URL}/organisation/survey`
export const ORG_CUSTOMER = `${API_URL}/organisation/customer`
export const DATE_SEARCH_CUSTOMER = `${API_URL}/organisation/dateSearchOrgCustomers`
export const SEARCH_CUSTOMER_ORG = `${API_URL}/organisation/searchOrgCustomers`
export const ORG_SEARCH_CUSTOMER = `${API_URL}/organisation/search_customer`
export const ORG_HANDLE_REQ = `${API_URL}/organisation/handleOrgRequest`
export const CUSTOMER_COUNT = `${API_URL}/organisation/customerCount`
export const SURVEY_COUNT = `${API_URL}/organisation/surveyCount`
export const GET_VOUCHER = `${API_URL}/organisation/getMyVocher`
export const AGENT_COUNT = `${API_URL}/organisation/agentsCount`
export const GET_MEMBER_WITH = `${API_URL}/organisation/getOrganisationMember`
export const DIS_BUY = `${API_URL}/organisation/blockbuyproduct`
export const IN_BUY = `${API_URL}/organisation/unblockbuyproduct`
export const DIS_SELL = `${API_URL}/organisation/blocksellproduct`
export const IN_SELL = `${API_URL}/organisation/unblocksellproduct`
// export const ADD_ORG_MEMBER = `${API_URL}/organisation/addOrganisationMember`
// export const EDIT_ORG_MEMBER = `${API_URL}/organisation/editOrganisationMember`
// export const DELETE_ORG_MEMBER = `${API_URL}/organisation/deleteOrganisationMember`
// export const APPROVE_ORG = `${API_URL}/organisation/approveOrganisationMember`
export const SEARCH_ORG_MEMBER = `${API_URL}/organisation/searchOrganisationMember`
export const ADD_MORE_DETAILS = `${API_URL}/organisation/addmoredetails`

// voucher
export const ASSIGN_VOUCHER = `${BASE_URL}/assignVocher`
export const VOUCHER_LIST = `${BASE_URL}/vocherList`
export const DELETE_VOUCHER = `${BASE_URL}/deleteVocher`
export const ADD_VOUCHER = `${BASE_URL}/addVocher`
export const EDIT_VOUCHER = `${BASE_URL}/editVocher`

// subscription
// export const ADMIN_SUB = `${BASE_URL}/AdminSubscription`
export const ADMIN_SUB = '/getpaidSubscriptions'
export const UPDATE_SUB = `${BASE_URL}/updateSubcrption`

export const FREE = `${BASE_URL}/free`

export const MY_ACCOUNT = 'My Account'
export const ADDRESS = "address"
export const STATUTORY = "statutory"
export const BANK = "bank"

// Language
export const LANGUAGE = `${API_URL}/language`
export const KEYWORDS = `${API_URL}/keyword`

//Configurations
export const GET_ROLES = `${API_URL}/configurations/getRoles`
export const GET_SUBSCRIPTION = `${API_URL}/configurations/getSubscription`
export const GET_ROLEBYID = `${API_URL}/configurations/getRoleById`
export const GET_SUBBYID = `${API_URL}/configurations/getSubById`
export const GET_PERMISSIONS = `${API_URL}/configurations/getPermissions`
export const GET_MOBILE_PERMISSIONS = `${API_URL}/configurations/getMobilePermissions`
export const EXPORT_MOBILE_PERMISSIONS = `${API_URL}/configurations/exportSubscriptionPermissions`
export const IMPORT_MOBILE_PERMISSIONS = `${API_URL}/configurations/importSubscriptionPermissions`
export const EXPORT_ROLE_PERMISSIONS = `${API_URL}/configurations/exportRolePermissions`
export const IMPORT_ROLE_PERMISSIONS = `${API_URL}/configurations/importRolePermissions`
export const MAP_PERMISSIONS = `${API_URL}/configurations/mapRolePermissions`
export const MAP_MOB_PERMISSIONS = `${API_URL}/configurations/mapSubsciptionPermissions`
export const GET_PORTAL_USERS = `${API_URL}/configurations/getPortalUsers`
export const SAVE_PORTAL_USER = `${API_URL}/configurations/savePortalUser`
export const UPDATE_PORTAL_USER = `${API_URL}/configurations/updatePortalUser`
export const UPDATE_PORTAL_USER_STATUS = `${API_URL}/configurations/updatePortalUserStatus`

// subscription 
export const ADD_RECIPIENT=`${API_URL}/`

// harvest
