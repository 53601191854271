import React,{useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"; // Adjust the import path for your UI library
import TabPanel from "@mui/lab/TabPanel"; // Adjust the import path for TabPanel if needed

const PaymentDetails = ({
  value,
  paymentDetails,
  formatDate,
  STRING_CONSTANTS,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handlePageChange = (e, newPage) => {
      setPage(newPage);
    };
    const handleRowPageChange = (e) => {
      setRowsPerPage(+e.target.value);
      setPage(0);
    };
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
    const handleSearch = (event) => {
      setSearchTerm(event.target.value);
      setPage(0);
    };
    const filteredDetails = paymentDetails.filter((detail) => {
      return (
        detail.payment_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        detail.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        detail.mobile_no.toLowerCase().includes(searchTerm.toLowerCase()) ||
        detail.amount.toString().includes(searchTerm) ||
        detail.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        formatDate(detail.time).includes(searchTerm) ||
        detail.payment_status.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  return (
    <TabPanel value={value}>
      <div style={{ position: "relative", padding: "10px" }}>
        <div
          style={{
            position: "absolute",
            top: "2px",
            right: "10px",
            width: "200px",
          }}
        >
          {(permissions === "All" ||
            permissions.some(
              (item) => item.keyword === "orgProducts" && item.isSearch
            )) && (
            <>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                  className="form-control"
                  style={{
                    width: "100%",
                    paddingLeft: "35px",
                    boxSizing: "border-box",
                  }}
                  value={searchTerm}
                  onChange={handleSearch} 
                />
                <i
                  className="fas fa-search"
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#aaa",
                  }}
                ></i>
              </div>
            </>
          )}
        </div>

        <TableContainer style={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{STRING_CONSTANTS.SN_NO}</TableCell>
                <TableCell>{STRING_CONSTANTS.PAYMENT_ID}</TableCell>
                <TableCell>{STRING_CONSTANTS.NAME}</TableCell>
                <TableCell>{STRING_CONSTANTS.CONTACT_NO}</TableCell>
                <TableCell>{STRING_CONSTANTS.AMOUNT}</TableCell>
                <TableCell>{STRING_CONSTANTS.P_TYPE}</TableCell>
                <TableCell>{STRING_CONSTANTS.DATE}</TableCell>
                <TableCell>{STRING_CONSTANTS.STATUS}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDetails.length > 0 ? (
                filteredDetails.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((detail, index) => (
                  <TableRow key={index}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{detail.payment_id}</TableCell>
                    <TableCell>{detail.name}</TableCell>
                    <TableCell>{detail.mobile_no}</TableCell>
                    <TableCell>{detail.amount}</TableCell>
                    <TableCell>{detail.type}</TableCell>
                    <TableCell>{formatDate(detail.time)}</TableCell>
                    <TableCell>{detail.payment_status}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: "center" }}>
                    {STRING_CONSTANTS.NO_DATA_FOUND}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
                  rowsPerPageOptions={[10, 50, 100]}
                  page={page||0}
                  count={filteredDetails.length}
                  rowsPerPage={rowsPerPage||0}
                  component="div"
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowPageChange}
                ></TablePagination>
      </div>
    </TabPanel>
  );
};

export default PaymentDetails;
