import fetch from "isomorphic-fetch";
import { API as url1 } from "../config";

import Cookies from "universal-cookie";
import {
  APP_ALL_BPROFILE,
  REJ_ALL_BPROFILE,
  REPLAY_BPROFILE,
  APP_BPROFILE,
  REJ_BPROFILE,
  BPROFILE_REJ,
  BPROFILE_APP,
  BPROFILE_PEN,
  BYTPE_LIST,
  DEL_BYTPE,
  EDIT_BYTPE,
  ADD_BYTYPE,
  SEARCH_BPROFILE_APR,
  SEARCH_BPROFILE_PEN,
  SEARCH_BPROFILE_REJ,
  SEARCH_BTYPE
} from "../constants/url";

import {
  ERR_POSTING,
  ERR_GETTING,
  ERR_ALERT_TITLE,
  NETWORK_NOT_OK
} from "../constants/alertMessage";
import Swal from "sweetalert2/dist/sweetalert2";
import { privateApiCall } from "../api/privateApi";

const cookies = new Cookies();
// Bussiness Type
export const addBType = (type) => {
  const requestUrl = `${ADD_BYTYPE}`;
  return privateApiCall(requestUrl, "POST", type)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_POSTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const editBType = (type, _id) => {
  const requestUrl = `${EDIT_BYTPE}?_id=${_id}`
  return privateApiCall(requestUrl,"POST",type)

    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${ERR_POSTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const deleteBType = (_id) => {
  
  const requestUrl = `${DEL_BYTPE}?_id=${_id}`
  return privateApiCall(requestUrl,'GET')

    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const getBTypes = (token) => {
const requestUrl = `${BYTPE_LIST}?token=${token}`
return privateApiCall(requestUrl,"GET")
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const searchBTypes = (query) => {
  const jwtToken = cookies.get("jwtToken");

  return fetch(`${url1}${SEARCH_BTYPE}?query=${query}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`, 
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`${NETWORK_NOT_OK}`);
      }
      return response.json();
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};
//Business Profile

export const getpending = (token) => {
  // Get the JWT token from cookies
  const requestUrl = `${BPROFILE_PEN}`;
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      if (response.status !== 200) {
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_GETTING}`,
          confirmButtonText: "OK",
        });
      }
      return response.data;
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const searchPending = (query) => {
  const requestUrl = `${SEARCH_BPROFILE_PEN}?query=${query}`; 
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      if (response.status !== 200) {
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_GETTING}`,
          confirmButtonText: "OK",
        });
        return [];
      }
      return response.data; 
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
      return [];
    });
};

export const getapproved = (token) => {
  const requestUrl = `${BPROFILE_APP}`;
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      if (response.status !== 200) {
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_GETTING}`,
          confirmButtonText: "OK",
        });
      }

      return response.data;
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const searchApproved = (query) => {
  const requestUrl = `${SEARCH_BPROFILE_APR}?query=${query}`; 
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      if (response.status !== 200) {
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_GETTING}`,
          confirmButtonText: "OK",
        });
        return [];
      }
      return response.data; 
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
      return [];
    });
};

export const getrejected = (token) => {
  const requestUrl = `${BPROFILE_REJ}`;
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      if (response.status !== 200) {
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_GETTING}`,
          confirmButtonText: "OK",
        });
      }
      return response.data;
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const searchRejected = (query) => {
  const requestUrl = `${SEARCH_BPROFILE_REJ}?query=${query}`; 
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      if (response.status !== 200) {
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_GETTING}`,
          confirmButtonText: "OK",
        });
        return [];
      }
      return response.data; 
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
      return [];
    });
};

export const rejectBProfile = (token, id) => {
  const jwtToken = cookies.get("jwtToken");
const requestUrl = `${REJ_BPROFILE}?token=${token}&_id=${id}`
   return privateApiCall(requestUrl,'GET')
  // return fetch(`${url1}${REJ_BPROFILE}?token=${token}&_id=${id}`, {
  //   method: "GET",
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${jwtToken}`,
  //   },
  // })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const acceptBProfile = (token, id) => {
  const requestUrl = `${APP_BPROFILE}?token=${token}&_id=${id}`
  return privateApiCall(requestUrl,"GET")

 
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const approveAllBprofile = (data) => {
  const requestUrl = `${APP_ALL_BPROFILE}`;
  return privateApiCall(requestUrl, "POST", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_POSTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const rejectAllBprofile = (data) => {
  const requestUrl = `${REJ_ALL_BPROFILE}`;
  return privateApiCall(requestUrl, "POST", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_POSTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const replayToBProfile = (token, _id, replay) => {
  const jwtToken = cookies.get("jwtToken");

  return fetch(
    `${url1}${REPLAY_BPROFILE}?token=${token}&_id=${_id}&replay=${replay}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`, // Add JWT token to the headers
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};
