import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { API as url1 } from "../../config";
import { Formik, Form, Field, ErrorMessage } from "formik";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TextField from '@mui/material/TextField';
import * as Yup from "yup";
import { LOGIN_PATH, MFAVERIFY_PATH } from "../../constants/url";
import {
  ERR_ALERT_TITLE,
  ERR_POSTING,
  SUCC_ALERT_TITLE,
  ERR_EMAIL,
  EMAIL_REQ,
  PASSWORD_REQ,
  MFA_CODE_REQ,
  INVALID_CRED,
  MFA_FAILD,
  ALERT_ICON_SUCC,
  ALERT_ICON_ERR,
  ALERT_BUTTON_OK,
  EMAIL_PLACEHOLDER_FIELD,
  PASSWORD_PLACEHOLDER_FIELD,
  EMAIL_LABEL,
  PASSWORD_LABEL,
  REMEMBER_ME,
  FORGOT_PASS,
  SIGN_IN,
  AGGRED_TO,
  TERMS_COND,
  AND,
  PRIVACY_POLICY,
  MFA_6_DIGIT_ENTER,
  VERIFY_MFA_CODE,
  SEND_RESET,
  MFA_CODE_PLACEHOLDER,
  BACK_TO_LOGIN,
  ERR_GETTING,
} from "../../constants/alertMessage";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2";

const Login = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [showLogin, setShowLogin] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState("#769A0F");
  const [rememberMe, setRememberMe] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
    errorField: ""
  });
  const [initialValuesMFA, setInitialValuesMFA] = useState({ email: "" });
  const MFASecret = cookies.get("MFASecret");
  const [mfasecret, setMfasecret] = useState(MFASecret);
  const [loginType, setLoginType] = useState(cookies.get("login_type"));
  const [showForgot, setShowForgot] = useState(false);
  const [showMfa, setShowMfa] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  const [initialValuesResetPassword, setInitialValuesResetPassword] = useState({
    email: "",
  });const [showPassword, setShowPassword] = useState(false);

   
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    cookies.remove("jwtToken", { path: "/" });
    cookies.remove("MFASecret", { path: "/" });
    cookies.remove("isEnabled2FA", { path: "/" });
    cookies.remove("login_type", { path: "/" });
    cookies.remove("role", { path: "/" });
    const rememberMeValue = cookies.get("rememberMe");
    if (rememberMeValue) {
      const savedEmail = cookies.get("email");
      const savedPassword = cookies.get("password");
      setInitialValues({ email: savedEmail, password: savedPassword });
      setRememberMe(true);
    }
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(ERR_EMAIL)
      .required(EMAIL_REQ),
    password: Yup.string().required(PASSWORD_REQ),
  });
  const validationSchemaForgotPass = Yup.object().shape({
    email: Yup.string()
      .required(EMAIL_REQ)
      .email(ERR_EMAIL),
  });

  const validationSchemaMFA = Yup.object().shape({
    otp: Yup.string().required(MFA_CODE_REQ),
  });

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    axios
      .post(`${url1}${LOGIN_PATH}`, {
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        if (res.data.result === "success") {
          cookies.remove("jwtToken", { path: "/" });
          cookies.remove("MFASecret", { path: "/" });
          cookies.remove("isEnabled2FA", { path: "/" });
          cookies.remove("login_type", { path: "/" });
          let role = res.data.role ? res.data.role : {};
          localStorage.setItem("role", JSON.stringify(role))
          let localRole = JSON.parse(localStorage.getItem("role"))
          console.log(localRole.name)

          cookies.set("email", values.email);
          cookies.set("MFASecret", res.data.MFASecret);
          cookies.set(
            "isEnabled2FA",
            res.data.isEnabled2FA ? res.data.isEnabled2FA : false
          );

          const MFASecret = cookies.get("MFASecret");
          const email = cookies.get("email");
          setInitialValuesMFA({ email: email });
          setMfasecret(MFASecret);

          if (rememberMe) {
            cookies.set("rememberMe", true);
            cookies.set("email", values.email);
            cookies.set("password", values.password);
          }

          if (res.data.type === "org") {
            setBackgroundColor("#28A745");
            cookies.set("org_token", res.data.org_token);
            cookies.set("org_id", res.data.org_id);
            cookies.set("org_name", res.data.org_name);
            cookies.set("login_type", "organisation");

            cookies.set("jwtToken", res.data.jwToken);
            if (res.data.isEnabled2FA) {
              setShowLogin(false);
              setShowMfa(true);
            } else {
              navigate("/organisation/dashboard");
            }
         
          } else if (res.data.type === "callSupport") {
          } else {
            setBackgroundColor("#769A0F");
            cookies.set("agritech_token", res.data.admin_token);
            cookies.set("agritech_id", res.data.admin_id);
            cookies.set("jwtToken", res.data.jwToken);
            cookies.set("login_type", "admin");
            if (res.data.isEnabled2FA) {
              setShowLogin(false);
              setShowMfa(true);
            } else {
              navigate("/dashboard");
            }
     
          }
          setLoginType(cookies.get("login_type"));
        } else {
          if (res.data.result === "Invalid Email Id" || res.data.result === "Password is wrong!") {
            setFieldError("errorField",INVALID_CRED);
          } else {
            // Handle other errors
          }
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ ALERT_ICON_ERR }`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ ALERT_BUTTON_OK }`,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSubmitMFA = (values, { setSubmitting, setFieldError }) => {
    let data = {
      email: initialValues.email,
      otp: values.otp,
      MFABase32: mfasecret.base32,
    };
    axios
      .post(`${url1}${MFAVERIFY_PATH}`, data)
      .then((res) => {
        if (res.status === 200) {
          if (loginType === "admin") {
            navigate("/dashboard");
          } else if (loginType === "organisation") {
            navigate("/organisation/dashboard");
          }
        } else {
          setFieldError("otp", MFA_FAILD);
        }
      })
      .catch((err) => {
        setFieldError("otp", MFA_FAILD);
   
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleModal = (e) => {
    e.preventDefault();
    setShowLogin(false);
    setShowForgot(true);
  };

  const handleForgotPassSubmit = (
    values,
    { setSubmitting, setFieldError, resetForm }
  ) => {
    let data = {
      resetEmail: values.email,
    };
    const requestUrl = `${url1}/App/forgotPassword`;
    axios
      .post(requestUrl, data)
      .then((res) => {
        resetForm({ values: { email: "" } });

        Swal.fire({
          icon: `${ALERT_ICON_SUCC}`,
          title: `${SUCC_ALERT_TITLE}`,
          text: res?.data?.message,
          confirmButtonText:`${ ALERT_BUTTON_OK}`,
        }).then(() => {
          window.location.href = "/";
        });
      })
      .catch((err) => {
        resetForm({ values: { email: "" } });
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ ALERT_ICON_ERR }`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ ALERT_BUTTON_OK }`,
        }).finally(() => {
          setSubmitting(false);
          resetForm({ values: { email: "" } });
        });
      });
  };
  return (
    <section
      style={{
        backgroundImage: `url("assets/images/login.png")`,
        height: "100vh",
        width: "100vw",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
    <div className="container-fluid">
    <div className="row justify-content-end pl-5 pr-1">
      <div className="col-lg-6 col-md-8 col-sm-10 pt-4 ">
        {/* Login Form */}
        <div className="card bg-pattern ">
          <div className="card-body p-4">
            <div className="text-center">
              <Link to="/">
                <img src="assets/images/jack_logo.png" alt="" />
              </Link>
            </div>
            {showLogin && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="emailaddress">{EMAIL_LABEL}</label>
                <Field
                  className="form-control"
                  type="email"
                  id="emailaddress"
                  name="email"
                  placeholder= {EMAIL_PLACEHOLDER_FIELD}
                /><ErrorMessage
                name="email"
                component="div"
                className="text-danger"
              />

              </div>
              <div className="form-group">
                <label htmlFor="password">{PASSWORD_LABEL}</label>
                <Field
        name="password"
        as={TextField} 
        className="form-control"
        id="password"
        type={showPassword ? 'text' : 'password'} 
        placeholder={PASSWORD_PLACEHOLDER_FIELD}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleTogglePasswordVisibility}
                edge="end"
                aria-label="toggle password visibility"
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          height: 40,  
          '& .MuiInputBase-input': {
            padding: '8px',  
            fontSize: '14px', 
          },
        }}
      />
                <ErrorMessage
                name="password"
                component="div"
                className="text-danger"
              />
                <ErrorMessage
                  name="errorField"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <div className="custom-control custom-checkbox checkbox-success">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="checkbox-signin"
                    name="rememberMe"
                    checked={rememberMe}
                    onChange={(e) => {
                      setRememberMe(e.target.checked);
                      if (!e.target.checked) {
                        cookies.remove("rememberMe");
                        cookies.remove("email");
                        cookies.remove("password");
                      }
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="checkbox-signin"
                  >
                  {REMEMBER_ME}
                  </label>
                </div>


                <label htmlFor="forgot password "><a onClick={handleModal} style={{ textDecoration: 'none', color: 'red' }} href="">{FORGOT_PASS}</a></label>
              </div>
              <div className="form-group row align-items-center">
                <div className="col-12">
                  <button
                    className="btn btn-block btn-primary waves-effect waves-light"
                    type="submit"
                    disabled={isSubmitting}
                  >
                   {SIGN_IN}
                  </button>
                  <div className="form-check text-stone-500 text-sm font-normal align-items-center leading-none">
                    <label
                      htmlFor="agreeTerms"
                      className="form-check-label mt-2"
                      style={{ width: "auto" }}
                    >
                    {AGGRED_TO}{" "}
                      <a
                        href="https://www.radtar.com/termsAndConditions.php"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                        style={{ color: "green" }}
                      >
                        {" "}
                       {TERMS_COND}
                      </a>{" "}
                      {AND}{" "}
                      <a
                        href="https://www.radtar.com/privacyPolicy.php"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "green" }}
                        className="underline"
                      >
                        {" "}
                        {PRIVACY_POLICY}
                      </a>
                      .
                    </label>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {showMfa && (
        <Formik
          initialValues={initialValuesMFA}
          validationSchema={validationSchemaMFA}
          onSubmit={handleSubmitMFA}
          enableReinitialize={true}
        >
          {({ isSubmitting }) => (
            <Form>
           
              <div className="form-group">
                <h6>
               {MFA_6_DIGIT_ENTER}
                </h6>
      
                <Field
                  className="form-control"
                  type="otp"
                  id="otp"
                  name="otp"
                  placeholder={MFA_CODE_PLACEHOLDER}
                />
                <ErrorMessage
                  name="otp"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group row align-items-center">
                <div className="col-12">
                  <button
                    className="btn btn-block btn-primary waves-effect waves-light"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {VERIFY_MFA_CODE}
                  </button>
                </div>
              </div>
              <div className="form-check text-stone-500 text-sm font-normal align-items-center leading-none">
                <div style={{ visibility: "hidden" }}>
                  <label
                    htmlFor="agreeTerms"
                    className="form-check-label mt-2"
                    style={{ width: "auto" }}
                  >
                   {AGGRED_TO}{" "}
                    <a
                      href="https://www.radtar.com/termsAndConditions.php"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                      style={{ color: "green" }}
                    >
                      {" "}
                     {TERMS_COND}
                    </a>{" "}
                    {AND}{" "}
                    <a
                      href="https://www.radtar.com/privacyPolicy.php"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "green" }}
                      className="underline"
                    >
                      {" "}
                     {PRIVACY_POLICY}
                    </a>
                    .
                  </label>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {showForgot && (
        <Formik
          onSubmit={handleForgotPassSubmit}
          enableReinitialize={true}
          validationSchema={validationSchemaForgotPass}
          initialValues={initialValuesResetPassword}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="email address">{EMAIL_LABEL}</label>
                <Field
                  className="form-control"
                  type="email"
                  id="email"
                  name="email"
                  placeholder={EMAIL_PLACEHOLDER_FIELD}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group row align-items-center">
                <div className="col-12">
                  <button
                    className="btn btn-block btn-primary waves-effect waves-light"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {SEND_RESET}
                  </button>
                </div>

              </div>


              <label htmlFor="back to login"><a style={{ textDecoration: 'none', color: 'inherit' }} href="/"> {BACK_TO_LOGIN}</a></label>
              <div className="form-check text-stone-500 text-sm font-normal align-items-center leading-none">
                <div style={{ visibility: "hidden" }}>
                  <label
                    htmlFor="agreeTerms"
                    className="form-check-label mt-2"
                    style={{ width: "auto" }}
                  >
                   {AGGRED_TO}{" "}
                    <a
                      href="https://www.radtar.com/termsAndConditions.php"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                      style={{ color: "green" }}
                    >
                      {" "}
                     {TERMS_COND}
                    </a>{" "}
                    {AND}{" "}
                    <a
                      href="https://www.radtar.com/privacyPolicy.php"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "green" }}
                      className="underline"
                    >
                      {" "}
                      {PRIVACY_POLICY}
                    </a>
                    .
                  </label>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
          </div>
        </div>
      </div>
    </div>
  </div>
    </section>
  );
};

export default Login;
