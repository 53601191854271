import React from 'react';
import { Modal, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImageModal = ({ open, handleClose, imageSrc }) => {
  const images = Array.isArray(imageSrc) ? imageSrc : [imageSrc];
  

  return (
   <Modal
    onClose={handleClose}
    open={open}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "60%",
        height: "60%",
        overflow: "hidden",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Button
        onClick={handleClose}
        style={{ position: 'absolute', top: '10px', right: '10px' }}
      >
        <CloseIcon />
      </Button>
      <div style={{ width: '80%', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {images.length > 1 ? (
          <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              {images.map((image, index) => (
                <div className={`carousel-item${index === 0 ? " active" : ""}`} key={index}>
                  <img
                    src={image || "/assets/images/no_image.png"}
                    className="d-block w-100"
                    alt={`Slide ${index}`}
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                  />
                </div>
              ))}
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
              style={{ width: '5%', left: '-50px' }}
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
                style={{ filter: 'invert(100%)', backgroundColor: '#000', width: '50px', height: '50px' }}
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
              style={{ width: '5%', right: '-50px' }}
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
                style={{ filter: 'invert(100%)', backgroundColor: '#000', width: '50px', height: '50px' }}
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        ) : (
          <img
            src={images[0]}
            alt="Single Image"
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
          />
        )}
      </div>
    </Box>
  </Modal>
  );
};

export default ImageModal;
