import React from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import { Link } from "react-router-dom";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { IconButton } from "@mui/material";

const customerSupport = () => {
  
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";

  const handleWhatsAppClick = () => {
    const phoneNumber = '9188009623';  
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };
  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">
                    {STRING_CONSTANTS.CUSTOMER_SUPPORT}
                  </h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">
                        {STRING_CONSTANTS.DASHBOARD}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {STRING_CONSTANTS.CUSTOMER_SUPPORT}
                    </li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <form>
              <div className="row">
                <div className="col-12">
                  <div className="card-box">
                    <div className="row">
                      <div className="col-12">
                        <div className="p-20">
                          <div className="row">
                            <div className="col-xs-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="card">
                                <div className="card-body">
                                  <div className="icon-button-wrapper">
                                  {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgCustomerSupport" && item.isNavigate
              )) && (
              
            <li>
            <IconButton
                                    style={{
                                      position: 'absolute',
                                      top: '10px',
                                      right: '10px',
                                      color: 'green',  
                                    }}
                                    onClick={handleWhatsAppClick}
                                    >
                                      <WhatsAppIcon />{" "}
                                      
                                    </IconButton>
          </li>
            )}
                                   
                                  </div>
                                  <h4 className="text-dark font-18">
                                    {STRING_CONSTANTS.CUSTOMER_SUPPORT}
                                  </h4>
                                  <div className="row text-center justify-content-center align-items-center mt-3">
                                    <img
                                      src="/assets/images/joice.jpg"
                                      alt=""
                                      className="rounded-circle avatar-xxl"
                                    />
                                  </div>

                                  <div className="row text-center justify-content-center align-items-center ">
                                    <h4 className="text-dark font-18   mt-2 ">
                                      {STRING_CONSTANTS.JOICE_MATHEW}
                                    </h4>
                                  </div>
                                  <div className="row text-center justify-content-center align-items-center ">
                                    <h4 className=" font-16   ">
                                      jackfruitworld@gmail.com
                                    </h4>
                                  </div>
                                  <div className="row text-center justify-content-center align-items-center ">
                                    <h4 className=" font-16">
                                      +91 91880 09623
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default customerSupport;
