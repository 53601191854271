import React, { useEffect } from 'react';
import Topbar from './topBar';
import Sidebar from './sideBar';
import  Content  from "./content"
const Dashboard = () => {
  useEffect(() => {
    // componentDidMount logic can go here
    return () => {
    };
  }, []);

  return (
    <div id="wrapper">
      <Topbar backgroundColor='#769A0F' />
      <Sidebar />
        <Content />   
    
    </div>
  );
};

export default Dashboard;
