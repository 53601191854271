export const ORDER_STATUS = Object.freeze({
    PAYMENT_PENDING:8,
    PAID: 9,
    SHIPPED:11,
    RECEIVED:12,
    CLOSED:13,
  });
 

  export const getOrderStatus=(status)=>{
    const statusMap={
        [ORDER_STATUS.PAYMENT_PENDING]:"Payment Pending",
        [ORDER_STATUS.PAID]:"Paid",
        [ORDER_STATUS.SHIPPED]:"Shipped",
        [ORDER_STATUS.RECEIVED]:"Received",
        [ORDER_STATUS.CLOSED]:"Closed"
    }
    return statusMap[status]
  }

  