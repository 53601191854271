import React, { useState, useEffect } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";


import {
  GET_ALL_ORDERS,
  SAVE_PORTAL_USER,
  UPDATE_PORTAL_USER
} from "../.././constants/url";
import { FiEdit2 } from "react-icons/fi";
import {
  getRoles,
  getPortalUsers,
  savePortalUser,
  updatePortalUserStatus,
  searchPortalUsers,
} from "../../actions/adminAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "react-tagsinput/react-tagsinput.css";
import { Link } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  FormControl,
  TableRow,
  MenuItem,
  Box,
  Grid,
  InputLabel,
  Select,
  Button,
  Modal,
  TextField,
} from "@mui/material"; // Import MUI component
import { Tooltip } from "react-tooltip";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import {
  ERR_POSTING,
  SUCC_ALERT_TITLE,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  ALERT_ICON_ERR,
  ALERT_BUTTON_OK,
  ALERT_ICON_SUCC,
  REMOVE_ACC,
  RESTORE_ACC,
  ALERT_BUTTON_YES,
  CONFIRM,
  ERR,
  ERROR
} from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const Users = () => {
  const [roles, setRoles] = useState([]);
  const [portalUsers, setPortalUsers] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false)
    setValues((prevValues) => ({
      ...prevValues,
      mobileError: "",  
    }));
  };
  const [portalUserId, setPortalUserId] = useState(null);
  const [values, setValues] = useState({
    name: "",
    mobile: "",
    email: "",
    role: "",
    header: "",
    type: "",
    password:"",
    _id: "",
    success: false,
    removed: false,
    loading: false,
    mobileError: "",
  });
  const [page,setPage]=useState(0)
  const [rowsPerPage,setRowsPerPage]=useState(10)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const { email, mobile, name,mobileError } = values;

  useEffect(() => {
    fetchRoles();
    fetchPortalUsers();
  }, []);

  const handleSearch = async (e) => {
    const searchValue = e.target.value;
    try {
      const response = await searchPortalUsers(searchValue);
  
  
      if (response.status && response.portalUsers) {
        setPortalUsers(response.portalUsers);
      } else {
        setValues((prevState) => ({
          ...prevState,
          team: [], 
        }));
      }
    } catch (error) {
      Swal.fire({
        title: `${ERROR}`,
        text: error.message ,
        icon: `${ERR}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${CONFIRM}`,
      });
    }
  };

  const handleEditClick = (row) => {
    setValues({
      ...values,
      error: false,
      name: row.name,
      mobile: row.mobile_no,
      role: row.roleId,
      email: row.email,
      password: row.password,
      type: "edit",
      _id: row._id,
    });
    
    setPortalUserId(row._id);
    setShow(true);
  };

  const handleStatusClick = (row) => {
    const newStatus = !row.approved;
    const confirmMessage = newStatus
      ? `${RESTORE_ACC} ${row.name}?`
      : `${REMOVE_ACC} ${row.name}?`;

    Swal.fire({
      title: confirmMessage,
      text: "",
      icon: "question",
      allowOutsideClick: false,
      confirmButtonText: `${ALERT_BUTTON_YES}`,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        updatePortalUserStatus(row._id, newStatus).then((data) => {
          if (data.error) {
            setValues({ ...values, error: data.error, loading: false });
          } else {
            fetchPortalUsers();
          }
        });
      }
    });
  };
  const fetchRoles = async () => {
    try {
      const data = await getRoles();
      if (data.error) {
        throw new Error();
      }
      setRoles(data.roles);
    } catch {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  const fetchPortalUsers = async () => {
    try {
      const data = await getPortalUsers();
      if (data.error) {
        throw new Error();
      }
      setPortalUsers(data.portalUsers);
    } catch {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  const handleClearData = () => {
    setValues({
      ...values,
      role: "",
      name: "",
      email: "",
      mobile: "",
      password:""
    });
    setShow(true);
    setPortalUserId(null);
    setSelectedRoleId("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (mobile.length !== 10 || mobile.startsWith("0")) {
      setValues((prevValues) => ({
        ...prevValues,
        mobileError: "Enter a valid 10-digit mobile number",
      }));
      return;
    }
    
    setValues({ ...values, loading: true, error: false });const userData = {
      name,
      email,
      mobile,
      roleId: selectedRoleId ? selectedRoleId : values.role,
      ...(values.password && values.password.trim() !== '' && { password: values.password }) // Add password only if it's not an empty string
    }
    const requestUrl = portalUserId ? `${UPDATE_PORTAL_USER}/${portalUserId}`:`${SAVE_PORTAL_USER}`

    
    savePortalUser(requestUrl,userData)
      .then((data) => {
        if (data.error) {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        } else {
          handleClose(false);
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            icon: `${ALERT_ICON_SUCC}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          }).then(() => {
            fetchPortalUsers();
            handleClearData();
            setShow(false);
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };
  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };
  const getRoleNameById = (roleId) => {
    const role = roles.find((role) => role.roleId === roleId);
    return role ? role.name : `${STRING_CONSTANTS.ROLE_NOT_FOUND}`;
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="configurations" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">
                    {STRING_CONSTANTS.PORTAL_USERS}
                  </h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {STRING_CONSTANTS.PORTAL_USERS}
                    </li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  {/* <div style={{ width: "98%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <button
                        className="btn btn-icon waves-effect waves-light btn-primary"
                        onClick={handleClearData}
                        style={{ marginRight: "10px" }}
                      >
                        <i className="fa fa-plus"></i> {STRING_CONSTANTS.ADD_PROTAL_USERS}
                      </button>
                      <div
                        style={{ position: "relative", marginLeft: "auto" }}
                      ></div>
                    </div>

                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ minWidth: 100 }}>
                              {STRING_CONSTANTS.SN_NO}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {STRING_CONSTANTS.NAME}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {STRING_CONSTANTS.EMAIL}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {STRING_CONSTANTS.MOBILE}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {STRING_CONSTANTS.ROLE}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {portalUsers &&
                            portalUsers.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.mobile}</TableCell>
                                <TableCell>
                                  {getRoleNameById(row.roleId)}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Modal
                      open={show}
                      onClose={handleClose}
                      aria-labelledby="add-call-support-modal-title"
                      aria-describedby="add-call-support-modal-description"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "100px",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "900px",
                          height: "300px",
                          backgroundColor: "white",
                          padding: "20px",
                          borderRadius: "8px",
                          boxShadow: 24,
                          outline: "none",
                        }}
                      >
                        <h3>
                          {portalUserId
                            ? `${STRING_CONSTANTS.EDIT_PROTAL_USER}`
                            : `${STRING_CONSTANTS.ADD_PROTAL_USERS}`}
                        </h3>
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={3}>
                            <Grid item md={4} xs={12}>
                              <TextField
                                label="Name"
                                value={name}
                                placeholder={STRING_CONSTANTS.NAME_PLACEHOLDER}
                                onChange={handleChange("name")}
                                required
                                fullWidth
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <TextField
                                label="Email ID"
                                value={email}
                                placeholder={STRING_CONSTANTS.EMAIL_PLACEHOLDER}
                                onChange={handleChange("email")}
                                required
                                fullWidth
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <TextField
                                label="Mobile No"
                                value={mobile}
                                placeholder={STRING_CONSTANTS.MOBILE_NO_PLACEHOLDER}
                                onChange={handleChange("mobile")}
                                fullWidth
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <FormControl fullWidth>
                                <InputLabel id="role-label">
                                  {STRING_CONSTANTS.SELECT_ROLE}
                                </InputLabel>
                                <Select
                                  labelId="role-label"
                                  value={selectedRoleId}
                                  onChange={(e) =>
                                    setSelectedRoleId(e.target.value)
                                  }
                                  required
                                >
                                  <MenuItem value="">
                                    <em>{STRING_CONSTANTS.SELECT_ROLE}</em>
                                  </MenuItem>
                                  {roles
                                    .sort((a, b) => a.roleId - b.roleId).map((item) => (item.roleId != 5 &&
                                      <MenuItem
                                        key={item.roleId}
                                        value={item.roleId}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            justifyContent="flex-end"
                            style={{ marginTop: "20px" }}
                          >
                            <Grid item>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={handleClose}
                              >
                                {STRING_CONSTANTS.CLOSE}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                type="submit"
                                variant="contained"
                                style={{
                                  backgroundColor: "#769A0F",
                                  color: "#fff",
                                }}
                              >
                                {portalUserId ? "Update" : "Submit"}
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </Box>
                    </Modal>
                  </div> */}
                  <div style={{ width: "98%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <button
                        className="btn btn-icon waves-effect waves-light btn-primary "
                        onClick={(e) => handleClearData()}
                        style={{ marginRight: "10px" }}
                      >
                        <i className="fa fa-plus"></i>{" "}
                        {STRING_CONSTANTS.ADD_PROTAL_USERS}
                      </button>
                      <div style={{ position: "relative", marginLeft: "auto" }}>
                        <input
                          type="text"
                          placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                          className="form-control"
                          style={{
                            width: "200px",
                            paddingLeft: "35px",
                            boxSizing: "border-box",
                            marginLeft: "auto",
                          }}
                          onChange={(e) => {
                            handleSearch(e);
                            if (e.target.value.trim() === '') {
                              e.target.nextElementSibling.style.display = 'inline-block';
                            } else {
                              e.target.nextElementSibling.style.display = 'none';
                            }
                          }}
                        />
                        <i
                          className="fas fa-search"
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            color: "#aaa",
                          }}
                        ></i>
                      </div>
                    </div>

                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ minWidth: 100 }}>
                              {STRING_CONSTANTS.SN_NO}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {STRING_CONSTANTS.NAME}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {STRING_CONSTANTS.EMAIL}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {STRING_CONSTANTS.MOBILE}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {STRING_CONSTANTS.ROLE}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {STRING_CONSTANTS.STATUS}
                            </TableCell>
                            <TableCell style={{ minWidth: 200 }}>
                              {STRING_CONSTANTS.ACTIONS}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {portalUsers &&
                            portalUsers.slice(
                              page *  rowsPerPage,
                               page *  rowsPerPage +  rowsPerPage
                            ).map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.mobile_no}</TableCell>
                                <TableCell>
                                  {getRoleNameById(row.roleId)}
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  {row.approved ? (
                                    <span className="btn-sm btn-success ">
                                      {STRING_CONSTANTS.ACTIVE}
                                    </span>
                                  ) : (
                                    <span className="btn-sm btn-danger">
                                      {STRING_CONSTANTS.INACTIVE}
                                    </span>
                                  )}{" "}
                                </TableCell>
                                <TableCell>
                                  <>
                                  <FiEdit2
                                    size="18px"
                                    color="#0978F2"
                                     data-tooltip-id="edit-tooltip"
                                     data-tooltip-content="Edit"
                                     onClick={() => handleEditClick(row)}
                                  />
                                    <Tooltip id="edit-tooltip"/>
                                    </>
                                  {row.approved ? (
                                    <>
                                    <FaRegThumbsDown
                                      size="18px"
                                      className="ml-3"
                                      color="red"
                                      data-tooltip-id="3"
                                      data-tooltip-content="InActive"
                                      onClick={() => handleStatusClick(row)}
                                    /> <Tooltip id="3"   /></>
                                    
                                    
                                  ) : (
                                    <>
                                    <FaRegThumbsUp
                                      size="18px"
                                      className="ml-3"
                                      color="green"
                                      data-tooltip-id="2"
                                      data-tooltip-content="Active"
                                       onClick={() => handleStatusClick(row)}
                                    />
                                     <Tooltip id="2"  />
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}

                          <Modal
                            open={show}
                            onClose={handleClose}
                            aria-labelledby="add-call-support-modal-title"
                            aria-describedby="add-call-support-modal-description"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "100px",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                width: "900px",
                                height: "300px",
                                backgroundColor: "white",
                                padding: "20px",
                                borderRadius: "8px",
                                boxShadow: 24,
                                outline: "none",
                              }}
                            >
                              <h3>
                                {portalUserId
                                  ? `${STRING_CONSTANTS.EDIT_PROTAL_USER}`
                                  : `${STRING_CONSTANTS.ADD_PROTAL_USERS}`}
                              </h3>

                              <form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                  <Grid item md={4} xs={12}>
                                    <TextField
                                      label="Name"
                                      value={name}
                                      placeholder={
                                        STRING_CONSTANTS.NAME_PLACEHOLDER
                                      }
                                      onChange={handleChange("name")}
                                      required
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item md={4} xs={12}>
                                    <TextField
                                      label="Email ID"
                                      value={email}
                                      placeholder={
                                        STRING_CONSTANTS.EMAIL_PLACEHOLDER
                                      }
                                      onChange={handleChange("email")}
                                      required
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item md={4} xs={12}>
                                    <TextField
                                      label="Mobile No"
                                      value={mobile}
                                      placeholder={
                                        STRING_CONSTANTS.MOBILE_NO_PLACEHOLDER
                                      }
                                      onChange={handleChange("mobile")}
                                      required
                                      fullWidth
                                      error={!!mobileError} 
                                      helperText={mobileError}
                                    />
                                  </Grid>
                                  <Grid item md={4} xs={12}>
                              <FormControl fullWidth>
                                <InputLabel >
                                  {STRING_CONSTANTS.SELECT_ROLE}
                                </InputLabel>
                                <Select
                                  label="select role"
                                  value={selectedRoleId || values.role}
                                  onChange={(e) =>
                                    setSelectedRoleId(e.target.value)
                                  }
                                  required
                                >
                                  <MenuItem value="">
                                    <em>{STRING_CONSTANTS.SELECT_ROLE}</em>
                                  </MenuItem>
                                  {roles
                                    .sort((a, b) => a.roleId - b.roleId).map((item) => (item.roleId != 5 &&
                                      <MenuItem
                                        key={item.roleId}
                                        value={item.roleId}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                                  <Grid item md={4} xs={12}>
                                  {!portalUserId &&
                                   (<TextField
                                      label="Password"
                                      placeholder={
                                        STRING_CONSTANTS.PASSWORD_PLACEHOLDER
                                      }
                                      onChange={handleChange("password")}
                                      required={!portalUserId} 
                                      fullWidth
                                      type="password"
                                    />)}
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  spacing={2}
                                  justifyContent="flex-end"
                                  style={{ marginTop: "20px" }}
                                >
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={handleClose}
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      style={{
                                        backgroundColor: "#769A0F",
                                        color: "#fff",
                                      }}
                                    >
                                     {portalUserId
                                  ? `${STRING_CONSTANTS.EDIT_PROTAL_USER}`
                                  : `${STRING_CONSTANTS.ADD_PROTAL_USERS}`}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </form>
                            </Box>
                          </Modal>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={portalUsers.length}
                        rowsPerPage={ rowsPerPage}
                        page={page||0}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
