import fetch from 'isomorphic-fetch'
import { API as url1 } from '../config';
import { ADMIN_SUB,UPDATE_SUB } from '../constants/url';
import { ERR_POSTING, ERR_GETTING, ERR_ALERT_TITLE } from "../constants/alertMessage";

import  Swal  from "sweetalert2/dist/sweetalert2";

import Cookies from "universal-cookie";
import { privateApiCall } from '../api/privateApi';
const cookies = new Cookies();

export const AdminSubscription = (token) => {
    const requestUrl = `${ADMIN_SUB}?token=${token}`
    return privateApiCall(requestUrl,'GET')
        .then(response => {
            return response.data
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                title: `${ERR_ALERT_TITLE}`,
                text: `${ERR_GETTING}`,
                confirmButtonText: 'OK'  
              })
        });
};

export const updateSubcription = (data, token) => {
    const jwtToken = cookies.get("jwtToken"); 
    return fetch(`${url1}${UPDATE_SUB}/?token=${token}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`, 
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `${ERR_POSTING}`,
                confirmButtonText: 'OK'  
              })
        });
};

const FREE = '/free'; // Define your endpoint here
export const freeUsers = (token) => {
    const jwtToken = cookies.get("jwtToken"); 
    return fetch( `${url1}${FREE}/?token=${token}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`, 
        },
    })
        .then(response => {
            return response.json();
        })
        .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `${ERR_GETTING}`,
                confirmButtonText: 'OK'  
              })
        });
};