import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Cookies from "universal-cookie";
import { Editor } from "@tinymce/tinymce-react";
import Swal from "sweetalert2/dist/sweetalert2";
import { ALERT_BUTTON_OK, ERR_ALERT_TITLE, ERR_GETTING } from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
const cookies = new Cookies();

class Addproduct extends Component {
  componentDidMount() {
    const token = cookies.get("asia_page_token");
    const requestUrl = `/addcategory/?token=${token}`;
    privateApiCall(requestUrl, "GET").then(
      (res) => {
        const k = 1;

        for (let i = 0; i < res.data.length; i++) {
          res.data[i].sno = k + +i;
        }

        this.setState({ data: res.data });
      },
      (err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`
        });
      }
    );
  }
  constructor() {
    super();
    window.postMessage("renderTable", "*");
    this.state = {
      name: "",
      category_id: "",
      //subcategory_id:"",
      data: [],
      data1: [],
      data2: [],
      data3: [],
      // video:"",
      material: "",
      size: "",
      color: "",
      moq: "",
      packing: "",
      payment_term: "",
      shipping: "",
      delivery_time: "",
      description: "",
      image1: "http://placehold.it/60/c2c2c2?text=User",
      image2: "http://placehold.it/60/c2c2c2?text=User",
      image3: "http://placehold.it/60/c2c2c2?text=User",
      image4: "http://placehold.it/60/c2c2c2?text=User",
      image5: "http://placehold.it/60/c2c2c2?text=User",
      // displayColorPicker: false,
    };

    this.handlenameChange = this.handlenameChange.bind(this);
    this.category = this.category.bind(this);
    this.material = this.material.bind(this);
    this.size = this.size.bind(this);
    this.color = this.color.bind(this);
    this.moq = this.moq.bind(this);
    this.packing = this.packing.bind(this);
    this.payment_term = this.payment_term.bind(this);
    this.shipping = this.shipping.bind(this);
    this.delivery_time = this.delivery_time.bind(this);
    this.description = this.description.bind(this);
    //   this.subcategory = this.subcategory.bind(this);
    this.changeimage1 = this.changeimage1.bind(this);
    this.changeimage2 = this.changeimage2.bind(this);
    this.changeimage3 = this.changeimage3.bind(this);
    this.changeimage4 = this.changeimage4.bind(this);
    this.changeimage5 = this.changeimage5.bind(this);
  }

  changeimage1(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image1: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      this.setState({ image1: "http://placehold.it/60/c2c2c2?text=User" });
    }
  }

  changeimage2(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image2: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      this.setState({ image2: "http://placehold.it/60/c2c2c2?text=User" });
    }
  }
  changeimage3(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image3: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      this.setState({ image3: "http://placehold.it/60/c2c2c2?text=User" });
    }
  }
  changeimage4(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image4: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      this.setState({ image4: "http://placehold.it/60/c2c2c2?text=User" });
    }
  }
  changeimage5(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image5: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      this.setState({ image5: "http://placehold.it/60/c2c2c2?text=User" });
    }
  }

  handlenameChange(event) {
    this.setState({ name: event.target.value });
  }

  category(event) {
    this.setState({ category_id: event.target.value });
  }
  material(event) {
    this.setState({ material: event.target.value });
  }
  size(event) {
    this.setState({ size: event.target.value });
  }
  color(event) {
    this.setState({ color: event.target.value });
  }
  moq(event) {
    this.setState({ moq: event.target.value });
  }
  packing(event) {
    this.setState({ packing: event.target.value });
  }
  payment_term(event) {
    this.setState({ payment_term: event.target.value });
  }
  shipping(event) {
    this.setState({ shipping: event.target.value });
  }
  delivery_time(event) {
    this.setState({ delivery_time: event.target.value });
  }
  handleChange(event) {
    this.setState({ color: event.target.value });
    console.log(event.target.value);
  }
  description(event) {
    this.setState({ description: event });
  }

  render() {
    return (
      <div>
        <Topbar backgroundColor="#769A0F" />
        <Sidebar />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title float-left">{STRING_CONSTANTS.ADD_PRODUCTS}</h4>
                    <ol className="breadcrumb float-right">
                      <li className="breadcrumb-item">
                        {" "}
                        <NavLink to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        {" "}
                        <NavLink to="/product">{STRING_CONSTANTS.PRODUCTS}</NavLink>
                      </li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.ADD_PRODUCTS}</li>
                    </ol>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card-box">
                    <form
                      method="post"
                      enctype="multipart/form-data"
                      action={
                        "/addproduct/?token=" +
                        cookies.get("asia_page_token") +
                        "&_id=" +
                        cookies.get("asia_page_id")
                      }
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>{STRING_CONSTANTS.PRODUCT_NAME}</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={STRING_CONSTANTS.NAME_PLACEHOLDER}
                              name="name"
                              onChange={this.handlenameChange}
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>{STRING_CONSTANTS.CATEGORY}</label>
                            <select
                              id="single"
                              className="form-control"
                              value={this.state.category_id}
                              name="category_id"
                              required
                              //  id="sel1"
                              onChange={this.category}
                            >
                              <option value="">{STRING_CONSTANTS.SELECT_CATEGORY}</option>
                              {this.state.data.map((person, i) => (
                                <option key={i} value={person._id}>
                                  {" "}
                                  {person.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <label>
                              {" "}
                              <span className="label-text">
                                {STRING_CONSTANTS.PRODUCT_DESCRIPTION}
                              </span>
                            </label>
                            <input
                              type="hidden"
                              name="description"
                              value={this.state.description}
                            ></input>
                            <Editor
                              initialValue={this.state.description}
                              init={{
                                plugins: [
                                  "advlist autolink link lists charmap print preview hr anchor pagebreak spellchecker",
                                  "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking",
                                  "save table contextmenu directionality emoticons template paste textcolor",
                                ],
                                toolbar:
                                  "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | print preview fullpage | forecolor backcolor emoticons",
                              }}
                              onEditorChange={this.description}
                              apiKey="uxjmfte1hqpg5tmlpjt5sllltuxcz4q4899bcxa9uzgbd98d"
                              cloudChannel="dev"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>{STRING_CONSTANTS.PRODUCT_IMAGE}</label>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <div
                                    className="col-12"
                                    style={{ paddingBottom: "11px" }}
                                  >
                                    {this.state.image1 !== "" &&
                                    this.state.image1 != null ? (
                                      <img
                                        className="img-responsive"
                                        src={this.state.image1}
                                        alt="user"
                                        style={{
                                          width: "150px",
                                          height: "150px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        className="img-responsive"
                                        src="http://placehold.it/60/c2c2c2?text=User"
                                        alt="user"
                                        style={{
                                          width: "150px",
                                          height: "150px",
                                        }}
                                      />
                                    )}
                                    <br />
                                    <input
                                      type="hidden"
                                      className="default"
                                      value={this.state.image1}
                                      name="image1"
                                      onChange={this.changeimage1}
                                      id="upload_file"
                                      accept="image/*"
                                    />
                                    <input
                                      type="file"
                                      className="default"
                                      name="image1"
                                      onChange={this.changeimage1}
                                      id="upload_file"
                                      accept="image/*"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <div
                                    className="col-12"
                                    style={{ paddingBottom: "11px" }}
                                  >
                                    {this.state.image2 !== "" &&
                                    this.state.image2 != null ? (
                                      <img
                                        className="img-responsive"
                                        src={this.state.image2}
                                        alt="user"
                                        style={{
                                          width: "150px",
                                          height: "150px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        className="img-responsive"
                                        src="http://placehold.it/60/c2c2c2?text=User"
                                        alt="user"
                                        style={{
                                          width: "150px",
                                          height: "150px",
                                        }}
                                      />
                                    )}
                                    <br />
                                    <input
                                      type="hidden"
                                      className="default"
                                      value={this.state.image2}
                                      name="image2"
                                      onChange={this.changeimage2}
                                      id="upload_file"
                                      accept="image/*"
                                    />
                                    <input
                                      type="file"
                                      className="default"
                                      name="image2"
                                      onChange={this.changeimage2}
                                      id="upload_file"
                                      accept="image/*"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <div
                                    className="col-12"
                                    style={{ paddingBottom: "11px" }}
                                  >
                                    {this.state.image3 !== "" &&
                                    this.state.image3 != null ? (
                                      <img
                                        className="img-responsive"
                                        src={this.state.image3}
                                        alt="user"
                                        style={{
                                          width: "150px",
                                          height: "150px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        className="img-responsive"
                                        src="http://placehold.it/60/c2c2c2?text=User"
                                        alt="user"
                                        style={{
                                          width: "150px",
                                          height: "150px",
                                        }}
                                      />
                                    )}
                                    <br />
                                    <input
                                      type="hidden"
                                      className="default"
                                      value={this.state.image3}
                                      name="image3"
                                      onChange={this.changeimage3}
                                      id="upload_file"
                                      accept="image/*"
                                    />
                                    <input
                                      type="file"
                                      className="default"
                                      name="image3"
                                      onChange={this.changeimage3}
                                      id="upload_file"
                                      accept="image/*"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <div
                                  className="col-12"
                                  style={{ paddingBottom: "11px" }}
                                >
                                  {this.state.image4 !== "" &&
                                  this.state.image4 != null ? (
                                    <img
                                      className="img-responsive"
                                      src={this.state.image4}
                                      alt="user"
                                      style={{
                                        width: "150px",
                                        height: "150px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      className="img-responsive"
                                      src="http://placehold.it/60/c2c2c2?text=User"
                                      alt="user"
                                      style={{
                                        width: "150px",
                                        height: "150px",
                                      }}
                                    />
                                  )}
                                  <br />
                                  <input
                                    type="hidden"
                                    className="default"
                                    value={this.state.image4}
                                    name="image4"
                                    onChange={this.changeimage4}
                                    id="upload_file"
                                    accept="image/*"
                                  />
                                  <input
                                    type="file"
                                    className="default"
                                    name="image4"
                                    onChange={this.changeimage4}
                                    id="upload_file"
                                    accept="image/*"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <div
                                  className="col-12"
                                  style={{ paddingBottom: "11px" }}
                                >
                                  {this.state.image5 !== "" &&
                                  this.state.image5 != null ? (
                                    <img
                                      className="img-responsive"
                                      src={this.state.image5}
                                      alt="user"
                                      style={{
                                        width: "150px",
                                        height: "150px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      className="img-responsive"
                                      src="http://placehold.it/60/c2c2c2?text=User"
                                      alt="user"
                                      style={{
                                        width: "150px",
                                        height: "150px",
                                      }}
                                    />
                                  )}
                                  <br />
                                  <input
                                    type="hidden"
                                    className="default"
                                    value={this.state.image5}
                                    name="image5"
                                    onChange={this.changeimage5}
                                    id="upload_file"
                                    accept="image/*"
                                  />
                                  <input
                                    type="file"
                                    className="default"
                                    name="image5"
                                    onChange={this.changeimage5}
                                    id="upload_file"
                                    accept="image/*"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group col-md-6">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{ float: "left", backgroundColor: "#11abff" }}
                        >
                          {STRING_CONSTANTS.SUBMIT}
                        </button>
                    
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Addproduct;
