import React, { Component } from "react";
import axios from "axios";
import { API as url1 } from "../../config";
import { GET_EMAIL_MOBILE_PATH } from "../../constants/url";

import  Swal  from "sweetalert2/dist/sweetalert2";
import { Link } from "react-router-dom";
import { ALERT_BUTTON_OK, ALERT_ICON_ERR, ALERT_ICON_SUCC, CONFIRM_NEW_PASS, EMAIL_LABEL, ERR_ALERT_TITLE, ERR_GETTING, INCORRECT_PASSWORD, NEW_PASSWORD_LABEL, PASSWORD_CHANGE_SUCC, PASSWORD_MIN_CHAR, PASSWORD_MISSMATCH, PASSWORD_PLACEHOLDER_FIELD, RESET_PASSWORD_LABEL, RE_ENTER_PASSWORD, SUBMIT_LABEL, TOKEN_EXP } from "../../constants/alertMessage";

class MobileResetpassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      confirm_pass: "",
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleconfirmPasswordChange =
      this.handleconfirmPasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    window.postMessage("renderTable", "*");
  }

  componentDidMount() {
    axios
      .get(
        `${url1}${GET_EMAIL_MOBILE_PATH}/${this.props.match.params.token}/?role=manager`
      )
      .then(
        (res) => {
          if (res.data.result === "success") {
            this.setState({ email: res.data.datas[0].email });
          } else {
            this.setState({ email: "" });
            Swal.fire({
              title: `${TOKEN_EXP}`,
              icon: `${ALERT_ICON_ERR}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        },
        (err) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ ALERT_ICON_ERR }`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ ALERT_BUTTON_OK }`,
          });
        }
      )
      .catch(()=>{
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ ALERT_ICON_ERR }`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ ALERT_BUTTON_OK }`,
        });
      })
  }

  handleemailChange(event) {
    this.setState({ email: event.target.value });
  }
  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }
  handleconfirmPasswordChange(event) {
    this.setState({ confirm_pass: event.target.value });
  }
  handleSubmit(event) {
    if (this.state.password.length < 6) {
      Swal.fire({
        title: {PASSWORD_MIN_CHAR},
        icon: {ALERT_ICON_ERR},
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: {ALERT_BUTTON_OK},
      });
    } else {
      if (this.state.password === this.state.confirm_pass) {
        event.preventDefault();
        axios
          .post(
            url1 + "/mobile/resetpassword/" + this.props.match.params.token,
            {
              password: this.state.password,
              secure_token: this.props.match.params.token,
            }
          )
          .then(
            (res) => {
              Swal.fire({
                title: {PASSWORD_CHANGE_SUCC},
                icon: {ALERT_ICON_SUCC},
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: {ALERT_BUTTON_OK},
              });
              document.location = "/";
            },
            (err) => {
              Swal.fire({
                title: {INCORRECT_PASSWORD},
                icon:{ALERT_ICON_ERR},
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: {ALERT_BUTTON_OK},
              });
            }
          );
      } else {
        Swal.fire({
          title: {PASSWORD_MISSMATCH},
          icon: {ALERT_ICON_ERR},
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: {ALERT_BUTTON_OK},
        });
      }
    }
  }

  render() {
    return (
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="wrapper-page">
                <div className="account-pages">
                  <div className="account-box">
                    <div className="account-logo-box">
                      <h2 className="text-uppercase text-center">
                        <Link to="/" className="text-success">
                          <span>
                            <img src="assets/images/LOGO-MAIN.png" alt="" />
                          </span>
                        </Link>
                      </h2>
                    </div>
                    <div className="account-content">
                      <div className="text-center m-b-20">
                        <h3>{RESET_PASSWORD_LABEL}</h3>
                      </div>
                      <form
                        className="form-horizontal"
                        onSubmit={this.handleSubmit}
                      >
                        <div className="form-group m-b-20 row">
                          <div className="col-12">
                            <label for="emailaddress">{EMAIL_LABEL}</label>
                            <input
                              className="form-control"
                              type="email"
                              id="emailaddress"
                              value={this.state.email}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="form-group row m-b-20">
                          <div className="col-12">
                            <label for="password">{NEW_PASSWORD_LABEL}</label>
                            <input
                              className="form-control"
                              type="password"
                              required=""
                              id="password"
                              placeholder={PASSWORD_PLACEHOLDER_FIELD}
                              onChange={this.handlePasswordChange}
                            />
                          </div>
                        </div>

                        <div className="form-group row m-b-20">
                          <div className="col-12">
                            <label for="password">{CONFIRM_NEW_PASS}</label>
                            <input
                              className="form-control"
                              type="password"
                              required=""
                              id="password"
                              placeholder={RE_ENTER_PASSWORD}
                              onChange={this.handleconfirmPasswordChange}
                            />
                          </div>
                        </div>

                        <div className="form-group row text-center m-t-10">
                          <div className="col-12">
                            <button
                              className="btn btn-block waves-effect waves-light"
                              style={{ backgroundColor: "#11abff" }}
                              type="submit"
                            >
                             {SUBMIT_LABEL}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default MobileResetpassword;
