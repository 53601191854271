import { useState, useEffect } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Cookies from "universal-cookie";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import {
  getBTypes,
  editBType,
  deleteBType,
  addBType,
  searchBTypes,
} from "../../actions/businessProfileAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link } from "react-router-dom";
import { FiTrash2, FiEdit2 } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Modal,
  Button,
  TextField,
  Grid,
  Fade,
  Box,
} from "@mui/material";
import { DELETE, EDIT } from "../../constants/url";
import {
  ALERT_BUTTON_OK,
  CONFIRM_TITLE,
  DELETE_YES_BUTTON,
  SUCC_ADD_BTYPE,
  SUCC_ALERT_TITLE,
} from "../../constants/alertMessage";

const cookies = new Cookies();

const Btype = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [page,setPage]=useState(0)
  const [rowsPerPage,setRowsPerPage]=useState(10)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const handleShow = () => {
    // Reset the values state to initial state for adding a new business type
    setValues({
      ...values,
      name: "", // Reset name field
      error: "",
      header: `${STRING_CONSTANTS.ADD_BUSSINESS_TYPE}`,
      _id: "",
      type: "add", // Set type to "add"
      success: false,
      removed: false,
    });
    setShow(true);
  };
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [values, setValues] = useState({
    name: "",
    error: "",
    header: `${STRING_CONSTANTS.ADD_BUSSINESS_TYPE}`,
    _id: "",
    type: "add",
    types: [],
    success: false,
    removed: false,
  });
  const { name, types, type, _id } = values;
  const agritech_id = cookies.get("agritech_id");

  useEffect(() => {
    loadBType();
  }, []);

  const loadBType = () => {
    getBTypes(agritech_id).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setValues({
          ...values,
          types: data.types,
          _id: "",
          type: "add",
          header: `${STRING_CONSTANTS.ADD_BUSSINESS_TYPE}`,
          name: "",
        });
      }
    });
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value;
    searchBTypes(searchQuery).then((data) => {
      if (!data) {
        return;
      }
      if (data.error) {
        console.log(data.error);
      } else {
        setValues({
          ...values,
          types: data.types || [],
          _id: "",
          type: "add",
          header: `${STRING_CONSTANTS.ADD_BUSSINESS_TYPE}`,
          name: "",
        });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setValues({ ...values, loading: true, error: false });
    const chapter = { name };

    if (type === "add") {
      addBType(chapter).then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error, loading: false, name: "" });
        } else {
          handleClose();
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            text: `${SUCC_ADD_BTYPE}`,
            icon: "success",
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          }).then(() => {
            loadBType();
          });
        }
      });
    } else if (type === "edit") {
      editBType(chapter, _id).then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error, loading: false });
        } else {
          handleClose1();
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            text: `${SUCC_ADD_BTYPE}`,
            icon: "success",
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          }).then(() => {
            loadBType();
          });
        }
      });
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };

  const handleEdit = (row) => {
    setShow1(true);
    setValues({
      ...values,
      error: false,
      name: row.name,
      header: `${STRING_CONSTANTS.EDIT_BUSSINESS_TYPE}`,
      type: "edit",
      _id: row._id,
    });
  };

  const handleDelete = (row) => {
    Swal.fire({
      title: `${CONFIRM_TITLE}`,
      text: "",
      icon: "warning",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      cancelButtonColor: " #3085d6",
      confirmButtonText: `${DELETE_YES_BUTTON}`,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBType(row._id).then((data) => {
          if (data.error) {
            setValues({ ...values, error: data.error, loading: false });
          } else {
            loadBType();
          }
        });
      }
    });
  };

  function actionFormatter(cell, row) {
    return (
      <span>
           {permissions.some(
          (item) => item.keyword === "settingsBTypes" && item.isEdit === true
        ) && (
         <>
          <FiEdit2
          size="18px"
          type="button"
          data-tooltip-id="1"
          data-tooltip-content={`${EDIT}`}
          color="#0978F2"
          onClick={() => handleEdit(row)}
        ></FiEdit2>
        <Tooltip id="1" />
         </>
        )}

{permissions.some(
          (item) => item.keyword === "settingsBTypes" && item.isDelete === true
        ) && (
         <>
         
        <FiTrash2
          type="button"
          size="18px"
          color="red"
          data-tooltip-id="2"
          data-tooltip-content={`${DELETE}`}
          onClick={() => handleDelete(row)}
          style={{ marginLeft: "10px" }}
        ></FiTrash2>
        <Tooltip id="2" />
         </>
        )}
       
      </span>
    );
  }

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="settings" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                      </li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.BUSSINESS_TYPE}</li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.BUSSINESS_TYPE}</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div style={{ width: "98%" }}>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                    {permissions.some(
          (item) => item.keyword === "settingsBTypes" && item.isCreate === true
        ) && (
         <>
    <button
                        className="btn btn-icon waves-effect waves-light btn-primary"
                        onClick={handleShow}
                      >
                        <i className="fa fa-plus"></i> {STRING_CONSTANTS.ADD_BUSSINESS_TYPE}
                      </button>
         </>
        )}
                     
                      <div style={{ width: "200px", position: "relative" }}>
                      {permissions.some(
          (item) => item.keyword === "settingsBTypes" && item.isSearch === true
        ) && (
         <>
  <input
                          type="text"
                          placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                          className="form-control"
                          style={{
                            width: "100%",
                            paddingLeft: "30px",
                            boxSizing: "border-box",
                          }}
                          onChange={(e) => {
                            handleSearch(e);
                            if (e.target.value.trim() === "") {
                              e.target.nextElementSibling.style.display =
                                "inline-block";
                            } else {
                              e.target.nextElementSibling.style.display =
                                "none";
                            }
                          }}
                        />
                        <i
                          className="fas fa-search"
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            pointerEvents: "none",
                            color: "#aaa",
                          }}
                        ></i>
         </>
        )}
                       
                      </div>
                    </div>
                    <Modal
                      open={show}
                      onClose={handleClose}
                      closeAfterTransition
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      disableBackdropClick
                    >
                      <Fade in={show}>
                        <Box
                          sx={{
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 2,
                            width: "60%",
                            height: "250px",
                            marginBottom: "50px",
                          }}
                        >
                          <h3>{values.header}</h3>
                          <form onSubmit={handleSubmit}>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginTop: "20px" }}
                            >
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Business Type"
                                  variant="outlined"
                                  value={name}
                                  onChange={handleChange("name")}
                                  required
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="flex-end"
                              style={{
                                marginTop: "40px",
                                marginBottom: "20px",
                              }}
                            >
                              <Button
                                onClick={handleClose}
                                variant="outlined"
                                color="error"
                                style={{ marginRight: "10px" }}
                              >
                                {STRING_CONSTANTS.CLOSE}
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: "#769A0F" }}
                              >
                                {STRING_CONSTANTS.SUBMIT}
                              </Button>
                            </Grid>
                          </form>
                        </Box>
                      </Fade>
                    </Modal>

                    <Modal
                      open={show1}
                      onClose={handleClose1}
                      closeAfterTransition
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      disableBackdropClick
                    >
                      <Fade in={show1}>
                        <Box
                          sx={{
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 2,
                            width: "60%",
                            height: "250px",
                            marginBottom: "50px",
                          }}
                        >
                          <h3>{values.header}</h3>
                          <form onSubmit={handleSubmit}>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginTop: "20px" }}
                            >
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Business Type"
                                  variant="outlined"
                                  value={name}
                                  onChange={handleChange("name")}
                                  required
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="flex-end"
                              style={{
                                marginTop: "40px",
                                marginBottom: "20px",
                              }}
                            >
                              <Button
                                onClick={handleClose1}
                                variant="outlined"
                                color="error"
                                style={{ marginRight: "10px" }}
                              >
                                {STRING_CONSTANTS.CLOSE}
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: "#769A0F" }}
                              >
                                {STRING_CONSTANTS.UPDATE}
                              </Button>
                            </Grid>
                          </form>
                        </Box>
                      </Fade>
                    </Modal>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ paddingLeft: "100px" }}>
                            {STRING_CONSTANTS.SN_NO}
                            </TableCell>
                            <TableCell>{STRING_CONSTANTS.NAME}</TableCell>
                            <TableCell>{STRING_CONSTANTS.ACTIONS}</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {types.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            ).map((type, index) => (
                            <TableRow key={type._id}>
                              <TableCell style={{ paddingLeft: "100px" }}>
                              {page * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell>{type.name}</TableCell>
                              <TableCell>
                                {actionFormatter(null, type)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={types.length}
                        rowsPerPage={rowsPerPage||0}
                        page={page||0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Btype;
