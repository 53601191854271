import React, { useState, useEffect } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Cookies from "universal-cookie";
import {
  getpending,
  rejectBProfile,
  acceptBProfile,
  rejectAllBprofile,
  approveAllBprofile,
  replayToBProfile,
  searchPending,
} from "../../actions/businessProfileAction";

import Swal from "sweetalert2/dist/sweetalert2.js";
import { FaImage } from "react-icons/fa";
import {
  FiMessageCircle,
  FiXCircle,
  FiCheckCircle,
  FiEye,
} from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Checkbox,
  Grid,
  Button,
  Dialog,
  TextField,
} from "@mui/material";
import { APPROVE, FEED, REJECT, VIEW_DET, VIEW_IMG } from "../../constants/url";
import {
  ALERT_BUTTON_OK,
  ALERT_BUTTON_YES,
  ALERT_ICON_ERR,
  ALERT_ICON_SUCC,
  CONFIRM_TITLE,
  CONFIRM_TXT_ACC_ALL,
  CONFIRM_TXT_REJ_ALL,
  CONFIRM__ACC_ALL_YES_BUTTON,
  CONFIRM__REJ_ALL_YES_BUTTON,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  NO_DATA_FOUND,
  NO_BUS_PEN,
  ERR_POSTING,
  SUCC_APPROVE_TEXT,
  SUCC_APPROVE_TITLE,
  SUCC_REJECTPRODUCT_TITLE,
  SUCC_REJECTPRODUCT_TXT,
  WARNING,
} from "../../constants/alertMessage";
import ImageModal from "../../images/imageModal";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();
const Pending = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [values, setValues] = useState({
    name: "",
    error: "",
    pending: [],
    success: false,
    removed: false,
    selectedRows: [],
  });
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [feedId, setFeedId] = useState("");
  const [details, setDetails] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const { pending, selectedRows } = values;
  const agritech_id = cookies.get("agritech_token");
  const [replay, setReplay] = useState("");
  useEffect(() => {
    loadBType();
  }, []);

  const loadBType = () => {
    getpending(agritech_id)
      .then((data) => {
        if (data.length===0) {
          Swal.fire({
            title: `${NO_DATA_FOUND}`,
            icon: "info",
            text: `${NO_BUS_PEN}`,
            allowOutsideClick: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        } else {
          setValues({ ...values, pending: data.pending });
        }
      })
      .catch(() => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ ALERT_ICON_ERR }`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ ALERT_BUTTON_OK }`,
        });
      });
  };

  const handleSearch = async (e) => {
    const searchValue = e.target.value;

    try {
      const response = await searchPending(searchValue);

      if (response && response.pending) {
        setValues((prevState) => ({
          ...prevState,
          pending: response.pending,
        }));
      } else {
        setValues((prevState) => ({
          ...prevState,
          pending: [],
        }));
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ ALERT_ICON_ERR }`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ ALERT_BUTTON_OK }`,
      });
    }
  };

  const handleChange = (name) => (e) => {
    setReplay(e.target.value);
  };
  const handleReplay = (e) => {
    e.preventDefault();

    replayToBProfile(feedId, replay)
      .then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error, loading: false });
          // Show error message
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ ALERT_ICON_ERR }`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ ALERT_BUTTON_OK }`,
          });
        } else {
          setOpen2(false);
          setReplay("");
          loadBType();
          // Show success message
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ ALERT_ICON_ERR }`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ ALERT_BUTTON_OK }`,
          });
        }
      })
      .catch((err) => {
        // Show error message for replayToBProfile failure
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ ALERT_ICON_ERR }`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ ALERT_BUTTON_OK }`,
        });
      });
  };

  const handleCheckBox = (row) => {
    const { selectedRows } = values;
    const selectedIndex = selectedRows.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, row);
    } else {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setValues({ ...values, selectedRows: newSelected });
  };

  function edit(cell, row, extraData, index) {
    const handleImageClick = () => {
      const imagesData = [row.image1, row.image2, row.image3, row.image4];

      const filteredImagesData = imagesData.filter(
        (image) => image !== undefined && image !== null && image !== ""
      );

      setImages(filteredImagesData);
      setOpen(true);
    };

    const hasImageData = [row.image1, row.image2, row.image3, row.image4].some(
      (image) => image !== undefined && image !== null && image !== ""
    );

    const handleClick = (e) => {
      Swal.fire({
        title: `${APPROVE}${row.company_name}`,
        text: "",
        icon: `${ALERT_ICON_SUCC}`,
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      })
        .then((result) => {
          if (result.isConfirmed) {
            acceptBProfile(agritech_id, row.id).then((data) => {
              if (data.error) {
                setValues({ ...values, error: data.error, loading: false });
              } else {
                loadBType();
              }
            });
          }
        })
        .catch(() => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ ALERT_ICON_ERR }`,
            text: `${ERR_POSTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ ALERT_BUTTON_OK }`,
          });
        });
    };

    const handleRejectClick = (e) => {
      Swal.fire({
        title: `${REJECT} ${row.company_name}`,
        icon: `${ALERT_ICON_ERR}`,
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      })
        .then((result) => {
          if (result.isConfirmed) {
            rejectBProfile(agritech_id, row.id).then((data) => {
              if (data.error) {
                setValues({ ...values, error: data.error, loading: false });
              } else {
                loadBType();
              }
            });
          }
        })
        .catch(() => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ ALERT_ICON_ERR }`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ ALERT_BUTTON_OK }`,
          });
        });
    };

    const handlePOP = (e) => {
      setDetails(row);
      setOpen1(true);
    };

    const handleS = (e) => {
      // Perform the state updates only if confirmed
      setFeedId(row.id);
      setOpen2(true);
    };

    return (
      <span>
        {permissions.some(
          (item) =>
            item.keyword === "businessProfilesPendingList" &&
            item.isApprove === true
        ) && (
          <>
            <i data-tooltip-id="1" data-tooltip-content={`${APPROVE}`}>
              <FiCheckCircle onClick={handleClick} color="#4fbde9" size="20" />
            </i>
            <Tooltip id="1" />
          </>
        )}
        {permissions.some(
          (item) =>
            item.keyword === "businessProfilesPendingList" &&
            item.isReject === true
        ) && (
          <>
            <i
              className="ml-1"
              data-tooltip-id="2"
              data-tooltip-content={`${REJECT}`}
            >
              {" "}
              <FiXCircle
                onClick={handleRejectClick}
                color="#f36270"
                size="20"
              />
            </i>
            <Tooltip id="2" />
          </>
        )}

        {permissions.some(
          (item) =>
            item.keyword === "businessProfilesPendingList" &&
            item.isFeedback === true
        ) && (
          <>
            {row.replay !== "" ? (
              <i
                className="ml-1"
                data-tooltip-id="3"
                data-tooltip-content={`${FEED}`}
              >
                {" "}
                <FiMessageCircle onClick={handleS} color="#769A0F" size="20" />
                <Tooltip id="3" />
              </i>
            ) : (
              <i
                className="ml-1"
                data-tooltip-id="3"
                style={{ fontStyle: "normal" }}
                data-tooltip-content={`${FEED}`}
              >
                {" "}
                <FiMessageCircle onClick={handleS} color="#f9bc0b" size="20" />
                <Tooltip id="3" />
              </i>
            )}
          </>
        )}
        <div
          className="modal fade bs-example-modal-center"
          id={"pop" + row.id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {STRING_CONSTANTS.FEEDBACK_LABEL}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={handleReplay}>
                <div className="modal-body">
                  <div className="row  justify-content-center text-center">
                    <div className="col-12">
                      <div className="text-left text-dark mb-1"> {STRING_CONSTANTS.MESSAGE}</div>
                      <textarea
                        type="text"
                        className="form-control"
                        value={replay}
                        placeholder={STRING_CONSTANTS.MESSAGE}
                        name="price"
                        onChange={handleChange("replay")}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    {STRING_CONSTANTS.CLOSE}
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {STRING_CONSTANTS.SEND}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <i
          className="ml-1"
          data-tooltip-id="4"
          data-tooltip-content={`${VIEW_DET}`}
        >
          {" "}
          <FiEye onClick={handlePOP} color="#9368f3" size="20" />
        </i>
        <Tooltip id="4" />

        {hasImageData && (
          <i
            className="ml-1"
            data-tooltip-id="5"
            data-tooltip-content={`${VIEW_IMG}`}
          >
            {" "}
            <FaImage onClick={handleImageClick} color="green" size="17" />
          </i>
        )}
        <Tooltip id="5" />
      </span>
    );
  }

  function acceptAll(event) {
    event.preventDefault();

    Swal.fire({
      title: `${CONFIRM_TITLE}`,
      text: `${CONFIRM_TXT_ACC_ALL}`,
      icon: `${WARNING}`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${CONFIRM__ACC_ALL_YES_BUTTON}`,
    })
      .then((result) => {
        if (result.isConfirmed) {
          approveAllBprofile(selectedRows).then((data) => {
            if (data.error) {
              setValues({ ...values, error: data.error, loading: false });
              // Show error message
              Swal.fire({
                title: `${ERR_ALERT_TITLE}`,
                text: data.error,
                icon: `${ALERT_ICON_ERR}`,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            } else {
              var mainData = pending;
              var sin = selectedRows;

              for (var i = 0; i < mainData.length; i++) {
                for (var j = 0; j < sin.length; j++) {
                  if (mainData[i].id === sin[j]) {
                    mainData.splice(i, 1);
                  }
                }
              }
              setValues({ ...values, selectedRows: [], data: mainData });

              // Show success message
              Swal.fire({
                title: `${SUCC_APPROVE_TITLE}`,
                text: `${SUCC_APPROVE_TEXT}`,
                icon: `${ALERT_ICON_SUCC}`,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
              loadBType();
            }
          });
        }
      })
      .catch((error) => {
        // Show error message
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_POSTING}`,
          icon: `${ALERT_ICON_ERR}`,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  }
  function rejectAll(event) {
    event.preventDefault();

    Swal.fire({
      title: `${CONFIRM_TITLE}`,
      text: `${CONFIRM_TXT_REJ_ALL}`,
      icon: `${WARNING}`,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: `${CONFIRM__REJ_ALL_YES_BUTTON}`,
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, execute rejectAll logic
        rejectAllBprofile(selectedRows)
          .then((data) => {
            if (data.error) {
              setValues({ ...values, error: data.error, loading: false });
              // Show error message
              Swal.fire({
                title: `${ERR_ALERT_TITLE}`,
                text: data.error,
                icon: `${ALERT_ICON_ERR}`,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            } else {
              var mainData = pending;
              var sin = selectedRows;

              for (var i = 0; i < mainData.length; i++) {
                for (var j = 0; j < sin.length; j++) {
                  if (mainData[i].id === sin[j]) {
                    mainData.splice(i, 1);
                  }
                }
              }
              setValues({ ...values, selectedRows: [], data: mainData });

              // Show success message
              Swal.fire({
                title: `${SUCC_REJECTPRODUCT_TITLE}`,
                text: `${SUCC_REJECTPRODUCT_TXT}`,
                icon: `${ALERT_ICON_SUCC}`,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
              loadBType();
            }
          })
          .catch((error) => {
            // Show error message
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              text: `${ERR_POSTING}`,
              icon: `${ALERT_ICON_ERR}`,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          });
      }
    });
  }

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="businessProfiles" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {" "}
                        <a href="/dashboard">{STRING_CONSTANTS.DASHBOARD}</a>
                      </li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.PENDING_LIST}</li>
                    </ol>
                  </div>
                  <h4 className="page-title">
                    {STRING_CONSTANTS.BP_PENDING_LIST}
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div style={{ width: "98%" }}>
                    <div className="row mb-3">
                      <div className="box1 ml-2">
                        <div id="toolbar">
                          {permissions.some(
                            (item) =>
                              item.keyword === "businessProfilesPendingList" &&
                              item.isApprove === true
                          ) && (
                            <form onSubmit={acceptAll}>
                              {selectedRows.length === 0 ? (
                                <button
                                  type="submit"
                                  id="button"
                                  className="btn btn-icon waves-effect waves-light btn-info"
                                  disabled
                                >
                                  {" "}
                                  <i className="fa fa-check"></i> {STRING_CONSTANTS.APPROVE}
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  id="button"
                                  className="btn btn-icon waves-effect waves-light btn-info"
                                >
                                  {" "}
                                  <i className="fa fa-check"></i> {STRING_CONSTANTS.APPROVE}
                                </button>
                              )}
                            </form>
                          )}
                        </div>
                      </div>

                      <div className="box1 ml-2">
                        <div id="toolbar">
                          {permissions.some(
                            (item) =>
                              item.keyword === "businessProfilesPendingList" &&
                              item.isReject === true
                          ) && (
                            <form onSubmit={rejectAll}>
                              {selectedRows.length === 0 ? (
                                <button
                                  id="remove"
                                  type="submit"
                                  className="btn btn-icon waves-effect waves-light btn-danger"
                                  disabled
                                >
                                  {" "}
                                  <i className="fa fa-times"></i> {STRING_CONSTANTS.REJECT}
                                </button>
                              ) : (
                                <button
                                  id="remove"
                                  type="submit"
                                  className="btn btn-icon waves-effect waves-light btn-danger"
                                >
                                  {" "}
                                  <i className="fa fa-times"></i> {STRING_CONSTANTS.REJECT}
                                </button>
                              )}
                            </form>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          marginLeft: "auto",
                          width: "200px",
                          marginRight: "40px",
                          position: "relative",
                        }}
                      >
                        <input
                          type="text"
                          placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                          className="form-control"
                          style={{
                            width: "100%",
                            paddingLeft: "30px",
                            boxSizing: "border-box",
                          }}
                          onChange={(e) => {
                            handleSearch(e);
                            if (e.target.value.trim() === "") {
                              e.target.nextElementSibling.style.display =
                                "inline-block";
                            } else {
                              e.target.nextElementSibling.style.display =
                                "none";
                            }
                          }}
                        />
                        <i
                          className="fas fa-search"
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            pointerEvents: "none",
                            color: "#aaa",
                          }}
                        ></i>
                      </div>
                    </div>
                    <div style={{ width: "98%" }}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">
                                <Checkbox
                                  style={{ transform: "scale(0.7)" }}
                                  align="left"
                                  checked={
                                    pending.length > 0 &&
                                    selectedRows.length === pending.length
                                  }
                                  onChange={() => {
                                    const newSelected =
                                      pending.length > 0 &&
                                      selectedRows.length !== pending.length
                                        ? pending
                                        : [];
                                    setValues({
                                      ...values,
                                      selectedRows: newSelected,
                                    });
                                  }}
                                />
                              </TableCell>
                              <TableCell align="left">{STRING_CONSTANTS.SN_NO}</TableCell>
                              <TableCell align="left">{STRING_CONSTANTS.CUSTOMER_NAME}</TableCell>
                              <TableCell align="left">
                                {STRING_CONSTANTS.CUSTOMER_MOBILE}
                              </TableCell>
                              <TableCell align="left">{STRING_CONSTANTS.COMPANY_NAME}</TableCell>
                              <TableCell align="left">{STRING_CONSTANTS.BUSSINESS_TYPE}</TableCell>
                              <TableCell align="left">{STRING_CONSTANTS.ACTIONS}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pending.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            ).map((row, index) => (
                              <TableRow key={index}>
                                <TableCell align="left">
                                  <Checkbox
                                    style={{ transform: "scale(0.7)" }}
                                    checked={selectedRows.indexOf(row) !== -1}
                                    onChange={() => handleCheckBox(row)}
                                  />
                                </TableCell>

                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">
                                  {row.customer_mobile_no}
                                </TableCell>
                                <TableCell align="left">
                                  {row.company_name}
                                </TableCell>
                                <TableCell align="left">{row.btype}</TableCell>

                                <TableCell align="left">
                                  {edit(row.cell, row, index)}
                                </TableCell>
                              </TableRow>
                            ))}
                            <ImageModal
                              handleClose={handleClose}
                              open={open}
                              imageSrc={images}
                            ></ImageModal>

                            <Dialog
                              open={open1}
                              onClose={handleClose1}
                              aria-labelledby="details-modal-title"
                              aria-describedby="details-modal-description"
                              style={{
                                maxHeight: "80vh",
                                overflowY: "auto",
                                marginTop: "100px",
                              }}
                              fullWidth
                              disableEnforceFocus
                              maxWidth="lg"
                            >
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className="modal-content"
                              >
                                <Grid item xs={12} className="modal-header">
                                  <h5 id="details-modal-title">{STRING_CONSTANTS.DETAILS}</h5>
                                  <Button
                                    onClick={handleClose1}
                                    color="inherit"
                                  >
                                    {" "}
                                    <span
                                      style={{ fontSize: "20px" }}
                                      aria-hidden="true"
                                    >
                                      &#128473;
                                    </span>
                                  </Button>
                                </Grid>
                                <Grid item xs={12} className="modal-body">
                                  <Table className="col-12">
                                    <TableRow>
                                      <TableCell className="text-left">
                                       {STRING_CONSTANTS.CUSTOMER_NAME}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {details.name}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.CUSTOMER_MOBILE}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {details.customer_mobile_no}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.BUSSINESS_TYPE}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {details.btype}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.COMPANY_NAME}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {details.company_name}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.DESCRIPTION}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {details.description}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.CONTACT_PERSON}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {details.contact_person}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.CONTACT_NUMBER}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {details.contact_number}
                                      </TableCell>
                                    </TableRow>

                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.LOCATION}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {details.address}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.CHARGES}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {details.charges}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.DELEVERY_AVAILABLE}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {details.deliveryAvailable}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.FREE_DELEVERY}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {details.freeDelivery}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                       {STRING_CONSTANTS.CHARGES_AFTER}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {details.chargesAfter}
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </Grid>
                                <Grid item xs={12} className="modal-footer">
                                  <Button
                                    onClick={handleClose1}
                                    variant="outlined"
                                    color="error"
                                  >
                                    {STRING_CONSTANTS.CLOSE}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Dialog>

                            <Dialog
                              open={open2}
                              onClose={handleClose2}
                              maxWidth="lg"
                              fullWidth
                              centered
                              disableEnforceFocus
                            >
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  padding: "20px",
                                  // minWidth: '500px',
                                  borderRadius: "8px",
                                }}
                              >
                                <h3>{STRING_CONSTANTS.FEEDBACK_LABEL}</h3>
                                <form onSubmit={handleReplay}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <div className="text-left text-dark mb-1">
                                        {STRING_CONSTANTS.MESSAGE}
                                      </div>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        value={replay}
                                        placeholder={STRING_CONSTANTS.MESSAGE}
                                        onChange={handleChange()}
                                        required
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    justifyContent="flex-end"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={handleClose2}
                                      style={{ marginRight: "8px" }}
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      style={{
                                        backgroundColor: "#769A0F",
                                        color: "#fff",
                                      }}
                                    >
                                      {STRING_CONSTANTS.SEND}
                                    </Button>
                                  </Grid>
                                </form>
                              </div>
                            </Dialog>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={pending.length}
                        rowsPerPage={rowsPerPage||0}
                        page={page||0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pending;
