import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { formatDate } from "../utils/dateUtils";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import { styled } from "@mui/system";
import { STRING_CONSTANTS } from "../constants/stringConstants";
import { privateApiCall } from "../api/privateApi";

const InvoiceContainer = styled(Paper)({
  padding: "20px",
  margin: "20px auto",
  backgroundColor: "#fff",
  maxWidth: "800px",
  position: "relative",
  overflowX: "auto", // Ensures table fits within the container
});

const InvoiceHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "",
});

const CompanyLogo = styled("img")({
  width: "250px",
  height: "auto",
  marginRight: "20px",
});

const InvoiceTitle = styled("h4")({
  margin: 0,
  textAlign: "right",
  position: "relative",
  top: "-90px", // Adjust this value to move it up
});

const CompanyInfoSection = styled("div")({
  flex: 1,
  marginBottom: "20px",
  marginRight: "10px",
  wordWrap: "break-word",
  whiteSpace: "normal",
  maxWidth: "350px",
});

const InvoiceDetailsSection = styled("div")({
  display: "flex",
  justifyContent: "space-between", // Align content to space between the container
  marginBottom: "20px",
});

const AddressSection = styled("div")({
  flex: 1,
  marginRight: "10px",
  "&:last-child": {
    marginRight: 0,
  },
});

const BillToSection = styled(AddressSection)({
  textAlign: "right", // Align Bill To section text to the right
});

const TableHeadStyled = styled(TableHead)({
  backgroundColor: "#f0f0f0",
});

const InfoText = styled(Typography)({
  marginBottom: "20px", // Add space between each info line
});

const InvoiceDetails = () => {
  const { id } = useParams();

  const [invoiceItems, setInvoiceItems] = useState([]);

  useEffect(() => {
    fetchInvoiceDetails();
  }, []);

  const fetchInvoiceDetails = async () => {
    const requestUrl = `/app/invoiceDetails/${id}`;
    const response = await privateApiCall(requestUrl, "GET");

    setInvoiceItems(response.data.invoiceData);
  };

  const downloadPdf = () => {
    var element = document.getElementById("invoice-container"); 
    var opt = {
      margin: [0, 0, 0, 0], // Adjust margins as needed
      filename: "invoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" }, 
      pagebreak: { mode: ["avoid-all", "css", "legacy"] }, // Ensure page breaks are handled correctly
    };

    html2pdf()
      .set(opt)
      .from(element)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        // Scale the content to fit within one page
        var totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          
          // Get the width of the page
          const pageWidth = pdf.internal.pageSize.getWidth();
          
          // Define the text to display
          const pageNumberText = `Page ${i} of ${totalPages}`;
          
          // Get the width of the text
          const textWidth = pdf.getTextWidth(pageNumberText);
          
          // Calculate x position to align the text to the right
          const xPosition = pageWidth - textWidth - 0.5; // Adding some margin
          
          // Increase y position slightly to lower the text
          const yPosition = pdf.internal.pageSize.height - 0.3; // Adjust as needed
          
          // Add the text at the calculated position
          pdf.text(pageNumberText, xPosition, yPosition);
      }
      
      
        // Convert the PDF to a data URL
        const pdfDataUrl = pdf.output("datauristring");

        // Use html2canvas to convert the HTML element to an image
        html2canvas(element).then((canvas) => {
          const imageData = canvas.toDataURL("image/jpeg", 0.98); // Adjust the quality if needed

          // Save the image to local storage
          localStorage.setItem("invoiceImage", imageData);

          // Convert imageData (data URL) to Blob
          const blob = dataURLToBlob(imageData);

          // Save the PDF file as usual
          pdf.save("invoice.pdf");

          // Send the Blob to the server
          sendImageToServer(blob, invoiceItems._id);
        });
      });
  };

  // Function to convert data URL to Blob
  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  // Function to send the image Blob to the server
  const sendImageToServer = async (blob, invoiceId) => {
    const requestUrl = `/invoice_pdf_save`;
    const formData = new FormData();

    // Append the blob to FormData
    formData.append("image1", blob, `invoice.jpeg`); // Change the filename as needed

    formData.append("invoiceId", invoiceId);
    try {
      const response = await privateApiCall(requestUrl, "POST", formData);
      if (response.status === 200) {
        console.log("Image uploaded successfully");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <InvoiceContainer id="invoice-container">
        <InvoiceHeader>
          <CompanyLogo src="/assets/images/radtar.png" alt="Logo" />
          <InvoiceTitle>{STRING_CONSTANTS.INVOICE}</InvoiceTitle>
        </InvoiceHeader>

        <InvoiceDetailsSection>
          {invoiceItems.soldBy && (
            <CompanyInfoSection>
              <Typography variant="body1">
                <strong>{STRING_CONSTANTS.SOLD_BY}:</strong>{" "}
                {invoiceItems.soldBy.companyName}
                <InfoText>
                  <div>{invoiceItems.soldBy.address}</div>
                </InfoText>
                <InfoText variant="body1">
                  <div>
                    <strong>{STRING_CONSTANTS.GST_NO}:</strong>{" "}
                    {invoiceItems.gstNumber}
                  </div>
                </InfoText>
              </Typography>
              <Typography>
                <div style={{ marginTop: "70px" }}>
                  <strong>{STRING_CONSTANTS.ORD_ID}:</strong>{" "}
                  {invoiceItems.orderDetails.orderNumber}
                </div>
                <div>
                  <strong>{STRING_CONSTANTS.ORDER_DATE}:</strong>{" "}
                  {formatDate(invoiceItems.orderDetails.orderDate)}
                </div>
              </Typography>
            </CompanyInfoSection>
          )}
          {invoiceItems.shippingAddress && (
            <BillToSection>
              <Typography variant="body1">
                <strong>{STRING_CONSTANTS.BILLING_ADD}:</strong>
                <InfoText>
                  <div>{invoiceItems.billingAddress.address1},</div>
                  <div>{invoiceItems.billingAddress.address2},</div>
                  <div>{invoiceItems.billingAddress.landMark},</div>
                  <div>{invoiceItems.billingAddress.city},</div>
                  <div>{invoiceItems.billingAddress.pinCode},</div>
                  <div>{invoiceItems.billingAddress.state}</div>
                </InfoText>
              </Typography>
              <InfoText>
                <Typography variant="body1">
                  <strong>{STRING_CONSTANTS.SHIPPING_ADDRESS}:</strong>
                  <div>{invoiceItems.shippingAddress.address1},</div>
                  <div>{invoiceItems.shippingAddress.address2},</div>
                  <div>{invoiceItems.shippingAddress.landMark},</div>
                  <div>{invoiceItems.shippingAddress.city},</div>
                  <div>{invoiceItems.shippingAddress.pinCode},</div>
                  <div>{invoiceItems.shippingAddress.state}</div>
                </Typography>
              </InfoText>
              <InfoText>
                <Typography variant="body1">
                  <strong>{STRING_CONSTANTS.INVOICE_ID}:</strong>{" "}
                  {invoiceItems._id}
                </Typography>
                <Typography variant="body1">
                  <strong>{STRING_CONSTANTS.INVOICE_DATE}:</strong>{" "}
                  {formatDate(invoiceItems.orderDetails.invoiceDate)}
                </Typography>
              </InfoText>
            </BillToSection>
          )}
        </InvoiceDetailsSection>

        <Table sx={{ border: "1px solid black", borderCollapse: "collapse" }}>
          <TableHeadStyled>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid black",
                  textAlign: "start",
                  padding: "1px", // Aligned to the start (left)
                }}
              >
                {STRING_CONSTANTS.SN_NO}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid black",
                  textAlign: "center",
                  padding: "1px",
                }}
              >
                {STRING_CONSTANTS.DESCRIPTION}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid black",
                  textAlign: "start",
                  padding: "1px",
                }}
              >
                {STRING_CONSTANTS.HSN_CODE}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid black",
                  textAlign: "start",
                  padding: "1px",
                }}
              >
                {STRING_CONSTANTS.UNIT_PRICE}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid black",
                  textAlign: "start",
                  padding: "1px",
                }}
              >
                {STRING_CONSTANTS.DISCOUNT}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid black",
                  textAlign: "start",
                  padding: "1px",
                }}
              >
                {STRING_CONSTANTS.QTY}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid black",
                  textAlign: "start",
                  padding: "1px",
                }}
              >
                {STRING_CONSTANTS.NET_AMOUNT}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid black",
                  textAlign: "start",
                  padding: "1px",
                }}
              >
                {STRING_CONSTANTS.TAX_RATE}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid black",
                  textAlign: "start",
                  padding: "1px",
                }}
              >
                {STRING_CONSTANTS.TAX_TYPE}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid black",
                  textAlign: "start",
                  padding: "1px",
                }}
              >
                {STRING_CONSTANTS.TAX_AMOUNT}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid black",
                  textAlign: "start",
                  padding: "1px",
                }}
              >
                {STRING_CONSTANTS.TOTAL_AMOUNT}
              </TableCell>
            </TableRow>
          </TableHeadStyled>

          <TableBody>
            {invoiceItems.items &&
              invoiceItems.items.map((item, index) => (
                <TableRow style={{ maxWidth: "30px" }}>
                  <TableCell
                    sx={{
                      border: "1px solid black",
                      textAlign: "start",
                      padding: "1px",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid black",
                      textAlign: "start",
                      padding: "1px",
                    }}
                  >
                    {item.description}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid black",
                      textAlign: "start",
                      padding: "1px",
                    }}
                  >
                    {item.hsnCode}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "5%",
                      minWidth: "50px",
                      border: "1px solid black",
                      textAlign: "start",
                      padding: "1px",
                    }}
                  >
                    {item.unitPrice}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid black",
                      textAlign: "start",
                      padding: "1px",
                    }}
                  >
                    {item.discount}
                  </TableCell>

                  <TableCell
                    sx={{
                      border: "1px solid black",
                      textAlign: "start",
                      padding: "1px",
                    }}
                  >
                    {item.quantity}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid black",
                      textAlign: "start",
                      padding: "1px",
                    }}
                  >
                    {item.unitPrice}
                  </TableCell>

                  <TableCell
                    sx={{
                      border: "1px solid black",
                      textAlign: "start",
                      padding: "1px",
                    }}
                  >
                    {item.taxes &&
                      item.taxes.map((tax, taxIndex) => (
                        <div>{tax.taxRate}%</div>
                      ))}
                  </TableCell>

                  <TableCell
                    sx={{
                      border: "1px solid black",
                      textAlign: "start",
                      padding: "1px",
                    }}
                  >
                    {item.taxes &&
                      item.taxes.map((tax, taxIndex) => (
                        <div>{tax.taxType}</div>
                      ))}
                  </TableCell>

                  <TableCell
                    sx={{
                      border: "1px solid black",
                      textAlign: "start",
                      padding: "1px",
                    }}
                  >
                    {item.taxes &&
                      item.taxes.map((tax, taxIndex) => (
                        <div>{tax.taxAmount}</div>
                      ))}
                  </TableCell>

                  <TableCell
                    sx={{
                      border: "1px solid black",
                      textAlign: "start",
                      padding: "1px",
                    }}
                  >
                    {item.totalAmount}
                  </TableCell>
                </TableRow>
              ))}

            {invoiceItems.totals && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  sx={{
                    borderBottom: "1px solid black",
                    textAlign: "start",
                    padding: "1px",
                  }}
                >
                  Total:
                </TableCell>
                <TableCell sx={{ border: "1px solid black", padding: "1px" }}>
                  {invoiceItems.totals.totalTax}
                </TableCell>
                <TableCell sx={{ border: "1px solid black", padding: "1px" }}>
                  {invoiceItems.totals.totalAmount}
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell
                colSpan={9}
                sx={{
                  borderBottom: "1px solid black",
                  textAlign: "start",
                  padding: "1px",
                }}
              >
                Amount in words:
                <div style={{ minHeight: "30px" }}></div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                colSpan={11}
                sx={{
                  border: "1px solid black",
                  textAlign: "end",
                  padding: "1px",
                }}
              >
                For RADTAR INNOVATIONS PRIVATE LIMITED :
                <div style={{ minHeight: "60px" }}></div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Table
          style={{
            marginTop: "10px",
          }}
        >
          <TableBody>
            {invoiceItems.paymentDetails && (
              <TableRow>
                <TableCell
                  colSpan={3}
                  sx={{
                    border: "1px solid black",
                    textAlign: "start",
                    padding: "1px",
                  }}
                >
                  Payment Transaction ID:
                  <div>{invoiceItems.paymentDetails.transactionId}</div>
                </TableCell>
                <TableCell
                  colSpan={3}
                  sx={{
                    border: "1px solid black",
                    textAlign: "start",
                    padding: "1px",
                  }}
                >
                  Date
                  <div>
                    {formatDate(invoiceItems.paymentDetails.paymentDateTime)}
                  </div>
                </TableCell>
                <TableCell
                  colSpan={3}
                  sx={{
                    border: "1px solid black",
                    textAlign: "start",
                    padding: "1px",
                  }}
                >
                  Invoice Value:
                  <div>{invoiceItems.paymentDetails.invoiceValue}</div>
                </TableCell>
                <TableCell
                  colSpan={3}
                  sx={{
                    border: "1px solid black",
                    textAlign: "start",
                    padding: "1px",
                  }}
                >
                  Mode of Payment:
                  <div>{invoiceItems.paymentDetails.paymentMode}</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </InvoiceContainer>
      <button onClick={downloadPdf}>Download PDF</button>
    </>
  );
};

export default InvoiceDetails;
