import React from 'react';

class Descliamer extends React.Component {
  render() {
    return (
        <div>
          <link href="https://getbootstrap.com/docs/4.0/dist/css/bootstrap.min.css" rel="stylesheet" />
          <div className="container">
          <p style={{margin: '0px 0px 5px', textAlign: 'center', fontStretch: 'normal', fontSize: '18px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><strong>DISCLAIMER</strong></p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>Last updated: January 16, 2024</p><br/>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><strong>WEBSITE DISCLAIMER</strong></p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>The information provided by <strong>RURAL AGRICULTURAL DEVELOPMENT TECHNOLOGY AND RESEARCH (RADTAR)</strong> (“Company”, “we”, “our”, “us”) on <strong>www.jackfruitworld.com</strong> (the “Site”) is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><strong>EXTERNAL LINKS DISCLAIMER</strong></p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><strong>PROFESSIONAL DISCLAIMER</strong></p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>The Site can not and does not contain financial advice. The information is provided for general informational and educational purposes only and is not a substitute for professional financial advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of financial advice.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>Content published on www.jackfruitworld.com is intended to be used and must be used for informational purposes only. It is very important to do your own analysis before making any decision based on your own personal circumstances. You should take independent financial advice from a professional or independently research and verify any information that you find on our Website and wish to rely upon.&nbsp;</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THIS SITE IS SOLELY AT YOUR OWN RISK.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><strong>AFFILIATES DISCLAIMER</strong></p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>The Site may contain links to affiliate websites, and we may receive an affiliate commission for any purchases or actions made by you on the affiliate websites using such links.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><strong>TESTIMONIALS DISCLAIMER</strong></p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume that all users will have the same experiences.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>YOUR INDIVIDUAL RESULTS MAY VARY.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity, where the full testimonial contained extraneous information not relevant to the general public.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><strong>ERRORS AND OMISSIONS DISCLAIMER</strong></p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>While we have made every attempt to ensure that the information contained in this site has been obtained from reliable sources, RURAL AGRICULTURAL DEVELOPMENT TECHNOLOGY AND RESEARCH (RADTAR) is not responsible for any errors or omissions or for the results obtained from the use of this information. All information in this site is provided “as is”, with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability, and fitness for a particular purpose.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>In no event will RURAL AGRICULTURAL DEVELOPMENT TECHNOLOGY AND RESEARCH (RADTAR), its related partnerships or corporations, or the partners, agents or employees thereof be liable to you or anyone else for any decision made or action taken in reliance on the information in this Site or for any consequential, special or similar damages, even if advised of the possibility of such damages.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><strong>GUEST CONTRIBUTORS DISCLAIMER</strong></p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>This Site may include content from guest contributors and any views or opinions expressed in such posts are personal and do not represent those of RURAL AGRICULTURAL DEVELOPMENT TECHNOLOGY AND RESEARCH (RADTAR) or any of its staff or affiliates unless explicitly stated.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><strong>LOGOS AND TRADEMARKS DISCLAIMER</strong></p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>All logos and trademarks of third parties referenced on www.jackfruitworld.com are the trademarks and logos of their respective owners. Any inclusion of such trademarks or logos does not imply or constitute any approval, endorsement or sponsorship of RURAL AGRICULTURAL DEVELOPMENT TECHNOLOGY AND RESEARCH (RADTAR) by such owners.</p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><strong>CONTACT US</strong></p>
        <p style={{margin: '0px 0px 5px',textAlign: 'justify', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>Should you have any feedback, comments, requests for technical support or other inquiries, please contact us by email: <strong>jackfruitworld@gmail.com</strong>.</p>
        <p><br /></p>
      </div>
        </div>
      );
    
    
  }
}

export default Descliamer;
