

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { API } from "../config";
import { Helmet } from "react-helmet";

const styles = {
  productPage: {
    maxWidth: "600px",
    margin: "0 auto",
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  productHeader: {
    backgroundColor: "rgb(118, 154, 15)",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
  },
  backButton: {
    background: "none",
    border: "none",
    color: "white",
    cursor: "pointer",
    fontSize: "18px",
  },
  shareButton: {
    background: "none",
    border: "none",
    color: "white",
    cursor: "pointer",
    fontSize: "18px",
  },

  installSection: {
    marginBottom: "15px",
  },
  playstoreButton: {
    backgroundColor: "white",
    color: "limegreen",
    border: "none",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  productDetails: {
    padding: "20px",
  },
  productInfo: {
    marginBottom: "20px",
  },
  statusIcon: {
    marginLeft:"6px",
    width: '20px',
    height: '20px',
    objectFit: 'cover',
  },
  price: {
    color: "limegreen",
    fontSize: "22px",
  },
  distance: {
    color: "gray",
    marginBottom: "10px",
    marginTop: "10px",
  },
  productTags: {
    display: "flex",
    gap: "10px",
  },
  productTag: {
    backgroundColor: "lightgray",
    padding: "5px",
    borderRadius: "5px",
  },
  productMeta: {
    margin: "5px 0",
  },
  bidSection: {
    marginTop: "20px",
  },
  bidInput: {
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  bidButton: {
    backgroundColor: "rgb(118, 154, 15)",
    color: "white",
    border: "none",
    padding: "10px",
    width: "100%",
    borderRadius: "5px",
    cursor: "pointer",
  },
  bidButtonHover: {
    backgroundColor: "#45a049",
  },

  label: {
    fontWeight: "500",
  },
  logo: {
    height: "50px",
    width: "auto",
  },
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "15px",
    margin: "20px 0",
    justifyContent: "center",
  },
  image: {
    width: "220px",
    height: "220px",
    objectFit: "cover",
    borderRadius: "10px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease",
  },
  iconGreen: {
    color: "#28a745",
    fontSize: "20px",
  },
  iconRed: {
    color: "#dc3545",
    fontSize: "20px",
  },
  linkButton: {
    display: "inline-block",
    padding: "10px 20px",
    margin: "10px 0",
    borderRadius: "5px",
    color: "#ffffff",
    textDecoration: "none",
    fontWeight: "bold",
    textAlign: "center",
    width: "100%",
  },
  googlePlayButton: {
    backgroundColor: "#34A853", // Google Play Store green color
  },
  appleStoreButton: {
    backgroundColor: "#000000", // Apple Store black color
  },
};

const ViewProductDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("_id");
  const type = queryParams.get("type");
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = useState(false); // State to manage modal visibility
  const [dimage, setDimage] = useState("/assets/images/jack_logo.png");
  const handlePurchase = () => {
    setOpen(true); // Open the modal when the purchase button is clicked
  };

  const handleClose = () => {
    setOpen(false); // Close the modal
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `${API}/api/user/view_product?_id=${id}&type=${type}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch product data");
        }
        const data = await response.json();
        setProduct(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();


    // const title = 'My Page Title';
    // const description = 'A brief description of my page.';
    // const image = 'https://example.com/my-image.jpg';

    // const metaTitle = document.querySelector('meta[property="og:title"]');
    // const metaDescription = document.querySelector('meta[property="og:description"]');
    // const metaImage = document.querySelector('meta[property="og:image"]');

    // metaTitle.content = title;
    // metaDescription.content = description;
    // metaImage.content = image;
  }, [id, type]);


  useEffect(() => {
    if (product) {
      const title = product.title;
      const description = product.description; // Consider using a dynamic description based on product details
      const image = product.image1 || 'https://example.com/default-product-image.jpg'; // Use a default image if product image is missing

      const metaTitle = document.querySelector('meta[property="og:title"]');
      const metaDescription = document.querySelector('meta[property="og:description"]');
      const metaImage = document.querySelector('meta[property="og:image"]');   


      metaTitle.content = title;
      metaDescription.content = description;
      metaImage.content = image;
    }
  }, [product]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!product) {
    return <div>No product found.</div>;
  }

  return (
    <>
    
    {/* <Helmet>
    <title>{product.title}</title>
        <meta property="og:title" content={product.title} />
        <meta property="og:description" content={product.description} />
        <meta property="og:image" content={`${product.image1}?v=${new Date().getTime()}`} style={{ maxHeight: '300px', maxWidth: '300px' }} />


  
      </Helmet> */}


    
 
    <div style={styles.productPage}>
      <header style={styles.productHeader}>
        <div style={styles.headerLeft}>
          <h1>Jackfruit World</h1>
        </div>
        <div style={styles.headerRight}>
          <img src={dimage} alt="Company Logo" style={styles.logo} />
        </div>
      </header>

      <div style={styles.productDetails}>
        <div style={styles.productInfo}>
          <h2>{product.title}</h2>
          <div style={styles.imageContainer}>
            {product.image2 && (
              <img src={product.image2} alt="Product" style={styles.image} />
            )}
            {product.image3 && (
              <img src={product.image3} alt="Product" style={styles.image} />
            )}{" "}
            {product.image4 && (
              <img src={product.image4} alt="Product" style={styles.image} />
            )}
          </div>
          {product.image1 && (
            <img src={product.image1} alt="Product" style={styles.image} />
          )}
          {type === "sell" && <p style={styles.price}>₹{product.price}</p>}
          <p style={styles.distance}>{product.distance} km</p>
          <div style={styles.productTags}>
  {product.auction_status === '1' && (
    <div style={styles.productTag}>
      Auction
      <img src="/assets/icons/bid_icon.png"  style={styles.statusIcon} />
    </div>
  )}
  {product.organic_status === '1' && (
    <div style={styles.productTag}>
      Organic
      <img src="/assets/icons/organi_1.jpg"  style={styles.statusIcon} />
    </div>
  )}
  {product.safe_status === '1' && (
    <div style={styles.productTag}>
      Safe
      <img src="/assets/icons/safe_icon.jpg"  style={styles.statusIcon} />
    </div>
  )}
  {product.transpot_status === '1' && (
    <div style={styles.productTag}>
      Transport
      <img src="/assets/icons/trucks.png"  style={styles.statusIcon} />
    </div>
  )}
  {product.ecommerce_status === '1' && (
    <div style={styles.productTag}>
      E-commerce
      <img src="/assets/icons/ecommerce.png"  style={styles.statusIcon} />
    </div>
  )}
</div>

        </div>

        <div style={styles.productMeta}>
          <p>
            <strong>Description:</strong> {product.description}
          </p>
          <p>
            <strong>Keywords:</strong> {product.keywords}
          </p>
          <p>
            <strong>Available From:</strong>{" "}
            {new Date(product.availableDate).toLocaleDateString()}
          </p>
          <p>
            <strong>Quantity:</strong> {product.qty} {product.unit}
          </p>
          <p>
            <strong>Expiry Date:</strong>{" "}
            {new Date(product.expDate).toLocaleDateString()}
          </p>
        </div>

        <div style={styles.bidSection}>
          <button
            style={{
              ...styles.bidButton,
              ...(isHovered ? styles.bidButtonHover : {}),
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handlePurchase}
          >
            Purchase Now
          </button>
        </div>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ color: "#ffffff" }}>Install Our App</DialogTitle>
        <DialogContent style={styles.modalContent}>
          <Typography variant="body1" gutterBottom>
            {" "}
            For a better experience, install our mobile app from the following
            links!{" "}
          </Typography>
          <Typography variant="body1">
            <a
              href="https://play.google.com/store/apps/details?id=com.jackfruitworld&pcampaignid=web_share"
              target="_blank"
              rel="noopener noreferrer"
              style={{ ...styles.linkButton, ...styles.googlePlayButton }}
            >
              Google Play Store
            </a>
          </Typography>
          <Typography variant="body1">
            <a
              href="https://apps.apple.com/in/app/jackfruitworld/id6472718704"
              target="_blank"
              rel="noopener noreferrer"
              style={{ ...styles.linkButton, ...styles.appleStoreButton }}
            >
              Apple App Store
            </a>
          </Typography>
        </DialogContent>
        <DialogActions style={{}}>
          <Button onClick={handleClose} style={{ color: "#006400" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </>
  );
};

export default ViewProductDetails;
