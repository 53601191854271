import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, TextField, Box, InputAdornment, Paper, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import Cookies from "universal-cookie";
import { ADD_EMPLOYEE } from "../../../constants/url";
import { privateApiCall } from "../../../api/privateApi";
 
import {formatChatTime} from "../../../utils/chatTime"
import { STRING_CONSTANTS } from "../../../constants/stringConstants";
import PhotoIcon from '@mui/icons-material/Photo'; // Import photo icon

const ChatList = ({ onSelectChat, selectedChatt }) => {
  const cookies = new Cookies();
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [org_id] = useState(cookies.get("org_id"));
  const [productId, setProductId] = useState("");
  const [product, setProducts] = useState(null);

  useEffect(() => {
    fetchChats();
  }, [org_id]);

  const fetchChats = async () => {
    try {
      const requestUrl = `${ADD_EMPLOYEE}/getOrgChat?orgId=${org_id}`;
      const response = await privateApiCall(requestUrl, "GET");
      if (response.data.success) {
        setUsers(response.data.chats);
        setProductId(response.data.chats[0].product.productId);
        setProducts(response.data.chats[0].product);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error fetching chat list:", error);
    }
  };

  // Filter users based on the search query
  const filteredUsers = users.filter(user => {
    if (user && user.otherParticipant && user.otherParticipant.name) {
      return user.otherParticipant.name.toLowerCase().includes(searchQuery.toLowerCase());
    } else {
      console.warn("Missing name in user object", user);
      return false;
    }
  });

  return (
    <Paper
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        borderRadius: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '16px',
      }}
    >
      {/* Search Box with Search Icon */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder={STRING_CONSTANTS.SEARCH_CON}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{
          marginBottom: 2,
          borderRadius: '20px',
          backgroundColor: '#f1f3f4',
          '& .MuiOutlinedInput-root': {
            borderRadius: '20px',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          sx: {
            height: '40px',
            backgroundColor: '#f1f3f4',
            borderRadius: '20px',
          },
        }}
      />

      {/* Chat List */}
      <List
        sx={{
          width: '100%',
          maxHeight: '500px',
          overflowY: 'auto',
        }}
      >
        {filteredUsers.map((user, index) => (
          <ListItem
            key={index}
            button
            onClick={() => onSelectChat(user)}
            sx={{
              padding: '10px 0',
              borderBottom: '1px solid #f0f0f0',
              backgroundColor: selectedChatt && selectedChatt.chatId === user.chatId ? "#0baae5" : "#fff",
              '&:hover': {
                backgroundColor: selectedChatt && selectedChatt.chatId === user.chatId ? "#0baae5" : '#f9f9f9',
              },
              borderRadius: '12px',
            }}
          >
            <ListItemAvatar>
              <Badge
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="plain"
                badgeContent={
                  <Avatar
                    alt="Product Image"
                    src={user.product.productImage || "/static/images/default-product.jpg"}
                    sx={{ width: 16, height: 20 }}
                  />
                }
                badgeInset="14%"
              >
                <Avatar alt={user.otherParticipant.name} src={user.otherParticipant.image || "/static/images/default-avatar.jpg"} size="sm" />
              </Badge>
            </ListItemAvatar>

            <ListItemText
              primary={user.otherParticipant.name}
              secondary={
                <Typography
                  variant="body2"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {/* Check if the last message contains an image */}
                  {user.lastMessage==="Image" ? (
                    <span>
                      <PhotoIcon fontSize="small" sx={{ verticalAlign: 'middle', marginRight: '4px' }} />
                      Image
                    </span>
                  ) : (
                    user.isSender
                      ? `You: ${user.lastMessage.length > 20 ? user.lastMessage.substring(0, 20) + "..." : user.lastMessage}`
                      : `${user.lastMessage.length > 20 ? user.lastMessage.substring(0, 20) + "...": user.lastMessage}`
                  )}
                  <span style={{ marginLeft: 'auto', color: '#999', fontSize: '12px' }}>
                    {formatChatTime(user.lastMessageDate)}
                  </span>
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default ChatList;
