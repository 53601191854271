import React, { useState, useEffect } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { privateApiCall } from "../../api/privateApi";
import { FaRegCalendar } from "react-icons/fa";
import {
  ALERT_BUTTON_OK,
  ALERT_ICON_ERR,
  ERR_ALERT_TITLE,
  ERR_GETTING,
} from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const Dashboard = () => {
  const [counts, setCounts] = useState({
    customerCount: 0,
    volunteerCount: 0,
    EmployeeCount: 0,
    ProfileCount: 0,
    surveyCount: 0,
    mapCount: 0,
    leadCount: 0,
    totalBuyCount: 0,
    buyExCOunt: 0,
    buyComCount: 0,
    buyRej: 0,
    buyPenCount: 0,
    buyAppCount: 0,
    sellPenCount: 0,
    sellExpCount: 0,
    sellAppCount: 0,
    sellRejCount: 0,
    sellSolCount: 0,
    sellTotCount: 0,
    currentSubCount: 0,
    survey: 0,
  });

  const [refCode, setRefCode] = useState("");

  const [vocherId, setVocherId] = useState("");
  const [vochersUsed, setVochersUsed] = useState("");
  const [totalVochers, setTotalVochers] = useState("");
  const [pendingVochers, setPendingVochers] = useState("");
  const [customerCount, setCustomerCount] = useState([]);
  useEffect(() => {
    const fetchProfileCounts = async () => {
      try {
        const requestUrl = "/api/organisation/dashboardAllapicount";
        const data = await privateApiCall(requestUrl, "GET");

        setCounts({
          customerCount: data.data.customerCount,
          volunteerCount: data.data.volunteerCount,
          EmployeeCount: data.data.EmployeeCount,
          profileCount: data.data.profilesTotal,
          surveyCount: data.data.customerCount,
          mapCount: data.data.totalCount,
          leadCount: data.data.leadsCount,
          totalBuyCount: data.data.buyTotal,
          buyPenCount: data.data.buyPen,
          buyRej: data.data.buyRej,
          buyComCount: data.data.buyCom,
          buyExCOunt: data.data.leadExpired,
          buyAppCount: data.data.buyApp,
          sellPenCount: data.data.sellPen,
          sellExpCount: data.data.sellExp,
          sellAppCount: data.data.sellApp,
          sellRejCount: data.data.sellRej,
          sellSolCount: data.data.sellSol,
          sellTotCount: data.data.sellTot,
          currentSubCount: data.data.subCount,
          survey: data.data.sur_len,
        });
      } catch (err) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      }
    };

    fetchProfileCounts();
  }, []);

  return (
    <div id="wrapper">
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {STRING_CONSTANTS.PAGES}
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.DASHBOARD}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.DASHBOARD}</h4>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-xs-12 col-md-6 col-lg-4 col-xl-4">
                <div
                  className="card-box tilebox-one"
                  style={{ height: "250px", color: "black" }}
                >
                  <i
                    className="fas fa-users float-right"
                    style={{ backgroundColor: "#0baae5" }}
                  ></i>
                  <h5 className="text-muted font-14 text-uppercase mb-3">
                    {STRING_CONSTANTS.PROFILES}
                  </h5>

                  <div className="row mb-5">
                    <span>
                      <div id="Profile Total"></div>
                    </span>

                    <h4
                      className="ml-1"
                      data-tooltip-id="5"
                      data-tooltip-content={`Total Profiles`}
                      style={{
                        fontSize: "50px",
                        fontWeight: "bold",
                        color: "#0baae5",
                        marginTop: "25px",
                      }}
                      data-plugin="counterup"
                    >
                      {counts.profileCount}{" "}
                      <Tooltip id="5" style={{ fontSize: "12px" }} />
                    </h4>
                    <span>
                      <div
                        className="ml-2"
                        id="customers"
                        data-tip="Customer"
                      ></div>
                    </span>
                    <Link to="/organisation/customer">
                      <h4
                        className="ml-1 "
                        data-tooltip-id="1"
                        data-tooltip-content={`Customers`}
                        style={{ color: "orange", marginTop: "45px" }}
                        data-plugin=""
                      >
                        {counts.customerCount}{" "}
                        <Tooltip id="1" style={{ fontSize: "12px" }} />
                      </h4>
                    </Link>

                    <span>
                      <div className="ml-2" id="organisations"></div>
                    </span>
                    <Link to="/organisation/employees">
                      <h4
                        className="ml-1 "
                        data-tooltip-id="2"
                        data-tooltip-content={`Employees`}
                        style={{ color: "green", marginTop: "45px" }}
                        data-plugin=""
                      >
                        {counts.EmployeeCount}{" "}
                        <Tooltip id="2" style={{ fontSize: "12px" }} />
                      </h4>
                    </Link>
                    <span>
                      <div className="ml-2" id="member"></div>
                    </span>
                    <Link to="/organisation/members">
                      <h4
                        className="ml-1 "
                        data-tooltip-id="3"
                        data-tooltip-content={`Volunteers`}
                        style={{ color: "red", marginTop: "45px" }}
                        data-plugin=""
                      >
                        {counts.volunteerCount}{" "}
                        <Tooltip id="3" style={{ fontSize: "12px" }} />
                      </h4>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4 col-xl-4">
                <Link to="/organisation/survey">
                  <div
                    className="card-box tilebox-one"
                    style={{ height: "250px", color: "black" }}
                  >
                    <i
                      className="fe-file-text float-right"
                      style={{ backgroundColor: "#0baae5" }}
                    ></i>

                    <h5 className="text-muted font-14 text-uppercase mb-3">
                      {STRING_CONSTANTS.SURVEY}
                    </h5>

                    <div className="row mb-5">
                      <span>
                        <div id="Profile Total"></div>
                      </span>

                      <h4
                        className="ml-1"
                        data-tooltip-id="4"
                        data-tooltip-content={`Total Surveys`}
                        style={{
                          fontSize: "50px",
                          fontWeight: "bold",
                          color: "#0baae5",
                          marginTop: "25px",
                        }}
                        data-plugin="counterup"
                      >
                        {counts.survey}{" "}
                        <Tooltip id="4" style={{ fontSize: "12px" }} />
                      </h4>
                      <span>
                        <div
                          className="ml-2"
                          id="customers"
                          data-tip="Customer"
                        ></div>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>

              <div
                className="col-xs-12 col-md-6 col-lg-4 col-xl-4"
                style={{
                  cursor: counts.mapCount === 0 ? "default" : "pointer",
                  pointerEvents: counts.mapCount === 0 ? "none" : "auto",
                }}
              >
                <Link to="/organisation/mapcomponents" target="_blank">
                  <div
                    className="card-box tilebox-one"
                    style={{ height: "250px", color: "black" }}
                  >
                    <i
                      className="fas fa-map-marker-alt float-right"
                      style={{ backgroundColor: "#0baae5" }}
                    ></i>

                    <h5 className="text-muted font-14 text-uppercase mb-3">
                      {STRING_CONSTANTS.MAP_VIEWS}
                    </h5>

                    <div className="row mb-5">
                      <h4
                        className="ml-1"
                        data-tooltip-id="5"
                        data-tooltip-content={`Total MapViews`}
                        style={{
                          fontSize: "50px",
                          fontWeight: "bold",
                          color: "#0baae5",
                          marginTop: "25px",
                        }}
                        data-plugin="counterup"
                      >
                        {counts.mapCount}{" "}
                        <Tooltip id="5" style={{ fontSize: "12px" }} />
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xs-12 col-md-6 col-lg-4 col-xl-4">
                <Link to="/organisation/leads">
                  <div
                    className="card-box tilebox-one"
                    style={{ height: "250px", color: "black" }}
                  >
                    <i
                      className="fa fa-tasks float-right"
                      style={{ backgroundColor: "#0baae5" }}
                    ></i>

                    <h5 className="text-muted font-14 text-uppercase mb-3">
                      {STRING_CONSTANTS.LEADS}
                    </h5>

                    <div className="row mb-5">
                      <span>
                        <div id="Profile Total"></div>
                      </span>

                      <h4
                        className="ml-1"
                        data-tooltip-id="6"
                        data-tooltip-content={`Total Leads`}
                        style={{
                          fontSize: "50px",
                          fontWeight: "bold",
                          color: "#0baae5",
                          marginTop: "25px",
                        }}
                        data-plugin="counterup"
                      >
                        {counts.leadCount}
                        <Tooltip id="6" style={{ fontSize: "12px" }} />
                      </h4>

                      <span>
                        <div
                          className="ml-2"
                          id="customers"
                          data-tip="Customer"
                        ></div>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4 col-xl-4">
                <Link to="/organisation/products">
                  <div
                    className="card-box tilebox-one"
                    style={{ height: "250px", color: "black" }}
                  >
                    <i
                      className="fa fa-podcast float-right"
                      style={{ backgroundColor: "#0baae5" }}
                    ></i>
                    <h5 className="text-muted font-14 text-uppercase mb-3">
                      {STRING_CONSTANTS.PRODUCTS}
                    </h5>

                    <div
                      className="row"
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4
                        className="mr-2"
                        data-tooltip-id="7"
                        data-tooltip-content="Total BuyProducts"
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "green",
                          display: "flex",
                          alignItems: "left",
                          marginRight: "10px",
                        }}
                        data-plugin="counterup"
                      >
                        <i
                          className="fa fa-shopping-cart"
                          aria-hidden="true"
                          style={{
                            marginRight: "1px",
                            backgroundColor: "#0baae5",
                          }}
                        ></i>
                        {counts.totalBuyCount}
                        <Tooltip id="7" style={{ fontSize: "12px" }} />
                      </h4>

                      <h4
                        className="ml-1"
                        data-tooltip-id="8"
                        data-tooltip-content="Total SellProducts"
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "green",
                          display: "flex",
                          alignItems: "right",
                        }}
                        data-plugin="counterup"
                      >
                        <i
                          className="fe-list"
                          style={{
                            marginRight: "1px",
                            backgroundColor: "#0baae5",
                          }}
                        ></i>
                        {counts.sellTotCount}
                        <Tooltip id="8" style={{ fontSize: "12px" }} />
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4 col-xl-4">
                <Link to="/organisation/harvestPlanner">
                  <div
                    className="card-box tilebox-one"
                    style={{ height: "250px", color: "black" }}
                  >
                    <i
                      className="fa fa-book fa-fw float-right"
                      style={{ backgroundColor: "#0baae5" }}
                    ></i>

                    <h5 className="text-muted font-14 text-uppercase mb-3">
                      {STRING_CONSTANTS.HARVEST_PLANNER}
                    </h5>

                    <div className="row mb-5">
                    <span>
                        <div id="Profile Total"></div>
                      </span>

                      <h4
                        className="ml-1"
                        data-tooltip-id="9"
                        data-tooltip-content={`Total HarvestData`}
                        style={{
                          fontSize: "50px",
                          fontWeight: "bold",
                          color: "#0baae5",
                          marginTop: "25px",
                        }}
                        data-plugin="counterup"
                      >
                        {counts.currentSubCount}
                        <Tooltip id="9" style={{ fontSize: "12px" }} />
                      </h4>

                      <span>
                        <div
                          className="ml-2"
                          id="customers"
                          data-tip="Customer"
                        ></div>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
              {vocherId !== "" && (
                <div className="col-xs-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <img
                          src="assets/images/vocher.png"
                          alt=""
                          height="15%"
                          width="15%"
                        />
                        <div className=" ms-3 mt-2">
                          {STRING_CONSTANTS.VOUCHER_CODE}{" "}
                        </div>
                        <h4 className="text-dark font-18  text-uppercase ms-3 mt-2 ml-2">
                          {" "}
                          {refCode}
                        </h4>
                      </div>
                      <div className="row">
                        <div className=" ms-3 mt-2">
                          {STRING_CONSTANTS.VOUCHER_LIMIT}{" "}
                        </div>

                        <h4 className="text-dark font-18  text-uppercase ms-3 mt-2 ml-2">
                          {" "}
                          {totalVochers}
                        </h4>

                        <div className=" ms-3 mt-2 ml-2">
                          {STRING_CONSTANTS.VOUCHER_USED}
                        </div>

                        <h4 className="text-dark font-18  text-uppercase ms-3 mt-2 ml-2">
                          {" "}
                          {vochersUsed}
                        </h4>

                        <div className=" ms-3 mt-2 ml-2">
                          {STRING_CONSTANTS.VOUCHER_PENDING}
                        </div>

                        <h4 className="text-dark font-18  text-uppercase ms-3 mt-2 ml-2">
                          {" "}
                          {pendingVochers}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
